import { isEdgeElement, isOutcomeNode } from '../../diagram/utils/check-node-type'
import fileDownload from 'js-file-download'
import { decodeHtml, removeTags } from '../../../pages/layout/pages/dashboard/scheme-parser/utils/decode-html'
import { Indicator } from '../../../dto'
import { EdgeElement, OutcomeNodeElement, Scheme, SchemeInfo } from '../../diagram/scheme/interfaces'

const CSV_COLUMN_TITLE_ROWS = [
  `RESULTS FRAME,,,,,,,,,,,`,
  `OUTCOME,,INDICATOR,,,,,RATIONALE,INTERVENTION,,,ASSUMPTIONS`,
  `Outcome Statement,Outcome Deeper Description,What will change,Who will be affected?,How many of them?,How much must they change to be good enough?,By when?,,,,,`,
]
const CSV_COLUMN_DELIMITER = ','
const CSV_ROW_DELIMITER = '\n'

export function downloadCsv(scheme: Scheme, schemeInfo: SchemeInfo) {
  const outcomeNode = scheme!.filter(isOutcomeNode)

  function comparer(ob1: OutcomeNodeElement, ob2: OutcomeNodeElement) {
    if (ob1.position.y > ob2.position.y) {
      return 1
    } else if (ob1.position.y < ob2.position.y) {
      return -1
    }
    if (ob1.position.x < ob2.position.x) {
      return -1
    } else if (ob1.position.x > ob2.position.x) {
      return 1
    } else {
      return 0
    }
  }

  function ReSort(t1: OutcomeNodeElement[]): OutcomeNodeElement[] {
    let result: OutcomeNodeElement[] = []
    let colors: string[] = []
    t1.sort(comparer).forEach((t) => {
      if (!colors.includes(t.data.color)) colors.push(t.data.color)
    })

    colors.forEach((c) => {
      let prevY: number = 0
      t1.filter((t) => t.data.color === c)
        .sort((a, b) => (a.position.y > b.position.y ? 1 : -1))
        .forEach((t) => {
          if (Math.abs(t.position.y - prevY) < 10) {
            t.position.y = prevY
          } else {
            prevY = t.position.y
          }
        })
      result.push.apply(result, t1.filter((t) => t.data.color === c).sort(comparer))
    })
    return result
  }

  const outcomeRows = ReSort(outcomeNode).map((node, index) => {
    const incomeEdges = scheme!.filter((el) => isEdgeElement(el) && el.target === node.id) as EdgeElement[]
    return [
      prepareStr(node.data.title),
      prepareStr(node.data.description),
      combineIndicatorField(node.data.indicators, 'whatWillChange'),
      combineIndicatorField(node.data.indicators, 'who'),
      combineIndicatorField(node.data.indicators, 'howMany'),
      combineIndicatorField(node.data.indicators, 'howMuch'),
      combineIndicatorField(node.data.indicators, 'byWhen'),
      incomeEdges
        .filter((el) => el.data.rationale)
        .map((el, i) => `(${i + 1}) ${el.data.rationale!.text}`)
        .join(' '),
      incomeEdges
        .map((el) => el.data.interventions?.map((t, j) => `(${j + 1}) ${t.text}`).join(' ') || '')
        .filter((s) => s)
        .join(' '),
      '',
      '',
      index === 0
        ? (schemeInfo!.underlyingAssumptions || []).map((ua, i) => `(${i + 1}) ${prepareStr(ua)}`).join(' ')
        : '',
    ]
      .map((s) => (s ? `"${s}"` : s))
      .join(CSV_COLUMN_DELIMITER)
  })
  const csvData = [...CSV_COLUMN_TITLE_ROWS, ...outcomeRows].join(CSV_ROW_DELIMITER)

  fileDownload(csvData, `Data.csv`)
}

function prepareStr(s: string | undefined) {
  if (!s) {
    return ''
  }
  return decodeHtml(removeTags(s))
}

function combineIndicatorField(indicators: Indicator[] | undefined, field: keyof Indicator) {
  if (!indicators || !indicators.length) {
    return ''
  }
  return indicators.map((indicator, index) => `(${index + 1}) ${indicator[field]}`).join(' ')
}
