import { useCallback, useEffect, useState } from 'react'
import { getBrowserVisibilityProp, getIsDocumentHidden } from './browser-visibility'

export function usePageVisibility() {
  const [isVisible, setIsVisible] = useState(getIsDocumentHidden())
  const onVisibilityChange = useCallback(() => {
    setIsVisible(getIsDocumentHidden())
  }, [])
  useEffect(() => {
    const visibilityChange = getBrowserVisibilityProp()
    document.addEventListener(visibilityChange!, onVisibilityChange, false)
    return () => {
      document.removeEventListener(visibilityChange!, onVisibilityChange)
    }
  }, [onVisibilityChange])
  return isVisible
}
