import Button from '@material-ui/core/Button'
import React, { CSSProperties, useCallback } from 'react'
import { Dialog, DialogActions, DialogContent, TextField } from '@material-ui/core'
import clsx from 'clsx'
import * as Yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import styles from './styles.module.scss'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { useLayoutContext } from '../../../pages/layout/context/use-layout-context'
import { api } from '../../../api'
import { Spinner } from '../../spinner'
import { useRemoteDataCallback } from '../../../hooks/use-remote-data'
import { useSavingSnackbar } from '../../toolbar/hooks/use-save-diagram'
import { convertSchemeToXml } from '../../../pages/layout/pages/dashboard/scheme-parser/js-to-xml/convert-scheme-to-xml'
import { useDashboardPageContext } from '../../../pages/layout/pages/dashboard/context/use-dashboard-page-context'
import { useAuthContext } from '../../context/use-auth-context'

type CloneTocForm = {
  name: string
}

const formValidationSchema = Yup.object().shape<CloneTocForm>({
  name: Yup.string().required('Required'),
})

export function CloneTocModal({ hideModal }: { hideModal: () => any }) {
  const { setCreatedTocoId } = useLayoutContext()
  const [createToco, isLoading] = useRemoteDataCallback(api.toco.postCreateToco)
  const { scheme, schemeInfo, xmlTocoScheme } = useDashboardPageContext()
  const { authData } = useAuthContext()
  const savingSnackbar = useSavingSnackbar()

  const { register, handleSubmit, errors } = useForm<CloneTocForm>({
    resolver: yupResolver(formValidationSchema),
  })
  const onSubmit = useCallback(
    (formData: CloneTocForm) => {
      const xml = convertSchemeToXml({
        xmlTocoScheme: xmlTocoScheme!,
        scheme: scheme!,
        schemeInfo: schemeInfo!,
        userId: authData!.userId,
        tocoInfo: {
          id: undefined,
          title: formData.name,
        },
      })
      createToco({ xml })
        .then((id) => {
          setCreatedTocoId(id)
          savingSnackbar.notifySavingFulfilled()
          hideModal()
        })
        .catch(savingSnackbar.notifySavingRejected)
    },
    [scheme, authData, createToco, hideModal, savingSnackbar, schemeInfo, setCreatedTocoId, xmlTocoScheme],
  )

  return (
    <Dialog maxWidth="xs" onClose={hideModal} open={true}>
      <Spinner show={isLoading} overlay={true} />
      <form onSubmit={handleSubmit(onSubmit)} className={styles.form} autoComplete="off">
        <MuiDialogTitle className={styles.titleWrapper}>
          Save TOC As...
          <IconButton onClick={hideModal} className={styles.closeIcon}>
            <CloseIcon />
          </IconButton>
        </MuiDialogTitle>

        <DialogContent style={dialogContentStyle}>
          <TextField
            inputRef={register}
            name="name"
            label="Enter a title for the new TOC"
            className={clsx('w-100', styles.nameField)}
            variant="outlined"
            size="small"
            helperText={errors.name?.message}
            error={!!errors.name}
          />
        </DialogContent>

        <DialogActions className={styles.actions}>
          <Button onClick={hideModal} color="primary" variant="outlined" className={styles.btn}>
            Cancel
          </Button>
          <Button type="submit" color="primary" variant="contained" className={styles.btn}>
            Done
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

const dialogContentStyle: CSSProperties = { overflow: 'visible' }
