import { makeStyles } from '@material-ui/core'
import { ToggleButton } from '@material-ui/lab'

const useStyles = makeStyles({
  root: {
    padding: '8px',
    height: '36px',
  },
})

export const useToggleBtnProps = (disable: boolean) => {
  const classes = useStyles()
  const btnProps: Partial<typeof ToggleButton> = {
    classes: {
      root: classes.root,
    },
    disabled: disable,
  }
  return btnProps
}
