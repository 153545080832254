export const PRESET_COLORS = [
  'rgba(131, 198, 237, 1)',
  'rgba(208, 2, 27, 1)',
  'rgba(245, 166, 35, 1)',
  'rgba(248, 231, 28, 1)',
  'rgba(139, 87, 42, 1)',
  'rgba(126, 211, 33, 1)',
  'rgba(65, 117, 5, 1)',
  'rgba(189, 16, 224, 1)',
  'rgba(144, 19, 254, 1)',
  'rgba(74, 144, 226, 1)',
  'rgba(80, 227, 194, 1)',
  'rgba(184, 233, 134, 1)',
  'rgba(0, 0, 0, 1)',
  'rgba(74, 74, 74, 1)',
  'rgba(155, 155, 155, 1)',
  'rgba(255, 255, 255, 1)',
]
