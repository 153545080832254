import { Box, TextField } from '@material-ui/core'
import React, { memo, useCallback } from 'react'
import { useEditorContext } from '../../../context/editor-context-provider'

export const NarrativeTab = memo(() => {
  const { updateSchemeInfo, schemeInfo } = useEditorContext()
  const onChangeNarrative = useCallback(
    (event) => {
      updateSchemeInfo((prevInfo) => ({ ...prevInfo, narrative: event.target.value }))
    },
    [updateSchemeInfo],
  )

  return (
    <Box px={2} py={2} className="h-100 bg-white">
      <TextField
        id="outlined-multiline-static"
        label="Narrative:"
        className="w-100"
        multiline
        rows={4}
        value={schemeInfo?.narrative}
        onChange={onChangeNarrative}
        variant="outlined"
      />
    </Box>
  )
})
