/**
 * Transforms 65382 => rgba(0,255,102,1)
 */
export function convertNumberColorToRgba(number: number, opacity: number = 1) {
  const r = (number & 0xff0000) >> 16
  const g = (number & 0x00ff00) >> 8
  const b = number & 0x0000ff

  return `rgba(${r},${g},${b},${opacity})`
}

const NUMBER_BASE_COLOR = 8636141
/**
 * Transforms rgba(0,255,102,1) => 65382
 */
export function convertRgbaColorToNumber(rgba: string) {
  const rgbaParts = rgba
    .split(/[rgba(),]/)
    .filter((s) => s)
    .map((s, index) => (index === 3 ? parseFloat(s) : parseInt(s, 10)))
  if (rgbaParts?.length !== 4) {
    console.warn('Unexpected color format. Rgba is expected. Received: ', rgba)
    return {
      numberColor: NUMBER_BASE_COLOR,
      transparency: 1,
    }
  }
  const [r, g, b, a] = rgbaParts
  return {
    numberColor: (r << 16) + (g << 8) + b,
    transparency: a,
  }
}
