import { ReducerWithoutAction, useCallback, useMemo } from 'react'
import { Scheme, SchemeElement, UpdateScheme } from '../../scheme/interfaces'

export type ElementUtils = {
  findElement: <T extends SchemeElement = SchemeElement>(id: string) => T
  updateElement: <T extends SchemeElement = SchemeElement>(id: string, updater: ReducerWithoutAction<T>) => void
}

export function useElementUtils(updateScheme: UpdateScheme, scheme: Scheme): ElementUtils {
  const updateElement = useCallback(
    (id: string, updater: ReducerWithoutAction<any>) => {
      updateScheme((els) => els.map((el) => (el.id === id ? updater(el) : el)))
    },
    [updateScheme],
  )
  const findElement = useCallback((id: string) => scheme?.find((node) => id === node.id)! as any, [scheme])

  return useMemo(() => ({ updateElement, findElement }), [updateElement, findElement])
}
