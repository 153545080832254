import { XmlTocoScheme } from '../interface'
import { NodeSchemeType } from '../../../../../../components/diagram/scheme/enums'
import { CommentNodeElement } from '../../../../../../components/diagram/shapes/comment'
import { decodeHtmlAndUpdateFont } from '../utils/decode-html'
import { XmlCommentOwner } from '../xml-enums'

const COMMENT_PREFIX = 'c_'

export function parseComment(xmlComment: XmlTocoScheme.Comment): CommentNodeElement {
  return {
    id: `${COMMENT_PREFIX}${xmlComment.CommentId}`,
    type: NodeSchemeType.comment,
    position: { x: xmlComment.x, y: xmlComment.y },
    style: { width: xmlComment.Width, height: xmlComment.Height },
    data: {
      commentId: xmlComment.CommentId,
      creator: xmlComment.Creator,
      isActKnowledgeComment: xmlComment.IsActKnowledgeComment === XmlCommentOwner.actKnowledge,
      text: decodeHtmlAndUpdateFont(xmlComment.Text),
    },
  }
}
