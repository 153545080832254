import React from 'react'
import styles from './index.module.scss'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { Spinner } from '../../components/spinner'
import { LoginRequestI } from '../../api/directory/auth'
import { useAuthContext } from '../../components/context/use-auth-context'

const formValidationSchema = Yup.object().shape<LoginRequestI>({
  username: Yup.string().required('Username is required'),
  password: Yup.string().required('Password is required'),
})

export function Login() {
  const { register, handleSubmit, errors } = useForm<LoginRequestI>({
    resolver: yupResolver(formValidationSchema),
  })

  const { logIn, logInLoading } = useAuthContext()

  return (
    <div className={styles.bg}>
      <div className={styles.dialogWrapper}>
        <Spinner show={logInLoading} />
        <div className={styles.dialogBg} />
        <div className={styles.dialog}>
          <h2>TOCO</h2>
          <form onSubmit={handleSubmit(logIn)}>
            <input ref={register} type="text" name="username" placeholder="Username" />
            <div className={styles.errorWrapper}>
              <div className={styles.error}>{errors?.username?.message}</div>
            </div>
            <input ref={register} type="password" name="password" placeholder="Password" />
            <div className={styles.errorWrapper}>
              <div className={styles.error}>{errors?.password?.message}</div>
            </div>
            <button className={styles.submitBtn} type="submit" disabled={false}>
              Login
            </button>
          </form>
        </div>
      </div>
    </div>
  )
}
