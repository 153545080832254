import React, { CSSProperties, PropsWithChildren, useCallback } from 'react'
import { Scheme, SchemeInfo, UpdateScheme } from './scheme/interfaces'
import { InnerSchemeAccessContextProvider } from './context/use-inner-scheme-access'
import { edgesDefinition, nodesDefinition } from './scheme/definition'
import ReactFlow, {
  Background,
  BackgroundVariant,
  ConnectionLineType,
  ConnectionMode,
  FlowElement,
  ReactFlowProps,
  ReactFlowProvider,
} from 'react-flow-renderer'
import Measure, { MeasureProps } from 'react-measure'
import { MarkerDefinitions } from './marker-definition'
import { DEFAULT_EDGE_STROKE_WIDTH, DIAGRAM_BG_COLOR, EDGE_COLOR, MARKER_ID } from './constants'
import { useFlowHandlers } from './utils/hooks/use-flow-handlers'
import { useDashboardPageContext } from '../../pages/layout/pages/dashboard/context/use-dashboard-page-context'

type FlowConfigurationProps = {
  scheme: Scheme
  updateScheme: UpdateScheme
  defaultPosition?: ReactFlowProps['defaultPosition']
  onResize?: MeasureProps['onResize']
  schemeInfo: SchemeInfo | undefined
  isFrozen?: boolean
  onLoadCb?: () => void
  paneMovable?: boolean
}

// wrap page provider and diagram
export function FlowConfigurationProvider({ children }: PropsWithChildren<{}>) {
  return <ReactFlowProvider>{children}</ReactFlowProvider>
}

export function Flow({
  scheme,
  updateScheme,
  defaultPosition,
  onResize,
  schemeInfo,
  isFrozen,
  onLoadCb,
  paneMovable,
}: FlowConfigurationProps) {
  const {
    onConnect,
    onElementsRemove,
    onSelectionChange,
    onLoad,
    onMoveEnd,
    onNodeDragStop,
    onPanelClick,
    onMove,
  } = useFlowHandlers(updateScheme, defaultPosition, isFrozen, onLoadCb)
  const { activeTab } = useDashboardPageContext()

  const childrenMeasureCb = useCallback(
    ({ measureRef }) => (
      <div className={'fullWidth'} ref={measureRef}>
        {(defaultPosition || isFrozen) && ( // avoid extra rendering with wrong position
          <>
            <ReactFlow
              key={schemeInfo?.id}
              nodeTypes={nodesDefinition}
              edgeTypes={edgesDefinition}
              elements={scheme as FlowElement[]}
              nodesDraggable={!isFrozen}
              paneMoveable={paneMovable}
              nodesConnectable={!isFrozen}
              elementsSelectable={!isFrozen}
              onSelectionChange={onSelectionChange}
              onElementsRemove={onElementsRemove}
              onConnect={onConnect}
              defaultPosition={
                activeTab?.id && schemeInfo?.x! && schemeInfo?.y! ? [schemeInfo?.x!, schemeInfo?.y!] : defaultPosition
              }
              defaultZoom={activeTab?.id && schemeInfo?.zoom ? schemeInfo?.zoom : 1}
              deleteKeyCode={46} /* 'delete'-key */
              multiSelectionKeyCode={17} /* 'ctrl'-key */
              markerEndId={MARKER_ID}
              connectionMode={ConnectionMode.Loose}
              connectionLineType={ConnectionLineType.Straight}
              connectionLineStyle={connectionLineStyle}
              onMove={onMove}
              onMoveEnd={onMoveEnd}
              onLoad={onLoad}
              minZoom={0.25}
              onPaneClick={onPanelClick}
              onNodeDragStop={onNodeDragStop}
            >
              <div style={greyBgStyle}>
                {schemeInfo?.isBgGridShown && (
                  <Background variant={BackgroundVariant.Lines} gap={20} color={'#00000033'} />
                )}
              </div>
            </ReactFlow>
            <MarkerDefinitions />
          </>
        )}
      </div>
    ),
    [
      defaultPosition,
      isFrozen,
      schemeInfo?.id,
      schemeInfo?.x,
      schemeInfo?.y,
      schemeInfo?.zoom,
      schemeInfo?.isBgGridShown,
      scheme,
      paneMovable,
      onSelectionChange,
      onElementsRemove,
      onConnect,
      activeTab?.id,
      onMove,
      onMoveEnd,
      onLoad,
      onPanelClick,
      onNodeDragStop,
    ],
  )
  return (
    <InnerSchemeAccessContextProvider scheme={scheme} updateScheme={updateScheme}>
      <Measure bounds onResize={onResize}>
        {childrenMeasureCb}
      </Measure>
    </InnerSchemeAccessContextProvider>
  )
}

const greyBgStyle: CSSProperties = {
  background: DIAGRAM_BG_COLOR,
  height: '100%',
}

const connectionLineStyle: React.CSSProperties = {
  stroke: EDGE_COLOR,
  strokeWidth: DEFAULT_EDGE_STROKE_WIDTH,
}
