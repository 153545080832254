export interface Indicator {
  byWhen?: string
  howMany?: string
  howMuch?: string
  status: 'needsAttention' | 'inProgress' | 'achieved'
  whatWillChange: string
  who?: string
}

export type OutcomeContent = {
  title?: string
  description?: string
  color: string
  file?: string
  indicators?: Indicator[]
}

export type EdgeData = {
  interventions?: Intervention[]
  rationale?: Rationale
  width: number
  lineStyle: LineStyle

  rationaleInterventionX?: number // hot fix for old app
  rationaleInterventionY?: number // hot fix for old app
}

export type Intervention = HiddenValue
export type Rationale = HiddenValue

interface HiddenValue {
  text: string
  isFullText: boolean
}

export type LineStyle = 'solid' | 'dashed' | 'dotted'

export enum Orientation {
  landscape = 'landscape',
  portrait = 'portrait',
}
