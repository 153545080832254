import React, { memo } from 'react'
import { OutcomeTabs } from './outcome-tabs'
import { EditorLayout } from '../components/layout'
import { EditorTitle } from '../components/layout/title'

export const OutcomeEditor = memo(() => {
  return (
    <EditorLayout>
      <EditorTitle>Outcome Editor</EditorTitle>
      <OutcomeTabs />
    </EditorLayout>
  )
})
