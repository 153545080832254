import { useCallback } from 'react'
import { OutcomeNodeElement } from '../../../diagram/scheme/interfaces'
import { useAddNode } from './use-add-node'

const NEW_SHAPE_WIDTH = 200
const NEW_SHAPE_HEIGHT = 150

export function useAddOutcomeNode() {
  const addShape = useAddNode()
  return useCallback(
    (type: OutcomeNodeElement['type'], color: string) =>
      addShape({
        type,
        data: {
          color,
          title: '',
          indicators: [],
        },
        style: { width: NEW_SHAPE_WIDTH, height: NEW_SHAPE_HEIGHT },
      }),
    [addShape],
  )
}
