import { Box, IconButton, TextField, TextFieldProps } from '@material-ui/core'
import React, { memo, ReducerWithoutAction, useCallback, useEffect } from 'react'
import { Indicator } from '../../../../../dto'
import { OutcomeNodeElement } from '../../../../diagram/scheme/interfaces'
import { ElementList } from '../../../../element-list'
import { useUpdateActiveNodeData } from '../../../components/hooks/use-update-active-node-data'
import { useEditorContext } from '../../../context/editor-context-provider'
import WarningRoundedIcon from '@material-ui/icons/WarningRounded'
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded'
import ErrorRoundedIcon from '@material-ui/icons/ErrorRounded'
import { green, yellow } from '@material-ui/core/colors'
import styles from './index.module.scss'

const emptyIndicator: Indicator = {
  status: 'inProgress',
  whatWillChange: '',
  byWhen: '',
  howMany: '',
  howMuch: '',
  who: '',
}

export const IndicatorsTab = memo(() => {
  const { activeElement, updateElement } = useEditorContext()
  const node: OutcomeNodeElement = activeElement as OutcomeNodeElement
  const addIndicators = useCallback(() => {
    updateElement<OutcomeNodeElement>(node.id, (el) => ({
      ...el,
      data: { ...el.data, indicators: [...(el.data.indicators || []), emptyIndicator] },
    }))
  }, [node.id, updateElement])
  useEffect(() => {
    if (node.data.indicators?.length === 0) {
      addIndicators()
    }
    // eslint-disable-next-line
  }, [])
  const removeIndicator = useCallback(
    (activeIndex) => {
      updateElement<OutcomeNodeElement>(node.id, (prevEl) => ({
        ...prevEl,
        data: {
          ...prevEl.data,
          indicators: [...prevEl.data.indicators!.filter((_, index) => index !== activeIndex)],
        },
      }))
    },
    [updateElement, node.id],
  )
  const renderContent = useCallback((activeIndex) => <Content activeIndex={activeIndex} node={node} />, [node])
  return (
    <ElementList
      resetKey={node.id}
      renderContent={renderContent}
      onRemoveElement={removeIndicator}
      onAddElement={addIndicators}
      elements={node.data?.indicators || []}
    />
  )
})

type ContentProps = {
  node: OutcomeNodeElement
  activeIndex: number
}

const Content = ({ node, activeIndex }: ContentProps) => {
  const updateActiveNodeData = useUpdateActiveNodeData()
  const updateIndicator = useCallback(
    (updater: ReducerWithoutAction<Indicator>) => {
      updateActiveNodeData((prevData) => ({
        indicators: prevData?.indicators?.map((prevIndicator, i) =>
          i === activeIndex ? updater(prevIndicator) : prevIndicator,
        ),
      }))
    },
    [updateActiveNodeData, activeIndex],
  )

  const onWhatWillChange = useCallback(
    (event) => {
      updateIndicator((prevIndicator) => ({
        ...prevIndicator,
        whatWillChange: event.target.value,
      }))
    },
    [updateIndicator],
  )

  const onWho = useCallback(
    (event) => {
      updateIndicator((prevIndicator) => ({
        ...prevIndicator,
        who: event.target.value,
      }))
    },
    [updateIndicator],
  )

  const onHowMany = useCallback(
    (event) => {
      updateIndicator((prevIndicator) => ({
        ...prevIndicator,
        howMany: event.target.value,
      }))
    },
    [updateIndicator],
  )

  const onHowMuch = useCallback(
    (event) => {
      updateIndicator((prevIndicator) => ({
        ...prevIndicator,
        howMuch: event.target.value,
      }))
    },
    [updateIndicator],
  )

  const onByWhen = useCallback(
    (event) => {
      updateIndicator((prevIndicator) => ({
        ...prevIndicator,
        byWhen: event.target.value,
      }))
    },
    [updateIndicator],
  )

  const activeIndicator = node.data.indicators?.[activeIndex]

  const onStatusChange = (indicatorStatus: Indicator['status']) => () => {
    updateIndicator((prevIndicator) => ({
      ...prevIndicator,
      status: indicatorStatus,
    }))
  }

  const getIconClassName = (iconStatus: Indicator['status']) =>
    activeIndicator?.status === iconStatus ? '' : styles.gray

  return (
    <>
      <Box mb={1} display="flex" alignItems="center">
        <IconButton aria-label="error" onClick={onStatusChange('needsAttention')}>
          <ErrorRoundedIcon color="error" fontSize="small" className={getIconClassName('needsAttention')} />
        </IconButton>
        <IconButton aria-label="warning" onClick={onStatusChange('inProgress')}>
          <WarningRoundedIcon
            style={{ color: yellow[500] }}
            fontSize="small"
            className={getIconClassName('inProgress')}
          />
        </IconButton>
        <IconButton aria-label="succsess" onClick={onStatusChange('achieved')}>
          <CheckCircleRoundedIcon
            style={{ color: green[500] }}
            fontSize="small"
            className={getIconClassName('achieved')}
          />
        </IconButton>

        <Box component="span" ml={1}>
          {activeIndicator?.status === 'achieved' && 'Achieved'}
          {activeIndicator?.status === 'inProgress' && 'In Progress'}
          {activeIndicator?.status === 'needsAttention' && 'Needs Attention'}
        </Box>
      </Box>
      <TextField
        {...textFieldProps}
        label="What Will Change?:"
        value={activeIndicator?.whatWillChange}
        onChange={onWhatWillChange}
      />
      <TextField {...textFieldProps} label="Who?:" value={activeIndicator?.who} onChange={onWho} />
      <TextField {...textFieldProps} label="How Many?:" value={activeIndicator?.howMany} onChange={onHowMany} />
      <TextField {...textFieldProps} label="How Much?:" value={activeIndicator?.howMuch} onChange={onHowMuch} />
      <TextField {...textFieldProps} label="By When?:" value={activeIndicator?.byWhen} onChange={onByWhen} />
    </>
  )
}

const textFieldProps: Partial<TextFieldProps> = {
  rows: 3,
  multiline: true,
  className: styles.indicatorsTextField,
  variant: 'outlined',
}
