import { InterventionEdgeProps } from '../../scheme/interfaces'
import { useMemo } from 'react'
import { OpenEditorStates, useEditorContext } from '../../../editor/context/editor-context-provider'
import { DEFAULT_EDGE_STROKE_WIDTH, EDGE_COLOR } from '../../constants'
import { EdgeWithContentProps } from '../../core/edge/common/interface'
import { LineStyle } from '../../../../dto'

const DASH_SIZE = 5
const SURROUND_CLICKABLE_AREA_SIZE = 10

export function useEdgeConfiguration(edgeProps: InterventionEdgeProps): Partial<EdgeWithContentProps> {
  const { setActiveElementId, openEditor } = useEditorContext()
  return useMemo(() => {
    const strokeWidth = edgeProps.data?.width || DEFAULT_EDGE_STROKE_WIDTH

    return {
      style: {
        pointerEvents: 'stroke',
        fill: 'none',
        stroke: EDGE_COLOR,
        strokeWidth,
        strokeDasharray: calcStrokeDasharray(strokeWidth, edgeProps.data?.lineStyle),
        ...edgeProps.style,
      },
      triggerAreaPathProps: {
        pointerEvents: 'stroke',
        fill: 'none',
        cursor: 'pointer',
        strokeWidth: strokeWidth + SURROUND_CLICKABLE_AREA_SIZE,
        onDoubleClickCapture: () => {
          openEditor?.(OpenEditorStates.edgeEditor)
        },
        onClickCapture: () => {
          setActiveElementId?.(edgeProps.id)
        },
      },
    }
  }, [edgeProps.data?.lineStyle, edgeProps.data?.width, edgeProps.id, edgeProps.style, openEditor, setActiveElementId])
}
function calcStrokeDasharray(strokeWidth: number, lineStyle?: LineStyle) {
  switch (lineStyle) {
    case 'dashed':
      return `${strokeWidth * DASH_SIZE} ${strokeWidth * DASH_SIZE}`
    case 'dotted':
      return `${strokeWidth} ${strokeWidth}`
    case 'solid':
    default:
      return undefined
  }
}
