import { SchemeExtraParams } from './scheme-extra-params'
import { NodeExtraParams } from './node-extra-params'

const SERIALIZATION_PREFIX = '__JSON__'

export function tryDecodeExtraParam<T extends ExtraParams>(value: string | undefined): T | undefined {
  // It is less expensive way to check if we have custom data in the field then try to parse Json
  const hasExtraParams = 'string' === typeof value && value.startsWith(SERIALIZATION_PREFIX)
  if (hasExtraParams) {
    try {
      return JSON.parse(value!.slice(SERIALIZATION_PREFIX.length))
    } catch {}
  }
  return undefined
}

export function encodeExtraParams(data: ExtraParams) {
  // Please avoid xml sy
  return `${SERIALIZATION_PREFIX}${JSON.stringify(data)}`
}

export type XmlWithExtraInfo<SchemeExtraParamField extends SchemeExtraParams[keyof SchemeExtraParams] | undefined> = {
  xml: string
  paramField?: SchemeExtraParamField
}

export type ExtraParams = NodeExtraParams | SchemeExtraParams
