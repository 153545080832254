import React, { useCallback, memo, CSSProperties } from 'react'
import { EdgeElement, EdgeElementWithType } from '../../../../../diagram/scheme/interfaces'
import { ElementUtils } from '../../../../../diagram/utils/hooks/use-element-utils'
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab'
import { EdgeSchemeType } from '../../../../../diagram/scheme/enums'
import curveImg from './images/curve.png'
import straightImg from './images/straight.png'
import stepImg from './images/step.png'
import bezierImg from './images/bezier.svg'
import { useToggleBtnProps } from '../toggle-btn-props'

type Props = Pick<ElementUtils, 'updateElement'> & {
  edge?: EdgeElement
}
export const EdgeTypeSwitcher = memo(({ edge, updateElement }: Props) => {
  const handleAlignment = useCallback(
    (_: any, newType: EdgeElementWithType<any>['type']) => {
      if (newType) {
        updateElement<EdgeElement>(edge!.id, (prevState) => ({ ...prevState, type: newType }))
      }
    },
    [edge, updateElement],
  )
  const edgeProps = useToggleBtnProps(edge === undefined)
  return (
    <ToggleButtonGroup value={edge?.type || null} exclusive onChange={handleAlignment}>
      <ToggleButton {...edgeProps} value={EdgeSchemeType.stepEdgeIntervention}>
        <img src={stepImg} alt="Step" />
      </ToggleButton>
      <ToggleButton {...edgeProps} value={EdgeSchemeType.smoothStepEdgeIntervention}>
        <img src={curveImg} alt="Curve" />
      </ToggleButton>
      <ToggleButton {...edgeProps} value={EdgeSchemeType.straightEdgeIntervention}>
        <img src={straightImg} alt="Straight" />
      </ToggleButton>
      <ToggleButton {...edgeProps} value={EdgeSchemeType.bezierEdgeIntervention}>
        <img src={bezierImg} alt="Bezier" width={'28px'} height={'28px'} style={bezierStyle} />
      </ToggleButton>
    </ToggleButtonGroup>
  )
})
const bezierStyle: CSSProperties = {
  transform: 'rotate(32deg)',
}
