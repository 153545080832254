import React, { memo, useCallback } from 'react'
import { InterventionEdgeProps } from '../scheme/interfaces'
import { useEditorContext } from '../../editor/context/editor-context-provider'
import styles from './index.module.scss'
import Draggable from 'react-draggable'
import { useDashboardPageContext } from '../../../pages/layout/pages/dashboard/context/use-dashboard-page-context'

export const ConnectionLabel = memo(({ data, id }: InterventionEdgeProps) => {
  const { setActiveElementId, setPaneMovable } = useEditorContext()
  const openConnectionEditor = useCallback(() => {
    setActiveElementId(id)
  }, [id, setActiveElementId])
  const onMouseEnter = useCallback(() => setPaneMovable(false), [setPaneMovable])
  const onMouseLeave = useCallback(() => setPaneMovable(true), [setPaneMovable])
  const { schemeInfo } = useDashboardPageContext()
  return (
    <Draggable scale={schemeInfo?.zoom}>
      <div
        className={styles.preview}
        onClick={openConnectionEditor}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {data?.rationale && (
          <span title={data?.rationale.text} className={styles.cell}>
            {data?.rationale?.isFullText && data?.rationale.text ? (
              <p className="ql-editor" dangerouslySetInnerHTML={{ __html: data.rationale.text }} />
            ) : (
              `R1`
            )}
          </span>
        )}
        {data?.interventions?.map((intervention, i) => (
          <span key={i} title={intervention.text} className={styles.cell}>
            {intervention.isFullText && intervention.text ? (
              <p className="ql-editor" dangerouslySetInnerHTML={{ __html: intervention.text }} />
            ) : (
              `i${i + 1}`
            )}
          </span>
        ))}
      </div>
    </Draggable>
  )
})
