import React, { memo } from 'react'
import { OutcomeNodeElementWithType, OutcomeNodeProps } from '../../../scheme/interfaces'
import { NodeSchemeType } from '../../../scheme/enums'
import styles from './index.module.scss'
import { BaseShape, ThemeClassNames } from '../base-shape'

export type RectangleElement = OutcomeNodeElementWithType<NodeSchemeType.rectangle>
export const RectangleNode = memo((outcomeProps: OutcomeNodeProps) => {
  return <BaseShape themeClassNames={styles as any} {...outcomeProps} />
})

const roundedStyles: ThemeClassNames = {
  ...(styles as any),
  shape: `${styles.shape} ${styles.rounded}`,
}
export type RoundedRectangleElement = OutcomeNodeElementWithType<NodeSchemeType.roundedRectangle>
export const RoundedRectangleNode = memo((outcomeProps: OutcomeNodeProps) => {
  return <BaseShape themeClassNames={roundedStyles} {...outcomeProps} />
})
