import { Tabs } from '@material-ui/core'
import React, { memo } from 'react'
import Tab from '@material-ui/core/Tab/Tab'
import { useTabsHandler } from '../../../../hooks/use-tabs-handler'
import { TabContent } from '../../components/layout/tab-content'
import { RationaleTab } from './rationale-tab'
import { InterventionsTab } from './interventions-tab'

export const ConnectorTabs = memo(() => {
  const { activeTabIndex, handleTabChange } = useTabsHandler()
  return (
    <>
      <Tabs value={activeTabIndex} onChange={handleTabChange} indicatorColor="primary" textColor="primary">
        <Tab label="Rationale" />
        <Tab label="Interventions" />
      </Tabs>
      <TabContent>
        {activeTabIndex === 0 && <RationaleTab />}
        {activeTabIndex === 1 && <InterventionsTab />}
      </TabContent>
    </>
  )
})
