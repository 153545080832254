import { Tabs } from '@material-ui/core'
import React, { memo } from 'react'
import Tab from '@material-ui/core/Tab/Tab'
import { BasicsTab } from './basics-tab'
import { useTabsHandler } from '../../../../hooks/use-tabs-handler'
import { TabContent } from '../../components/layout/tab-content'
import { IndicatorsTab } from './indicators-tab'
import styles from './index.module.scss'

export const OutcomeTabs = memo(() => {
  const { activeTabIndex, handleTabChange } = useTabsHandler()
  return (
    <>
      <Tabs
        value={activeTabIndex}
        onChange={handleTabChange}
        className={styles.tabsRow}
        indicatorColor="primary"
        textColor="primary"
      >
        <Tab label="Outcome" />
        <Tab label="Indicators" />
      </Tabs>
      <TabContent>
        {activeTabIndex === 0 && <BasicsTab />}
        {activeTabIndex === 1 && <IndicatorsTab />}
      </TabContent>
    </>
  )
})
