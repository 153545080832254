import React, { memo } from 'react'
import { EditorLayout } from '../components/layout'
import { EditorTitle } from '../components/layout/title'
import { ConnectorTabs } from './connector-tabs'

export const ConnectorEditor = memo(() => {
  return (
    <EditorLayout>
      <EditorTitle>Connector Editor</EditorTitle>
      <ConnectorTabs />
    </EditorLayout>
  )
})
