import { useStoreState } from 'react-flow-renderer'
import { useCallback } from 'react'
import { Scheme } from '../../scheme/interfaces'
import { setZIndexes } from '../set-z-index-util'

// need to be wrapper by FlowConfigurationProvider
export function useSchemeProcessor() {
  const selectedElements = useStoreState((s) => s.selectedElements)
  return useCallback(
    (scheme: Scheme) => {
      let expandedScheme = scheme
      // set zIndex for all nodes to place containers under usual nodes
      expandedScheme = setZIndexes(expandedScheme, selectedElements)
      return expandedScheme
    },
    [selectedElements],
  )
}
