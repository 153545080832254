import React, { useCallback } from 'react'
import GridOnIcon from '@material-ui/icons/GridOn'
import GridOffIcon from '@material-ui/icons/GridOff'
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab'
import { useEditorContext } from '../../../editor/context/editor-context-provider'

export const BgGridToggle = () => {
  const { schemeInfo, updateSchemeInfo } = useEditorContext()
  const toggleBgGridShown = useCallback(
    (_, value) => {
      if (value !== null && value !== schemeInfo?.isBgGridShown) {
        updateSchemeInfo((prevScheme) => ({
          ...prevScheme,
          isBgGridShown: value,
        }))
      }
    },
    [updateSchemeInfo, schemeInfo?.isBgGridShown],
  )
  if (!schemeInfo) {
    return null
  }
  return (
    <ToggleButtonGroup value={schemeInfo.isBgGridShown} exclusive onChange={toggleBgGridShown} size="small">
      <ToggleButton value={true}>
        <GridOnIcon fontSize="small" />
      </ToggleButton>
      <ToggleButton value={false}>
        <GridOffIcon fontSize="small" />
      </ToggleButton>
    </ToggleButtonGroup>
  )
}
