import { XmlTocoScheme } from '../interface'
import { CanvasSize } from '../../../../../../components/diagram/scheme/interfaces'
import { XmlAccountabilityCeilingVisibility } from '../xml-enums'
import { AccountabilityCeilingNodeElement } from '../../../../../../components/diagram/shapes/accountability-ceiling'
import {
  createHorizontalGuideNode,
  createVerticalGuideNode,
} from '../../../../../../components/toolbar/hooks/add-shape/create-special-nodes/guides'

export function createGuideNodes(xmlTocoScheme: XmlTocoScheme.RootObject, canvasSize: CanvasSize) {
  const guideElements: AccountabilityCeilingNodeElement[] = []
  const xmlCeiling = xmlTocoScheme.Toc.Content.CanvasContent.AccountabilityCeiling
  if (!xmlCeiling) {
    return []
  }

  const isHorizontalDisplayed =
    xmlCeiling.Visible === XmlAccountabilityCeilingVisibility.horizontal ||
    xmlCeiling.Visible === XmlAccountabilityCeilingVisibility.both

  const isVerticalDisplayed =
    xmlCeiling.Visible === XmlAccountabilityCeilingVisibility.vertical ||
    xmlCeiling.Visible === XmlAccountabilityCeilingVisibility.both

  if (isHorizontalDisplayed) {
    guideElements.push(createHorizontalGuideNode(xmlCeiling.y, canvasSize))
  }
  if (isVerticalDisplayed) {
    guideElements.push(createVerticalGuideNode(xmlCeiling.x, canvasSize))
  }
  return guideElements
}
