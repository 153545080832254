import React, {
  createContext,
  PropsWithChildren,
  ReducerWithoutAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { ElementUtils } from '../../diagram/utils/hooks/use-element-utils'
import { EdgeElement, NodeElement, Scheme, SchemeInfo } from '../../diagram/scheme/interfaces'
import { FlowTransform, ReactFlowProps } from 'react-flow-renderer'

type EditorContext = ReturnType<typeof useValue>

type Props = ElementUtils & {
  scheme?: Scheme
  schemeInfo?: SchemeInfo
  updateSchemeInfo: (updater: ReducerWithoutAction<SchemeInfo>) => void
}

const Context = createContext<EditorContext>({} as EditorContext)

export function EditorContextProvider({
  children,
  scheme,
  updateElement,
  findElement,
  updateSchemeInfo,
  schemeInfo,
}: PropsWithChildren<Props>) {
  const value = useValue({ scheme, updateElement, findElement, schemeInfo, updateSchemeInfo })
  return <Context.Provider value={value}>{children}</Context.Provider>
}

export function useEditorContext() {
  return useContext(Context)
}

export enum OpenEditorStates {
  closed,
  outcomeEditor,
  edgeEditor,
  narrativeEditor,
  textBoxEditor,
}

function useValue({ scheme, updateElement, findElement, updateSchemeInfo, schemeInfo }: Props) {
  const [activeElementId, setActiveElementId] = useState<string | undefined>()
  const activeElement: NodeElement | EdgeElement | undefined = useMemo(
    () => (activeElementId ? scheme?.find((el) => el.id === activeElementId) : undefined),
    [activeElementId, scheme],
  )
  const [editorStatus, setEditorStatus] = useState<OpenEditorStates>()
  const [newXyPosition, setNewXyPosition] = useState<FlowTransform>()
  const [activeTabIndex, setActiveTabIndex] = useState(0)

  const [currentDefaultPosition, setCurrentDefaultPosition] = useState<ReactFlowProps['defaultPosition']>()
  const [paneMovable, setPaneMovable] = useState<boolean>(true)

  const closeEditor = useCallback(() => {
    setEditorStatus(OpenEditorStates.closed)
  }, [])

  const openEditor = useCallback((type: OpenEditorStates, tabIndex: number = 0) => {
    setEditorStatus(type)
    setActiveTabIndex(tabIndex)
  }, [])

  useEffect(() => {
    setEditorStatus(undefined)
    setActiveElementId(undefined)
  }, [schemeInfo?.id])

  return useMemo(
    () => ({
      openEditor,
      activeElementId,
      activeElement,
      setActiveElementId,
      newXyPosition,
      setNewXyPosition,
      updateElement,
      findElement,
      closeEditor,
      schemeInfo,
      updateSchemeInfo,
      currentDefaultPosition,
      setCurrentDefaultPosition,
      editorStatus,
      activeTabIndex,
      setActiveTabIndex,
      paneMovable,
      setPaneMovable,
    }),
    [
      openEditor,
      activeElementId,
      activeElement,
      setActiveElementId,
      updateElement,
      findElement,
      newXyPosition,
      setNewXyPosition,
      closeEditor,
      schemeInfo,
      updateSchemeInfo,
      currentDefaultPosition,
      setCurrentDefaultPosition,
      editorStatus,
      activeTabIndex,
      setActiveTabIndex,
      paneMovable,
      setPaneMovable,
    ],
  )
}
