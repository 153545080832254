import { Scheme, SchemeInfo } from '../../../../../../components/diagram/scheme/interfaces'
import { convertNodesToXml } from './nodes'
import { convertEdgesToXmlAndGetExtraInfo } from './edges'
import { encodeExtraParams } from '../utils/extra-params'
import { SchemeExtraParams } from '../utils/extra-params/scheme-extra-params'
import { XmlTocoScheme } from '../interface'
import { j2xParser } from 'fast-xml-parser'
import { convertTextBoxToXml } from './text-box'
import { convertCommentsToXml } from './comment'
import { getDateTime } from '../utils/date'
import { convertUnderlyingAssumptionsToXml } from './underlyingAssumptions'
import { XmlCommentsVisibility } from '../xml-enums'
import { convertColorLegend } from './color-legend'
import { convertAccountabilityCeiling } from './accountability-ceiling'

type ConvertSchemeToXmlArg = {
  xmlTocoScheme: XmlTocoScheme.RootObject
  scheme: Scheme
  schemeInfo: SchemeInfo
  tocoInfo: { id: string | undefined; title: string }
  userId: string
}
export function convertSchemeToXml(data: ConvertSchemeToXmlArg) {
  const edgesXmlAndExtraInfo = convertEdgesToXmlAndGetExtraInfo(data.scheme, data.xmlTocoScheme)
  const schemeExtraParams: SchemeExtraParams = {
    edgesInfo: edgesXmlAndExtraInfo.paramField,
  }
  const prevXml = data.xmlTocoScheme
  const isClone = data.tocoInfo.id === undefined

  return `
<Toc>
    ${isClone ? '' : `<TocId>${prevXml?.Toc?.TocId}</TocId>`}
    <Title>${data.tocoInfo.title}</Title>
    ${isClone ? '' : `<CreateUserId>${prevXml?.Toc?.CreateUserId || ''}</CreateUserId>`}
    ${isClone ? '' : `<CreateTimestamp>${prevXml?.Toc?.CreateTimestamp || ''}</CreateTimestamp>`}
    ${isClone ? '' : `<UpdateUserId>${prevXml?.Toc?.UpdateUserId || ''}</UpdateUserId>`}
    <UpdateTimestamp>${isClone ? getDateTime() : prevXml?.Toc?.UpdateTimestamp || ''}</UpdateTimestamp>
    <Shared>${prevXml?.Toc?.Shared}</Shared>
    <TocWidth>${prevXml?.Toc?.TocWidth}</TocWidth>
    <TocHeight>${prevXml?.Toc?.TocHeight}</TocHeight>
    <BackgroundPattern>${prevXml?.Toc?.BackgroundPattern || ''}</BackgroundPattern>
    <IsSavedByUser>${data.schemeInfo.isSavedByUser ? 1 : 0}</IsSavedByUser>
    <Status>${prevXml?.Toc?.Status || ''}</Status>
    <Content>
        <Narrative>${data.schemeInfo.narrative || ''}</Narrative>
        ${convertUnderlyingAssumptionsToXml(data.schemeInfo.underlyingAssumptions)}
        <ColorMeanings>${prevXml?.Toc?.Content?.ColorMeanings || ''}</ColorMeanings>
        ${convertColorLegend(data.scheme)}
        <CanvasTitle>
            <x>${prevXml?.Toc?.Content?.CanvasTitle?.x || 0}</x>
            <y>${prevXml?.Toc?.Content?.CanvasTitle?.y || 0}</y>
            <Width>${prevXml?.Toc?.Content?.CanvasTitle?.Width || 0}</Width>
            <Height>${prevXml?.Toc?.Content?.CanvasTitle?.Height || 0}</Height>
            <Visible>${prevXml?.Toc?.Content?.CanvasTitle?.Visible}</Visible>
            <Text>${prevXml?.Toc?.Content?.CanvasTitle?.Text || ''}</Text>
            <FontFamily>${encodeExtraParams(schemeExtraParams)}</FontFamily>
            <FontColor>${prevXml?.Toc?.Content?.CanvasTitle?.FontColor || 0}</FontColor>
            <FontSize>${prevXml?.Toc?.Content?.CanvasTitle?.FontSize || 0}</FontSize>
            <BorderColor>${prevXml?.Toc?.Content?.CanvasTitle?.BorderColor || 0}</BorderColor>
            <BackgroundColor>${prevXml?.Toc?.Content?.CanvasTitle?.BackgroundColor || 0}</BackgroundColor>
        </CanvasTitle>
        <ConnectorStyle>${prevXml?.Toc?.Content?.ConnectorStyle || ''}</ConnectorStyle>
        <CanvasContent>
            <Width>${prevXml?.Toc?.Content?.CanvasContent?.Width || 0}</Width>
            <Height>${prevXml?.Toc?.Content?.CanvasContent?.Height || 0}</Height>
            ${convertAccountabilityCeiling(data.scheme)}
            
            ${convertNodesToXml(data.scheme, data.xmlTocoScheme)}
            
            ${edgesXmlAndExtraInfo?.xml}
            
            ${convertCommentsToXml(data.scheme, data.xmlTocoScheme, data.userId)}
            
            <CommentVisibility>${
              data.schemeInfo.areCommentsVisible ? XmlCommentsVisibility.visible : XmlCommentsVisibility.hidden
            }</CommentVisibility>
            ${convertTextBoxToXml(data.scheme, data.xmlTocoScheme)}
            <ExternalGraphics>${recoverPrevXmlBody(
              prevXml?.Toc?.Content?.CanvasContent?.ExternalGraphics,
            )}</ExternalGraphics>
            <ShowDatagrid>${prevXml?.Toc?.Content?.CanvasContent?.ShowDatagrid || 0}</ShowDatagrid>
            <CurvedLines>${recoverPrevXmlBody(prevXml?.Toc?.Content?.CanvasContent?.CurvedLines)}
            </CurvedLines>
        </CanvasContent>
    </Content>
</Toc>
`
}

function recoverPrevXmlBody(obj: any) {
  if (obj == null) return ''
  if (!obj) return obj
  const parser = new j2xParser({})
  return parser.parse(obj).replaceAll('><', '>\n<')
}
