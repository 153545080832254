import React, { PropsWithChildren } from "react";
import { Redirect, Route } from "react-router-dom";
import { useAuthContext } from '../context/use-auth-context'
import { RouteProps } from 'react-router'

export function PrivateRoute({ children, ...rest }: PropsWithChildren<RouteProps>) {
  const { authData } = useAuthContext()
  return (
    <Route
      {...rest}
      render={({ location }) =>
        authData ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}
