import { fetchLogIn } from './directory/auth'
import { postCreateToco, fetchList, fetchToco, postUpdateToco } from './main/toco'
import { returnAccess } from './main/toc-access'

export const api = {
  auth: {
    fetchLogIn,
  },
  toco: {
    fetchList,
    fetchToco,
    postCreateToco,
    postUpdateToco,
  },
  tocAccess: {
    returnAccess,
  },
}
