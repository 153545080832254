import { useCallback } from 'react'
import { clearAuthLocalStorage } from '../components/context/use-auth-context'
import { AxiosResponse } from 'axios'
import { useSnackbar } from 'notistack'

export enum ApiErrorMessage {
  connection = 'Connection error',
  server = 'Server error',
  authorization = 'Authorization error',
}

export function getErrorMessageByNetworkStatus(response: AxiosResponse): ApiErrorMessage | undefined {
  const status = response?.request?.status
  switch (status) {
    case 0:
      return ApiErrorMessage.connection
    case 500:
      return ApiErrorMessage.server
    case 401:
    case 403:
      clearAuthLocalStorage()
      return ApiErrorMessage.authorization
  }
}

export type ErrorMessageParser = (response: AxiosResponse) => string | undefined

export function useApiErrorHandler(errorMessageParser: ErrorMessageParser = getErrorMessageByNetworkStatus) {
  const { enqueueSnackbar } = useSnackbar()

  return useCallback(
    (promise: Promise<any>) => {
      promise.catch((responseError: AxiosResponse) => {
        const commonErrorMessage = errorMessageParser(responseError)
        if (commonErrorMessage) {
          enqueueSnackbar(commonErrorMessage, { variant: 'error' })
          return
        }

        throw responseError
      })
    },
    [enqueueSnackbar, errorMessageParser],
  )
}
