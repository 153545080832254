import React, { createContext, PropsWithChildren, useCallback, useContext, useMemo, useState } from 'react'
import { useRemoteDataCallback } from '../../hooks/use-remote-data'
import { LoginRequestI, LoginResponseI } from '../../api/directory/auth'
import { api } from '../../api'
import { useHistory, useLocation } from 'react-router-dom'
import { setTokenHeader } from '../../api/main/_client'
import {
  ApiErrorMessage,
  ErrorMessageParser,
  getErrorMessageByNetworkStatus,
  useApiErrorHandler,
} from '../../hooks/use-api-error-handler'
import { useSnackbar } from 'notistack'

const LS_AUTH = 'auth'
const LS_AUTH_EXPIRATION = 'auth-expiration'
const TOKEN_DURATION = 1000 * 60 * 60 * 24 * 2 // 2 days

const LogInErrorMessages = {
  invalidPassword: 'The username or password is invalid',
  subscriptionLimit: 'You must be a paid user to access TOCO',
}

type AuthContext = ReturnType<typeof useProvideAuth>

const Context = createContext<AuthContext>({} as AuthContext)

export function AuthContextProvider({ children }: PropsWithChildren<{}>) {
  const auth = useProvideAuth()
  return <Context.Provider value={auth}>{children}</Context.Provider>
}

export function useAuthContext() {
  return useContext(Context)
}

function useProvideAuth() {
  const history = useHistory()
  const location = useLocation()
  const [authData, setAuthData] = useState<LoginResponseI | null>(() => {
    const storedAuthData = localStorage.getItem(LS_AUTH)
    const expireDate = parseInt(localStorage.getItem(LS_AUTH_EXPIRATION) || '', 10)
    if (!storedAuthData || isNaN(expireDate) || expireDate < Date.now()) {
      localStorage.removeItem(LS_AUTH)
      localStorage.removeItem(LS_AUTH_EXPIRATION)
      return null
    }
    try {
      const userInfo = JSON.parse(storedAuthData)
      setTokenHeader(userInfo.token)
      return userInfo
    } catch {
      return null
    }
  })
  const [fetchLogIn, logInLoading] = useRemoteDataCallback(api.auth.fetchLogIn, false)

  const { enqueueSnackbar } = useSnackbar()
  const errorMessageParser = useCallback<ErrorMessageParser>((response) => {
    const message = getErrorMessageByNetworkStatus(response)
    if (message === ApiErrorMessage.authorization) {
      return LogInErrorMessages.invalidPassword
    }
    return message
  }, [])
  const apiErrorHandler = useApiErrorHandler(errorMessageParser)
  const logIn = useCallback(
    async (agr: LoginRequestI) => {
      const logInPromise = fetchLogIn(agr)
      apiErrorHandler(logInPromise)
      const userInfo = await logInPromise
      if (userInfo.subscriptionId === 'free') {
        enqueueSnackbar(LogInErrorMessages.subscriptionLimit, { variant: 'error' })
        return
      }
      localStorage.setItem(LS_AUTH, JSON.stringify(userInfo)) // todo analise: is it enough to store only token
      localStorage.setItem(LS_AUTH_EXPIRATION, `${Date.now() + TOKEN_DURATION}`)
      setTokenHeader(userInfo.token)
      setAuthData(userInfo)
      const { from } = (location.state as any) || { from: { pathname: '/' } }
      history.replace(from)
      return userInfo
    },
    [apiErrorHandler, enqueueSnackbar, fetchLogIn, history, location.state],
  )

  const logOut = useCallback(() => {
    clearAuthLocalStorage()
    window.location.reload()
  }, [])

  return useMemo(
    () => ({
      authData,
      logIn,
      logInLoading,
      logOut,
    }),
    [authData, logIn, logInLoading, logOut],
  )
}

export function clearAuthLocalStorage() {
  localStorage.removeItem(LS_AUTH)
  localStorage.removeItem(LS_AUTH_EXPIRATION)
}
