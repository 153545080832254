import React, { memo, useCallback, useRef } from 'react'
import { NodeProps } from 'react-flow-renderer'
import { ColorLegendItem, NodeElementWithType } from '../../scheme/interfaces'
import { NodeSchemeType } from '../../scheme/enums'
import styles from './styles.module.scss'
import { useEditorContext } from '../../../editor/context/editor-context-provider'
import { useInnerSchemeAccessContext } from '../../context/use-inner-scheme-access'
import { useDraggableDisabler } from '../../utils/hooks/use-draggable-disabler'
import { useColorsWatcher } from './use-colors-watcher'
import RemoveIcon from '@material-ui/icons/Close'
import { DragDropContext, Draggable, DraggingStyle, Droppable } from 'react-beautiful-dnd'

type ColorLegendData = { legendItems: ColorLegendItem[] }

export type ColorLegendNodeElement = NodeElementWithType<NodeSchemeType.colorLegend, ColorLegendData>

export const ColorLegendNode = memo(({ id, data: { legendItems } }: NodeProps<ColorLegendData>) => {
  useColorsWatcher(id)
  const { setActiveElementId, schemeInfo } = useEditorContext()
  const { updateElement } = useInnerSchemeAccessContext()
  const { allowDrag, denyDrag } = useDraggableDisabler(updateElement, id)
  const setAsActiveElement = useCallback(() => {
    setActiveElementId(id)
  }, [id, setActiveElementId])
  const removeColorItem = (color: string) => {
    updateElement<ColorLegendNodeElement>(id, (el) => ({
      ...el,
      data: {
        ...el.data,
        legendItems: el.data.legendItems.filter((item) => item.color !== color),
      },
    }))
  }

  const onDragEnd = useCallback(
    (result) => {
      // dropped outside the list
      if (!result.destination) {
        return
      }
      const list = reorder(legendItems, result.source.index, result.destination.index)

      updateElement<ColorLegendNodeElement>(id, (el) => ({
        ...el,
        data: {
          ...el.data,
          legendItems: list,
        },
      }))
    },
    [id, legendItems, updateElement],
  )
  const containerRef = useRef<HTMLDivElement>()
  return (
    <div onClick={setAsActiveElement} ref={containerRef as any}>
      <div className={styles.title}>Color Legend</div>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(dropProvided) => (
            <div {...dropProvided.droppableProps} ref={dropProvided.innerRef} className={styles.list}>
              {legendItems.map((legendItem, index) => (
                <Draggable key={legendItem.color} draggableId={legendItem.color} index={index}>
                  {(dragProvided, dragSnapshot) => {
                    const style: React.CSSProperties = {
                      ...dragProvided.draggableProps.style,
                    }

                    if (dragSnapshot.isDragging) {
                      // fix dragging element position. Library calc position by window but it is rendering by parent position
                      const containerRect = containerRef.current?.getClientRects()[0]
                      if (containerRect) {
                        const zoom = schemeInfo?.zoom || 1
                        const draggingStyle = dragProvided.draggableProps.style as DraggingStyle
                        style.left = draggingStyle.left - containerRect.left
                        style.top = (draggingStyle.top - containerRect.top) / zoom // todo fix zoom
                      }
                    }
                    return (
                      <div
                        key={legendItem.color}
                        className={styles.row}
                        onMouseEnter={denyDrag}
                        onMouseLeave={allowDrag}
                        ref={dragProvided.innerRef}
                        {...dragProvided.draggableProps}
                        {...dragProvided.dragHandleProps}
                        style={style}
                      >
                        <span className={styles.colorPreview} style={{ background: legendItem.color }} />
                        <input
                          className={styles.text}
                          value={legendItem.text}
                          onChange={(event) => {
                            updateElement<ColorLegendNodeElement>(id, (el) => ({
                              ...el,
                              data: {
                                ...el.data,
                                legendItems: el.data.legendItems.map((prev) =>
                                  prev.color === legendItem.color ? { ...prev, text: event.target.value } : prev,
                                ),
                              },
                            }))
                          }}
                        />
                        <span className={styles.remove} onClick={() => removeColorItem(legendItem.color)}>
                          <RemoveIcon style={{ fontSize: 16 }} />
                        </span>
                      </div>
                    )
                  }}
                </Draggable>
              ))}
              {dropProvided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  )
})

// a little function to help us with reordering the result
function reorder<T extends any[]>(list: T, startIndex: number, endIndex: number): T {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result as T
}
