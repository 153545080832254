import React, { memo, ReactNode, useCallback, useRef, useState } from 'react'
import { Dimensions, EdgeTextProps } from 'react-flow-renderer'
import Measure from 'react-measure'
import { useEditorContext } from '../../../../editor/context/editor-context-provider'

export type EdgeContentProps = Omit<EdgeTextProps, 'label'> & { label?: ReactNode }

/**
 * Overrides EdgeText to allow use HTML label instead string label
 */
export const EdgeContent = memo(({ x, y, label }: EdgeContentProps) => {
  const edgeRef = useRef<SVGForeignObjectElement>(null)
  const [contentDimensions, setContentDimensions] = useState<Dimensions>({ width: 100, height: 100 })

  const { newXyPosition } = useEditorContext()

  const newZoom = newXyPosition ? newXyPosition?.zoom! : 1

  const onChildrenResize = useCallback(
    (ContentRect) => {
      setContentDimensions({
        width: ContentRect.bounds?.width! / newZoom,
        height: ContentRect.bounds?.height! / newZoom,
      })
    },
    [newZoom],
  )

  const measureChildrenCallback = useCallback(
    ({ measureRef }) => (
      <div style={measureDivStyle} ref={measureRef}>
        {label}
      </div>
    ),
    [label],
  )

  return (
    <foreignObject
      className="react-flow__edge-content"
      ref={edgeRef}
      style={{
        width: contentDimensions.width,
        height: contentDimensions.height,
        position: 'fixed',
        overflow: 'initial',
      }}
      transform={`translate(${x - contentDimensions.width / 2} ${y - contentDimensions.height / 2})`}
    >
      <Measure bounds onResize={onChildrenResize}>
        {measureChildrenCallback}
      </Measure>
    </foreignObject>
  )
})

const measureDivStyle = { display: 'inline-block' }
