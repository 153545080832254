import React from 'react'
import styles from './index.module.scss'
import { CircularProgress } from '@material-ui/core'

export function Spinner({ show, overlay }: { show: boolean; overlay?: boolean }) {
  if (!show) {
    return null
  }
  return (
    <div className={overlay ? styles.overlay : ''}>
      <div className={styles.spinner}>
        <CircularProgress />
      </div>
    </div>
  )
}
