import React from 'react'
import { OutcomeEditor } from './outcome-editor'
import { ConnectorEditor } from './connector-editor'
import { NarrativeEditor } from './narrative-editor'
import { TextBoxEditor } from './text-box-editor'
import { OpenEditorStates, useEditorContext } from './context/editor-context-provider'

export function Editors() {
  const { editorStatus } = useEditorContext()
  switch (editorStatus) {
    case OpenEditorStates.outcomeEditor:
      return <OutcomeEditor />
    case OpenEditorStates.edgeEditor:
      return <ConnectorEditor />
    case OpenEditorStates.narrativeEditor:
      return <NarrativeEditor />
    case OpenEditorStates.textBoxEditor:
      return <TextBoxEditor />
    default:
      return null
  }
}
