import React from 'react'
import { BaseMenu } from '../base-menu'

export const HelpMenu = () => {
  const helpList = [
    {
      label: 'Open Help Wiki',
      handle: () => {
        window.open('http://help.actknowledge.org/index.php?title=Main_Page', '_blank')
      },
      disabled: false,
    },
    {
      label: 'Sample finished TOC',
      handle: () => {
        window.open('https://www.theoryofchange.org/library/toc-examples/', '_blank')
      },
      disabled: false,
    },
    {
      label: 'About Center for Theory of Change',
      handle: () => {
        window.open('https://www.theoryofchange.org/', '_blank')
      },
      disabled: false,
    },
    {
      label: 'About Actknowledge',
      handle: () => {
        window.open('https://www.actknowledge.org/', '_blank')
      },
      disabled: false,
    },
  ]
  return <BaseMenu list={helpList} title={'HELP'} />
}
