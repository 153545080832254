import React, { PropsWithChildren, ReactNode } from 'react'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Typography from '@material-ui/core/Typography'
import { Dialog, DialogActions, DialogContent } from '@material-ui/core'
import styles from './index.module.scss'

type SimpleModalBodyProps = {
  onClose: () => void
  title: ReactNode
  body: ReactNode
  footer: ReactNode
}
export function SimpleModalBody({ onClose, title, body, footer }: SimpleModalBodyProps) {
  return (
    <Dialog onClose={onClose} open={true}>
      <ModalTitle onClose={onClose}>{title}</ModalTitle>
      <DialogContent dividers>{body}</DialogContent>
      <DialogActions>{footer}</DialogActions>
    </Dialog>
  )
}

type ModalTitleProps = {
  onClose: () => void
}
export function ModalTitle({ onClose, children }: PropsWithChildren<ModalTitleProps>) {
  return (
    <MuiDialogTitle disableTypography>
      <Typography variant="h6">{children}</Typography>
      <IconButton onClick={onClose} className={styles.closeIcon}>
        <CloseIcon />
      </IconButton>
    </MuiDialogTitle>
  )
}
