import { Checkbox } from '@material-ui/core'
import React from 'react'
import horizontalCeilingUrl from './images/horizontal-ceiling.png'
import verticalCeilingUrl from './images/vertical-ceiling.png'
import { ShowHideAddAccountabilityCeiling } from './show-hide-accountability-ceiling'
import styles from './styles.module.scss'

export function AddAccountabilityCeiling() {
  const { addOrRemoveHorizontal, addOrRemoveVertical, horizontal, vertical } = ShowHideAddAccountabilityCeiling()
  return (
    <>
      <div className={styles.wrapper}>
        <Checkbox color="primary" checked={!!horizontal} onChange={addOrRemoveHorizontal} className={styles.checkbox} />
        <img src={horizontalCeilingUrl} alt="Horizontal Ceiling" />
      </div>
      <div className={styles.wrapper}>
        <Checkbox color="primary" checked={!!vertical} onChange={addOrRemoveVertical} className={styles.checkbox} />
        <img src={verticalCeilingUrl} alt="Vertical Ceiling" />
      </div>
    </>
  )
}
