import { Box, Button, IconButton } from '@material-ui/core'
import styles from './index.module.scss'
import React, { useCallback, useState } from 'react'
import { SketchPicker } from 'react-color'
import { PRESET_COLORS } from '../../../diagram/indicators-bar/color-picker/preset-color'
import { useVisible } from '../../../diagram/utils/hooks/use-visible'
import { useEditorContext } from '../../../editor/context/editor-context-provider'
import { OutcomeNodeElement } from '../../../diagram/scheme/interfaces'
import { useAddOutcomeNode } from '../../hooks/add-shape/use-add-outcome-node'
import { isOutcomeNode, isTextBoxNode } from '../../../diagram/utils/check-node-type'
import { OutcomeShapePreview, outcomeShapePreviewOrder } from '../../../outcome-shape-preview'

export const ShapesAndPicker = () => {
  const [newColor, setNewColor] = useState(PRESET_COLORS[0])
  return (
    <>
      <Shapes newColor={newColor} />
      <Picker newColor={newColor} setNewColor={setNewColor} />
    </>
  )
}

const Shapes = ({ newColor }: { newColor: string }) => {
  const { activeElement, updateElement } = useEditorContext()
  const addOutcome = useAddOutcomeNode()

  const createOnClickHandler = (newNodeType: OutcomeNodeElement['type']) => () => {
    if (isOutcomeNode(activeElement)) {
      updateElement<OutcomeNodeElement>(activeElement.id, (el) => ({ ...el, type: newNodeType }))
    } else {
      addOutcome(newNodeType, newColor)
    }
  }

  return (
    <>
      {outcomeShapePreviewOrder.map((type) => (
        <IconButton
          key={type}
          onClick={createOnClickHandler(type)}
          color={'primary'}
          className={styles.shapes}
          size={'small'}
        >
          <OutcomeShapePreview type={type} />
        </IconButton>
      ))}
    </>
  )
}

const Picker = ({ newColor, setNewColor }: { newColor: string; setNewColor: (color: string) => void }) => {
  const { activeElement, updateElement } = useEditorContext()
  const { ref, isVisible, toggleVisible } = useVisible()

  const onChangeColor = useCallback(
    ({ rgb: { r, g, b, a } }) => {
      const rgba = `rgba(${r},${g},${b},${a})`
      if (isOutcomeNode(activeElement) || isTextBoxNode(activeElement)) {
        updateElement<any>(activeElement.id, (el) => ({ ...el, data: { ...el.data, color: rgba } }))
      }
      setNewColor(rgba)
    },
    [activeElement, setNewColor, updateElement],
  )

  return (
    <Box className={styles.colorPicker}>
      <Button
        variant="contained"
        className={styles.colorPickerBtn}
        onClick={() => toggleVisible(!isVisible)}
        style={{ backgroundColor: newColor, transition: 'none' }}
      />
      {isVisible && (
        <div className={styles.picker} ref={ref}>
          <SketchPicker color={newColor} onChange={onChangeColor} presetColors={PRESET_COLORS} />
        </div>
      )}
    </Box>
  )
}
