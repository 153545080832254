import { XmlTocoScheme } from '../interface'
import { NodeSchemeType } from '../../../../../../components/diagram/scheme/enums'
import { TextBoxNodeElement } from '../../../../../../components/diagram/shapes/text-box'
import { CallOutNodeElement } from '../../../../../../components/diagram/shapes/call-out'
import { decodeHtmlAndUpdateFont } from '../utils/decode-html'
import { tryDecodeExtraParam } from '../utils/extra-params'
import { NodeExtraParams } from '../utils/extra-params/node-extra-params'
import { convertNumberColorToRgba } from '../utils/color'

const TEXT_BOX_PREFIX = 'tb_'
const CALL_OUT_PREFIX = 'co_'

export function parseTextBoxOrCallout(
  xmlAnnotation: XmlTocoScheme.Annotation,
): TextBoxNodeElement | CallOutNodeElement {
  const extraParams = tryDecodeExtraParam<NodeExtraParams>(xmlAnnotation.textStyle)
  const isCallOut = xmlAnnotation.Type === 'callout'
  const prefix = isCallOut ? CALL_OUT_PREFIX : TEXT_BOX_PREFIX
  const type = isCallOut ? NodeSchemeType.callOut : NodeSchemeType.textBox

  return {
    id: `${prefix}${xmlAnnotation.AnnotationId}`,
    type,
    position: { x: xmlAnnotation.x, y: xmlAnnotation.y },
    style: { width: xmlAnnotation.Width, height: xmlAnnotation.Height },
    data: {
      text: decodeHtmlAndUpdateFont(xmlAnnotation.Text),
      color: convertNumberColorToRgba(xmlAnnotation.BackgroundColor, extraParams?.opacity),
      annotationId: xmlAnnotation.AnnotationId,
    },
  } as any //TO DO FIX FOR callOut
}
