import React, { ChangeEvent, memo, useCallback } from 'react'
import { NodeProps } from 'react-flow-renderer'
import { NodeElementWithType } from '../../scheme/interfaces'
import { NodeSchemeType } from '../../scheme/enums'
import styles from './styles.module.scss'
import clsx from 'clsx'
import { ResizableShapeWrapper } from '../../resizable-shape-wrapper'
import { useInnerSchemeAccessContext } from '../../context/use-inner-scheme-access'
import { useEditorContext } from '../../../editor/context/editor-context-provider'

type CommentData = {
  commentId?: number
  text: string
  creator: string
  isActKnowledgeComment: boolean
}

export type CommentNodeElement = NodeElementWithType<NodeSchemeType.comment, CommentData>

export const CommentNode = memo(({ id, data: { text, creator, isActKnowledgeComment } }: NodeProps<CommentData>) => {
  const { updateElement } = useInnerSchemeAccessContext()
  const { setActiveElementId } = useEditorContext()
  const onChange = useCallback(
    (event: ChangeEvent<any>) => {
      updateElement<CommentNodeElement>(id, (el) => ({
        ...el,
        data: {
          ...el.data,
          text: event.target.value,
        },
      }))
    },
    [id, updateElement],
  )
  const setAsActiveElement = useCallback(() => {
    setActiveElementId(id)
  }, [id, setActiveElementId])

  return (
    <div onClick={setAsActiveElement}>
      <ResizableShapeWrapper nodeId={id}>
        <div className={clsx(styles.comment, isActKnowledgeComment && styles.isActKnowledgeComment, 'selected')}>
          <div className={styles.creator}>{isActKnowledgeComment ? 'ActKnowledge' : creator}</div>
          <div className={styles.scrollWrapper}>
            <textarea className={styles.textarea} defaultValue={text} onChange={onChange} />
          </div>
        </div>
      </ResizableShapeWrapper>
    </div>
  )
})
