import { CanvasSize, Scheme } from '../../../../diagram/scheme/interfaces'
import { AccountabilityCeilingNodeElement } from '../../../../diagram/shapes/accountability-ceiling'
import { NodeSchemeType } from '../../../../diagram/scheme/enums'

const GUIDE_SIZE = 2
export const HORIZONTAL_GUIDE_NODE_ID = 'horizontal'
export const VERTICAL_GUIDE_NODE_ID = 'vertical'

export function findGuide(scheme: Scheme | undefined, type: 'horizontal' | 'vertical') {
  return scheme?.find((el) => el.id === type) as AccountabilityCeilingNodeElement | undefined
}

export function createHorizontalGuideNode(y: number, canvasSize: CanvasSize): AccountabilityCeilingNodeElement {
  return {
    id: HORIZONTAL_GUIDE_NODE_ID,
    type: NodeSchemeType.accountabilityCeiling,
    position: { x: 0, y },
    style: { width: canvasSize.width, height: GUIDE_SIZE },
    data: { isHorizontal: true },
  }
}

export function createVerticalGuideNode(x: number, canvasSize: CanvasSize): AccountabilityCeilingNodeElement {
  return {
    id: VERTICAL_GUIDE_NODE_ID,
    type: NodeSchemeType.accountabilityCeiling,
    position: { x, y: 0 },
    style: { width: GUIDE_SIZE, height: canvasSize.height },
    data: { isHorizontal: false },
  }
}
