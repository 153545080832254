import React, { memo, ReducerWithoutAction, useCallback } from 'react'
import { useEditorContext } from '../../../context/editor-context-provider'
import { EdgeElement } from '../../../../diagram/scheme/interfaces'
import { Intervention } from '../../../../../dto'
import { useUpdateActiveEdgeData } from '../../../components/hooks/use-update-active-edge-data'
import { ElementList } from '../../../../element-list'
import { Box, Checkbox, FormControl, FormControlLabel, FormGroup, Typography } from '@material-ui/core'
import { QuillEditor } from '../../../components/react-quill'
import styles from './index.module.scss'

const DEFAULT_INTERVENTION: Intervention = { isFullText: false, text: '' }

export const InterventionsTab = memo(() => {
  const { activeElement } = useEditorContext()
  const edge = activeElement as EdgeElement
  const updateActiveEdgeData = useUpdateActiveEdgeData()

  const addIntervention = useCallback(() => {
    updateActiveEdgeData((prevData) => {
      const interventions = [...(prevData?.interventions || []), DEFAULT_INTERVENTION]
      return {
        interventions,
      }
    })
  }, [updateActiveEdgeData])

  const removeElement = useCallback(
    (activeIndex: number) => {
      updateActiveEdgeData((prevData) => ({
        interventions: prevData!.interventions!.filter((_, index) => index !== activeIndex),
      }))
    },
    [updateActiveEdgeData],
  )

  const renderContent = useCallback((activeIndex: number) => <Content activeIndex={activeIndex} edge={edge} />, [edge])
  return (
    <ElementList
      resetKey={edge.id}
      renderContent={renderContent}
      onRemoveElement={removeElement}
      onAddElement={addIntervention}
      elements={edge.data?.interventions || []}
    />
  )
})

type ContentProps = {
  activeIndex: number
  edge: EdgeElement
}

const Content = ({ activeIndex, edge }: ContentProps) => {
  const activeIntervention = edge.data?.interventions?.[activeIndex]!
  const updateActiveEdgeData = useUpdateActiveEdgeData()

  const updateIntervention = useCallback(
    (updater: ReducerWithoutAction<Intervention>) => {
      updateActiveEdgeData((prevData) => ({
        interventions: prevData?.interventions?.map((prevIntervention, i) =>
          i === activeIndex ? updater(prevIntervention) : prevIntervention,
        ),
      }))
    },
    [updateActiveEdgeData, activeIndex],
  )

  const updateText = useCallback(
    (text) => {
      updateIntervention((prevIntervention) => ({
        ...prevIntervention,
        text: text,
      }))
    },
    [updateIntervention],
  )
  const toggleVisibility = useCallback(() => {
    updateIntervention((prevIntervention) => ({
      ...prevIntervention,
      isFullText: !prevIntervention.isFullText,
    }))
  }, [updateIntervention])

  return (
    <section>
      <Typography component="span">Edit Intervention:</Typography>
      <Box mt={2} className={styles.interventionEditor}>
        <QuillEditor
          placeholder={'Name intervention'}
          value={activeIntervention.text || DEFAULT_INTERVENTION.text}
          onChange={updateText}
          key={activeIndex}
        />
      </Box>
      <Box mb={1}>
        <FormControl component="fieldset">
          <FormGroup aria-label="position" row>
            <FormControlLabel
              value="Show full text"
              control={
                <Checkbox
                  color="primary"
                  checked={
                    activeIntervention.isFullText != null
                      ? activeIntervention.isFullText
                      : DEFAULT_INTERVENTION.isFullText
                  }
                  onChange={toggleVisibility}
                />
              }
              label="Show full text"
              labelPlacement="end"
            />
          </FormGroup>
        </FormControl>
      </Box>
    </section>
  )
}
