import React, { memo, useCallback, useMemo } from 'react'
import { NodeProps } from 'react-flow-renderer'
import { NodeElementWithType } from '../../scheme/interfaces'
import { NodeSchemeType } from '../../scheme/enums'
import styles from './styles.module.scss'
import { ResizableShapeWrapper } from '../../resizable-shape-wrapper'
import clsx from 'clsx'
import { OpenEditorStates, useEditorContext } from '../../../editor/context/editor-context-provider'

type TextBoxData = {
  text: string
  color?: string
  annotationId?: number
}

export type TextBoxNodeElement = NodeElementWithType<NodeSchemeType.textBox, TextBoxData>

export const TextBoxNode = memo(({ id, data }: NodeProps<TextBoxData>) => {
  const html = useMemo(() => ({ __html: data.text || '' }), [data.text])
  const style = useMemo(
    () => ({
      background: data.color,
    }),
    [data.color],
  )
  const { setActiveElementId, openEditor } = useEditorContext()

  const setTextBoxId = useCallback(() => {
    setActiveElementId(id)
  }, [id, setActiveElementId])

  const openTextBoxEditor = useCallback(() => {
    openEditor(OpenEditorStates.textBoxEditor)
  }, [openEditor])

  return (
    <div onDoubleClick={openTextBoxEditor} onClick={setTextBoxId}>
      <ResizableShapeWrapper nodeId={id}>
        <div className={clsx(styles.textBox, 'selected')} style={style}>
          <div className={clsx(styles.text, 'ql-editor')} dangerouslySetInnerHTML={html} />
        </div>
      </ResizableShapeWrapper>
    </div>
  )
})
