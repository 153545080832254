import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import React, { useCallback } from 'react'
import { useModalContext } from '../../../../../components/modal/context/use-modal-context'
import { SimpleModalBody } from '../../../../../components/modal'

type AccessSchemeErrorModalBodyProps = { openedByName: string; onClose: () => void }
export function AccessSchemeErrorModalBody({ openedByName, onClose }: AccessSchemeErrorModalBodyProps) {
  const { hideModal } = useModalContext()
  const closeHandler = useCallback(() => {
    onClose()
    hideModal()
  }, [onClose, hideModal])
  return (
    <SimpleModalBody
      onClose={closeHandler}
      title={<Typography gutterBottom>Can't open diagram</Typography>}
      body={
        <Typography gutterBottom>
          Sorry, but the diagram that you are trying to access can not be opened. It might be due to this diagram being
          opened by {openedByName || 'somebody else'}.
          <br />
          Please check if the diagram has already been opened or try again later.
        </Typography>
      }
      footer={
        <Button autoFocus onClick={closeHandler} color="primary">
          OK
        </Button>
      }
    />
  )
}

type ParseSchemeErrorModalBodyProps = { onClose: () => void }
export function ParseSchemeErrorModalBody({ onClose }: ParseSchemeErrorModalBodyProps) {
  const { hideModal } = useModalContext()
  const closeHandler = useCallback(() => {
    onClose()
    hideModal()
  }, [onClose, hideModal])
  return (
    <SimpleModalBody
      onClose={closeHandler}
      title={<Typography gutterBottom>Can't open diagram</Typography>}
      body={
        <Typography gutterBottom>
          Sorry, but the diagram that you are trying to access can not be opened. It might be due to internal error.
          <br />
          Please try again later.
        </Typography>
      }
      footer={
        <Button autoFocus onClick={closeHandler} color="primary">
          OK
        </Button>
      }
    />
  )
}
