import React, { useCallback } from 'react'
import { Scheme, SchemeInfo } from '../components/diagram/scheme/interfaces'
import { XmlTocoScheme } from '../pages/layout/pages/dashboard/scheme-parser/interface'
import { AccessError, ParseError, parseScheme } from '../pages/layout/pages/dashboard/scheme-parser/xml-to-js'
import {
  AccessSchemeErrorModalBody,
  ParseSchemeErrorModalBody,
} from '../pages/layout/pages/dashboard/modal/scheme-error-modal'
import { api } from '../api'
import { useModalContext } from '../components/modal/context/use-modal-context'
import { useAuthContext } from '../components/context/use-auth-context'

export function useParseLoadedToco() {
  const { showModal } = useModalContext()
  const { authData } = useAuthContext()
  const userId = authData!.userId
  return useCallback(
    (promise: Promise<string>, tocId: string, onCloseErrorModel: () => void = () => {}) => {
      return promise
        .then((tocoXml) => {
          let scheme: Scheme | undefined
          let schemeInfo: SchemeInfo | undefined
          let xmlTocoScheme: XmlTocoScheme.RootObject | undefined
          try {
            const parsedXml = parseScheme(tocoXml)
            scheme = parsedXml.scheme
            schemeInfo = parsedXml.schemeInfo
            xmlTocoScheme = parsedXml.xmlTocoScheme
          } catch (e) {
            if (e instanceof AccessError) {
              showModal(<AccessSchemeErrorModalBody openedByName={e.message} onClose={onCloseErrorModel} />)
            } else if (e instanceof ParseError) {
              showModal(<ParseSchemeErrorModalBody onClose={onCloseErrorModel} />)
            } else {
              showModal(<ParseSchemeErrorModalBody onClose={onCloseErrorModel} />)
              console.error(e)
            }
          }

          return {
            scheme,
            schemeInfo,
            xmlTocoScheme,
          }
        })
        .finally(() => {
          api.tocAccess.returnAccess({
            tocId,
            userId,
          })
        })
    },
    [showModal, userId],
  )
}
