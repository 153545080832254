import { Box, Checkbox, FormControlLabel, TextField, Typography } from '@material-ui/core'
import React, { memo, useCallback, useMemo } from 'react'
import { ColorPicker } from '../../../../diagram/indicators-bar/color-picker'
import { useEditorContext } from '../../../context/editor-context-provider'
import styles from './index.module.scss'
import { OutcomeNodeElement } from '../../../../diagram/scheme/interfaces'
import 'react-quill/dist/quill.snow.css'
import { UpdateTypeShape } from './update-type-shape'
import { useVisible } from '../../../../diagram/utils/hooks/use-visible'
import { QuillEditor } from '../../../components/react-quill'
import { useDashboardPageContext } from '../../../../../pages/layout/pages/dashboard/context/use-dashboard-page-context'
import { isOutcomeNode } from '../../../../diagram/utils/check-node-type'
import { decodeHtml, removeTags } from '../../../../../pages/layout/pages/dashboard/scheme-parser/utils/decode-html'

export const BasicsTab = memo(() => {
  const { activeElement, updateElement } = useEditorContext()
  const { updateScheme, scheme } = useDashboardPageContext()
  const node: OutcomeNodeElement = activeElement as OutcomeNodeElement
  const { ref, isVisible, toggleVisible } = useVisible()
  const selectedColorStyle = useMemo(() => ({ backgroundColor: node.data.color }), [node.data.color])
  const onChangeTitle = useCallback(
    (title) => {
      updateElement<OutcomeNodeElement>(node.id, (el) => ({ ...el, data: { ...el.data, title } }))
    },
    [node.id, updateElement],
  )

  const onChangeDescription = useCallback(
    (description) => {
      updateElement<OutcomeNodeElement>(node.id, (el) => ({ ...el, data: { ...el.data, description } }))
    },
    [node.id, updateElement],
  )

  const applyStyle = useCallback(
    (e) => {
      if (e.target.checked) {
        const prevNodes: OutcomeNodeElement[] = scheme!.filter(isOutcomeNode)
        const newNodes: OutcomeNodeElement[] = prevNodes.map((el) => {
          const wrap = document.createElement('div')
          wrap.innerHTML = node.data.title!
          let parent = wrap
          while (parent.children?.length === 1) {
            parent = parent.children[0] as HTMLDivElement
          }
          const nodesContentImg = el.data.title!.match(/<img\s[^>]*?src\s*=\s*['"]([^'"]*?)['"][^>]*?>/g)
          const nodesContent = decodeHtml(removeTags(el.data.title!))
          parent.innerHTML =
            nodesContent !== undefined
              ? `${nodesContentImg === null ? nodesContent : nodesContent + nodesContentImg}`
              : ''
          return {
            ...el,
            data: {
              ...el.data,
              title: wrap.innerHTML,
            },
          }
        })
        updateScheme((prevScheme) =>
          prevScheme.map(
            (el) => (newNodes.find((newNode) => newNode.id === el.id && node.id !== newNode.id) as any) || el,
          ),
        )
      }
    },
    [node, scheme, updateScheme],
  )

  return (
    <Box p={1} className={styles.wrapper}>
      <QuillEditor value={node.data.title || ''} onChange={onChangeTitle} />
      <Box mb={2}>
        <TextField
          id="outlined-multiline-static"
          label="Description:"
          className="w-100"
          multiline
          rows={4}
          variant="outlined"
          value={node.data.description || ''}
          onChange={(event) => onChangeDescription(event.target.value)}
        />
      </Box>
      <Box mb={2} display="flex" alignItems="center">
        <Typography component="span">Type:</Typography>
        <Box ml={1}>
          <UpdateTypeShape />
        </Box>
      </Box>
      <Box mb={2} display="flex">
        <Box mr={2}>
          <Typography component="span">Color:</Typography>
          <Box className={styles.colorPicker} mt={1}>
            <Box
              className={styles.selectedColor}
              onClick={() => toggleVisible(!isVisible)}
              style={selectedColorStyle}
              mr={2}
            />
          </Box>
          {isVisible && node.id && (
            <div ref={ref}>
              <ColorPicker
                updateElement={updateElement}
                nodeId={node.id}
                shapeColor={node.data.color}
                pickerWrapperClassName={styles.pickerWrapper}
              />
            </div>
          )}
        </Box>

        <Box mt={2.6}>
          <FormControlLabel
            control={<Checkbox color="primary" onChange={applyStyle} />}
            label="Apply to all Outcomes"
          />
        </Box>
      </Box>
    </Box>
  )
})
