import { EdgeElement, OutcomeNodeElement, SchemeElement } from '../scheme/interfaces'
import { NodeSchemeType } from '../scheme/enums'
import { CallOutNodeElement } from '../shapes/call-out'
import { TextBoxNodeElement } from '../shapes/text-box'
import { isEdge } from 'react-flow-renderer'
import { ColorLegendNodeElement } from '../shapes/color-legend'

const outcomeTypes = [
  NodeSchemeType.triangle,
  NodeSchemeType.rectangle,
  NodeSchemeType.roundedRectangle,
  NodeSchemeType.pentagon,
  NodeSchemeType.hexagon,
  NodeSchemeType.octagon,
  NodeSchemeType.ellipse,
]

export function isOutcomeNode(element: SchemeElement | undefined): element is OutcomeNodeElement {
  return element ? outcomeTypes.includes(element.type as any) : false
}

export function isCallOutNode(element: SchemeElement | undefined): element is CallOutNodeElement {
  return NodeSchemeType.callOut === element?.type
}

export function isTextBoxNode(element: SchemeElement | undefined): element is TextBoxNodeElement {
  return NodeSchemeType.textBox === element?.type
}

export function isCommentNode(element: SchemeElement | undefined): element is TextBoxNodeElement {
  return NodeSchemeType.comment === element?.type
}

export function isEdgeElement(element: SchemeElement | undefined): element is EdgeElement {
  return element ? isEdge(element as any) : false
}

export function isColorLegend(element: SchemeElement | undefined): element is ColorLegendNodeElement {
  return NodeSchemeType.colorLegend === element?.type
}
