import { mainApiClient } from './_client'

export type ReturnAccessRequestI = {
  tocId: string
  userId: string
}

export function returnAccess({ tocId, userId }: ReturnAccessRequestI) {
  const params = { TocId: tocId, UserId: userId }
  return mainApiClient.post<string>(`api/v2/tocaccess`, params, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
}
