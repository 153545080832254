import { Flow, FlowConfigurationProvider } from '../../components/diagram'
import React, { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useAuthContext } from '../../components/context/use-auth-context'
import { useRemoteDataCallback } from '../../hooks/use-remote-data'
import { api } from '../../api'
import { Spinner } from '../../components/spinner'
import { useParseLoadedToco } from '../../hooks/use-parse-loaded-toco'
import { Scheme, SchemeInfo } from '../../components/diagram/scheme/interfaces'
import { Helmet } from 'react-helmet'
import moment from 'moment'
import { setZIndexes } from '../../components/diagram/utils/set-z-index-util'

export function Print() {
  const { tocId } = useParams<{ tocId: string }>()
  const { authData } = useAuthContext()
  const [fetchToco, loading] = useRemoteDataCallback(api.toco.fetchToco)
  const parseLoadedToco = useParseLoadedToco()
  const userId = authData?.userId
  const [scheme, setScheme] = useState<Scheme>()
  const [schemeInfo, setSchemeInfo] = useState<SchemeInfo>()

  useEffect(() => {
    if (userId && tocId) {
      const fetchPromise = fetchToco({
        tocoId: tocId,
        userId,
      })

      parseLoadedToco(fetchPromise as Promise<string>, tocId).then((parsed) => {
        if (parsed.scheme) {
          const schemeWithFixedZIndexed = setZIndexes(parsed.scheme, null)
          setScheme(schemeWithFixedZIndexed)
          setSchemeInfo(parsed.schemeInfo)
        }
      })
    }
  }, [userId, fetchToco, tocId, parseLoadedToco])
  const onLoadCb = useCallback(() => {
    requestAnimationFrame(() =>
      requestAnimationFrame(() => {
        window.print()
      }),
    )
  }, [])
  return (
    <div style={wrapperStyle}>
      <Spinner show={loading} />
      {schemeInfo && (
        <Helmet>
          <title>
            {schemeInfo.title} {moment().format('MM/DD/YYYY')}
          </title>
        </Helmet>
      )}
      {scheme && (
        <FlowConfigurationProvider>
          <Flow
            isFrozen={true}
            scheme={scheme as any}
            updateScheme={() => {}}
            schemeInfo={schemeInfo}
            onLoadCb={onLoadCb}
          />
        </FlowConfigurationProvider>
      )}
    </div>
  )
}

const wrapperStyle = { height: '100vh', width: '100vw' }
