import { useCallback, useMemo } from 'react'
import { OutcomeContent } from '../../../../dto'
import { OpenEditorStates, useEditorContext } from '../../../editor/context/editor-context-provider'

export function useOutcomeNodeConfig(data: OutcomeContent, id: string) {
  const style = useMemo(
    () => ({
      background: data.color,
    }),
    [data.color],
  )

  const { setActiveElementId, openEditor } = useEditorContext()

  const setNodeId = useCallback(() => {
    setActiveElementId(id)
  }, [id, setActiveElementId])

  const openOutcomeEditor = useCallback(() => {
    openEditor(OpenEditorStates.outcomeEditor)
  }, [openEditor])

  return useMemo(() => ({ style, openOutcomeEditor, setNodeId }), [style, openOutcomeEditor, setNodeId])
}
