import React, { memo, useCallback } from 'react'
import { EdgeElement } from '../../../../../diagram/scheme/interfaces'
import { ElementUtils } from '../../../../../diagram/utils/hooks/use-element-utils'
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab'
import { LineStyle } from '../../../../../../dto'
import styles from './index.module.scss'
import { useToggleBtnProps } from '../toggle-btn-props'

const SOLID_STYLE: LineStyle = 'solid'
const DASHED_STYLE: LineStyle = 'dashed'
const DOTTED_STYLE: LineStyle = 'dotted'

type Props = Pick<ElementUtils, 'updateElement'> & {
  edge?: EdgeElement
}
export const LineStyleSwitcher = memo(({ edge, updateElement }: Props) => {
  const handleAlignment = useCallback(
    (_: any, newLineStyle: LineStyle) => {
      if (newLineStyle) {
        updateElement<EdgeElement>(edge!.id, (prevState) => ({
          ...prevState,
          data: { ...prevState.data, lineStyle: newLineStyle },
        }))
      }
    },
    [edge, updateElement],
  )
  const edgeProps = useToggleBtnProps(edge === undefined)
  return (
    <ToggleButtonGroup value={edge?.data.lineStyle || null} exclusive onChange={handleAlignment} size="small">
      <ToggleButton {...edgeProps} value={SOLID_STYLE}>
        <svg className={styles.icon} viewBox="0 0 24 24">
          <path strokeWidth={4} d="M0 12L24 12" />
        </svg>
      </ToggleButton>
      <ToggleButton {...edgeProps} value={DASHED_STYLE}>
        <svg className={styles.icon} focusable="false" viewBox="0 0 24 24" aria-hidden="true">
          <path strokeWidth={4} d="M0 12L10 12M14 12L24 12" />
        </svg>
      </ToggleButton>
      <ToggleButton {...edgeProps} value={DOTTED_STYLE}>
        <svg className={styles.icon} focusable="false" viewBox="0 0 25 24" aria-hidden="true">
          <path strokeWidth={3} d="M2 12l3 0m3 0l3 0m3 0l3 0m3 0l3 0" />
        </svg>
      </ToggleButton>
    </ToggleButtonGroup>
  )
})
