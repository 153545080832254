import { Scheme } from '../scheme/interfaces'
import { FlowElement, isNode } from 'react-flow-renderer'

// base core zIndex for node is 3
// base core zIndex for selected node is 10
const MAX_Z_INDEX = 3000 // max shape side ( = Math.sqrt(3000 * 3000) = 3000)

// The larger the area of the shape, the smaller its index
function calcZIndex(width: number, height: number) {
  return MAX_Z_INDEX - Math.ceil(Math.sqrt(width * height))
}

// Place the container under the figure.
export function setZIndexes(elements: Scheme, selected: null | { id: string }[]) {
  return elements.map((el) => {
    if (isNode(el as FlowElement)) {
      const isSelected = selected ? selected.some((s) => s.id === el.id) : false
      const shapeZIndex = calcZIndex((el.style?.width as number) || 1, (el.style?.height as number) || 1)
      const newZIndex = isSelected ? shapeZIndex + MAX_Z_INDEX : shapeZIndex

      if (el.style?.zIndex === newZIndex) return el

      return {
        ...el,
        style: {
          ...el.style,
          zIndex: newZIndex,
        },
      }
    }
    return el
  })
}
