import { Orientation } from '../../../../../../dto'
import { getDateTime } from '../utils/date'
import { defaultSize } from '../utils/orientation'
import { encodeHtml } from '../utils/decode-html'

export type CreateNewXmlSchemeArg = {
  title: string
  orientation: Orientation
  outcome: string
}

export function createNewXmlScheme(arg: CreateNewXmlSchemeArg) {
  return `
<Toc>
  <TocId>-1</TocId>
  <Title>${arg.title}</Title>
  <UpdateTimestamp>${getDateTime()}</UpdateTimestamp>
  <Shared>0</Shared>
  <IsSavedByUser>1</IsSavedByUser>
  <TocWidth>${defaultSize[arg.orientation].width}</TocWidth>
  <TocHeight>${defaultSize[arg.orientation].height}</TocHeight>
  <BackgroundPattern>noPattern</BackgroundPattern>
  <Content>
    <Narrative/>
    <UnderlyingAssumptions/>
    <ColorLegend>
      <Visible>0</Visible>
      <x>10</x>
      <y>10</y>
      <LegendItems/>
    </ColorLegend>
    <CanvasTitle>
      <Visible>0</Visible>
      <x>10</x>
      <y>10</y>
      <Width>300</Width>
      <Height>200</Height>
      <Text/>
      <FontFamily>__JSON__{"edgesInfo":{"handlers":[{"source":"11111","target":"111","sourceHandle":"top","targetHandle":"bottom"},{"source":"1111","target":"11","sourceHandle":"top","targetHandle":"bottom"},{"source":"11","target":"1","sourceHandle":"top","targetHandle":"bottom"},{"source":"111","target":"1","sourceHandle":"top","targetHandle":"bottom"}],"curveLines":[]}}</FontFamily>
      <FontColor>0x000000</FontColor>
      <FontSize>25</FontSize>
      <BorderColor>1</BorderColor>
      <BackgroundColor>0xFFFFFF</BackgroundColor>
    </CanvasTitle>
    <ConnectorStyle>basic</ConnectorStyle>
    <CanvasContent>
      <Width>${defaultSize[arg.orientation].width}</Width>
      <Height>${defaultSize[arg.orientation].height}</Height>
      <AccountabilityCeiling>
        <Visible>0</Visible>
        <y>10</y>
        <x>10</x>
      </AccountabilityCeiling>
      <Outcomes>
        <Outcome>
          <OutcomeId>1</OutcomeId>
          <Title>${addBaseFontFormatting(arg.outcome)}</Title>
          <Description />
          <x>1100.5</x>
          <y>157</y>
          <Width>200</Width>
          <Height>150</Height>
          <Color>8636141</Color>
          <outcomeFontWeight>__JSON__{"opacity":1}</outcomeFontWeight>
          <outcomeType />
          <outcomeTextFontChoice>Verdana</outcomeTextFontChoice>
          <outcomeFontColor>0</outcomeFontColor>
          <outcomeFontSize>14</outcomeFontSize>
          <outcomeFontStyle>normal</outcomeFontStyle>
          <linkedResource>undefined</linkedResource>
          <Depth>NaN</Depth>
          <TitleVisibility>1</TitleVisibility>
          <Indicators>
              <Indicator>
                <Status>needsattention</Status>
                <WhatWillChange>Sample Change</WhatWillChange>
                <Who>Sample person</Who>
                <HowMany>4</HowMany>
                <HowMuch>sample text</HowMuch>
                <ByWhen>sample date</ByWhen>
              </Indicator>
          </Indicators>
        </Outcome>
        <Outcome>
          <OutcomeId>11</OutcomeId>
          <Title>&lt;p&gt;This is a sample outcome and TOC diagram sample. It is not necessary to use this diagram structure for your TOC. &lt;/p&gt;</Title>
          <Description />
          <x>793.7826086956522</x>
          <y>425.2608695652175</y>
          <Width>200</Width>
          <Height>150</Height>
          <Color>16312092</Color>
          <outcomeFontWeight>__JSON__{"opacity":1}</outcomeFontWeight>
          <outcomeType />
          <outcomeTextFontChoice>Verdana</outcomeTextFontChoice>
          <outcomeFontColor>0</outcomeFontColor>
          <outcomeFontSize>14</outcomeFontSize>
          <outcomeFontStyle>normal</outcomeFontStyle>
          <linkedResource />
          <Depth>NaN</Depth>
          <TitleVisibility>1</TitleVisibility>
          <Indicators />
        </Outcome>
        <Outcome>
          <OutcomeId>111</OutcomeId>
          <Title>&lt;p&gt;Outcome&lt;/p&gt;</Title>
          <Description />
          <x>1332.7826086956522</x>
          <y>425.2608695652175</y>
          <Width>200</Width>
          <Height>150</Height>
          <Color>16312092</Color>
          <outcomeFontWeight>__JSON__{"opacity":1}</outcomeFontWeight>
          <outcomeType />
          <outcomeTextFontChoice>Verdana</outcomeTextFontChoice>
          <outcomeFontColor>0</outcomeFontColor>
          <outcomeFontSize>14</outcomeFontSize>
          <outcomeFontStyle>normal</outcomeFontStyle>
          <linkedResource />
          <Depth>NaN</Depth>
          <TitleVisibility>1</TitleVisibility>
          <Indicators />
        </Outcome>
        <Outcome>
          <OutcomeId>1111</OutcomeId>
          <Title>&lt;p&gt;Outcome&lt;/p&gt;</Title>
          <Description />
          <x>793.7826086956522</x>
          <y>702.2608695652175</y>
          <Width>200</Width>
          <Height>150</Height>
          <Color>5301186</Color>
          <outcomeFontWeight>__JSON__{"opacity":1}</outcomeFontWeight>
          <outcomeType />
          <outcomeTextFontChoice>Verdana</outcomeTextFontChoice>
          <outcomeFontColor>0</outcomeFontColor>
          <outcomeFontSize>14</outcomeFontSize>
          <outcomeFontStyle>normal</outcomeFontStyle>
          <linkedResource />
          <Depth>NaN</Depth>
          <TitleVisibility>1</TitleVisibility>
          <Indicators />
        </Outcome>
        <Outcome>
          <OutcomeId>11111</OutcomeId>
          <Title>&lt;p&gt;Outcome&lt;/p&gt;</Title>
          <Description />
          <x>1518.7826086956522</x>
          <y>740.2608695652175</y>
          <Width>200</Width>
          <Height>150</Height>
          <Color>5301186</Color>
          <outcomeFontWeight>__JSON__{"opacity":1}</outcomeFontWeight>
          <outcomeType />
          <outcomeTextFontChoice>Verdana</outcomeTextFontChoice>
          <outcomeFontColor>0</outcomeFontColor>
          <outcomeFontSize>14</outcomeFontSize>
          <outcomeFontStyle>normal</outcomeFontStyle>
          <linkedResource />
          <Depth>NaN</Depth>
          <TitleVisibility>1</TitleVisibility>
          <Indicators />
        </Outcome>
      </Outcomes>
      <Connectors>
        <Connector>
            <Origin>
              <OutcomeId>11111</OutcomeId>
            </Origin>
            <Destination>
              <OutcomeId>111</OutcomeId>
            </Destination>
            <RationaleInterventionX>NaN</RationaleInterventionX>
            <RationaleInterventionY>NaN</RationaleInterventionY>
            <lineThickness>3</lineThickness>
            <lineStyle>Solid</lineStyle>
            <ConnectorStyle>basic</ConnectorStyle>
            <PivotX>NaN</PivotX>
            <PivotY>NaN</PivotY>
            <lineColor>0</lineColor>
            <Rationale>
              <Text>Sample Rationale</Text>
              <FullText>false</FullText>
            </Rationale>
            <Interventions>
              <Intervention>
                  <Text>Sample Intervention</Text>
                  <FullText>false</FullText>
              </Intervention>
            </Interventions>
        </Connector>
        <Connector>
            <Origin>
              <OutcomeId>1111</OutcomeId>
            </Origin>
            <Destination>
              <OutcomeId>11</OutcomeId>
            </Destination>
            <RationaleInterventionX>NaN</RationaleInterventionX>
            <RationaleInterventionY>NaN</RationaleInterventionY>
            <lineThickness>3</lineThickness>
            <lineStyle>Solid</lineStyle>
            <ConnectorStyle>straight</ConnectorStyle>
            <PivotX>NaN</PivotX>
            <PivotY>NaN</PivotY>
            <lineColor>0</lineColor>
            <Rationale />
            <Interventions />
        </Connector>
        <Connector>
            <Origin>
              <OutcomeId>11</OutcomeId>
            </Origin>
            <Destination>
              <OutcomeId>1</OutcomeId>
            </Destination>
            <RationaleInterventionX>NaN</RationaleInterventionX>
            <RationaleInterventionY>NaN</RationaleInterventionY>
            <lineThickness>3</lineThickness>
            <lineStyle>Solid</lineStyle>
            <ConnectorStyle>basic</ConnectorStyle>
            <PivotX>NaN</PivotX>
            <PivotY>NaN</PivotY>
            <lineColor>0</lineColor>
            <Rationale />
            <Interventions />
        </Connector>
        <Connector>
            <Origin>
              <OutcomeId>111</OutcomeId>
            </Origin>
            <Destination>
              <OutcomeId>1</OutcomeId>
            </Destination>
            <RationaleInterventionX>NaN</RationaleInterventionX>
            <RationaleInterventionY>NaN</RationaleInterventionY>
            <lineThickness>3</lineThickness>
            <lineStyle>Solid</lineStyle>
            <ConnectorStyle>basic</ConnectorStyle>
            <PivotX>NaN</PivotX>
            <PivotY>NaN</PivotY>
            <lineColor>0</lineColor>
            <Rationale />
            <Interventions />
        </Connector>
      </Connectors>
      <Comments/>
      <CommentVisibility>1</CommentVisibility>
      <Annotations/>
      <CurvedLines/>
      <ShowDatagrid>1</ShowDatagrid>
      <ExternalGraphics/>
    </CanvasContent>
  </Content>
</Toc>
`
}

function addBaseFontFormatting(text: string) {
  return encodeHtml(`<FONT FACE="Verdana" SIZE="14" COLOR="#000000" LETTERSPACING="0" KERNING="0">${text}</FONT>`)
}
