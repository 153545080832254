import React, { createContext, PropsWithChildren, ReactNode, useCallback, useContext, useMemo, useState } from 'react'

type ContextType = ReturnType<typeof useProvideValue>
const Context = createContext<ContextType>({} as ContextType)

export function ModalContextProvider({ children }: PropsWithChildren<{}>) {
  const value = useProvideValue()
  return <Context.Provider value={value}>{children}</Context.Provider>
}

export function useModalContext() {
  return useContext(Context)
}

function useProvideValue() {
  const [modalContent, setModalContent] = useState<ReactNode>()
  const showModal = useCallback((content: ReactNode) => setModalContent(content), [])
  const hideModal = useCallback(() => {
    setModalContent(undefined)
  }, [setModalContent])
  return useMemo(
    () => ({
      showModal,
      hideModal,
      modalContent,
    }),
    [showModal, hideModal, modalContent],
  )
}
