import { useCallback } from 'react'
import { OutcomeNodeElement } from '../../../diagram/scheme/interfaces'
import { useEditorContext } from '../../context/editor-context-provider'

export function useUpdateActiveNodeData() {
  const { activeElement, updateElement } = useEditorContext()
  return useCallback(
    (updater: (prevData: OutcomeNodeElement['data']) => Partial<OutcomeNodeElement['data']>) => {
      updateElement<OutcomeNodeElement>(activeElement?.id!, (prevNode) => ({
        ...prevNode,
        data: {
          ...prevNode.data,
          ...updater(prevNode.data),
        },
      }))
    },
    [updateElement, activeElement?.id],
  )
}
