import { useCallback, useEffect, useRef } from 'react'
import {
  DASHBOARD_TAB_ID,
  useDashboardPageContext,
} from '../../../pages/layout/pages/dashboard/context/use-dashboard-page-context'
import { usePageVisibility } from './page-visibility'
import { useSaveDiagram, useSavingSnackbar } from './use-save-diagram'

const MINUTE_MS = 60 * 1000 // 1min.

export function useAutoSave() {
  const { activeTab, autoSaveError, setAutoSaveError } = useDashboardPageContext()
  const { saveDiagram } = useSaveDiagram()
  const savingSnackbar = useSavingSnackbar()
  const isVisible = usePageVisibility()
  const intervalIdRef = useRef<any>()

  const autoSaveHandler = useCallback(
    (promise: Promise<any>) =>
      promise
        .then(() => {
          savingSnackbar.notifySavingFulfilled()
        })
        .catch(() => {
          savingSnackbar.notifySavingRejectedAutoSave()
          clearInterval(intervalIdRef.current)
          setAutoSaveError(true)
        }),
    [savingSnackbar, setAutoSaveError],
  )

  useEffect(() => {
    if (activeTab?.id && activeTab!.id !== DASHBOARD_TAB_ID && !autoSaveError && isVisible) {
      intervalIdRef.current = setInterval(() => {
        saveDiagram(autoSaveHandler)
      }, MINUTE_MS)
    }

    return () => clearInterval(intervalIdRef.current)
  }, [activeTab, autoSaveError, autoSaveHandler, isVisible, saveDiagram, savingSnackbar, setAutoSaveError])

  useEffect(() => {
    if (!isVisible) {
      clearInterval(intervalIdRef.current)
    }
  }, [isVisible])
}
