import { directoryApiClient } from './_client'
import { stringify } from 'querystring'
import { parse } from 'fast-xml-parser'

export type LoginRequestI = {
  username: string
  password: string
}

export type LoginResponseI = {
  token: string
  userId: string
  lastLogIn: string
  firstName: string
  userRole: UserRoles
  subscriptionId: 'free' | string
}

export function fetchLogIn({ username, password }: LoginRequestI) {
  const token = btoa(`${username}:${password}`)
  return directoryApiClient
    .get<string>(
      `api/v1/users/${username}?${stringify({
        username,
        password,
        isLoginRequest: true,
        requestingApplication: 'toco',
      })}`,
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          AUTHORIZATION: `Basic ${token}`,
        },
      },
    )
    .then(({ data }) => {
      try {
        let parsedData: AuthXmlDto.Root = parse(data)
        const user = parsedData?.User
        if (user) {
          const mappedResponse: LoginResponseI = {
            token,
            userId: user.UserId,
            lastLogIn: user.ApplicationAccess.Application.LastLogin,
            firstName: user.FirstName,
            userRole: user.UserRole,
            subscriptionId: user.SubscriptionId,
          }
          return mappedResponse
        }
      } catch (error) {
        console.warn(error.message)
      }
      throw new Error('fetchLogIn parsing error')
    })
}

declare module AuthXmlDto {
  export interface Root {
    User: User
  }

  interface Organization {
    OrganizationId: string
    Name: string
    OtherUsers: string
  }

  interface Organizations {
    Organization: Organization
  }

  interface Sector {
    Sector: string
  }

  interface Sectors {
    Sector: Sector
  }

  interface Right {
    RightId: string
    Application: string
    Resource: string
    Segment: string
    Action: string
    Access: string
  }

  interface Rights {
    Right: Right[]
  }

  interface Role {
    RoleId: string
    PermissionLevel: number
    Rights: Rights
  }

  interface Roles {
    Role: Role
  }

  interface Subscription {
    Type: string
    Detail: string
    amount: number
    subId: string
    paymentId: number
    paymentType: string
    transactionId: number
    transactionStatus: string
    SubscriptionDate: string
  }

  interface Subscriptions {
    Subscription: Subscription
  }

  interface UserStatus {
    UserStatus: string
    IndividualActivationStatus: string
  }

  interface UserStatuss {
    UserStatus: UserStatus
  }

  interface Application {
    ApplicationId: string
    LastLogin: string
    Frequency: number
  }

  interface ApplicationAccess {
    Application: Application
  }

  interface User {
    UserId: string
    Password: string
    FirstName: string
    LastName: string
    Email: string
    City: string
    State: string
    Country: string
    ApplicationData: string
    SubscriptionId: string
    subscriptionExpiry: string
    duration: number
    noOfUsers: number
    availUsers: number
    Created: string
    Active: number
    SubscriptionExpiredEmail: number
    subscriptionNearEndEmail: number
    SubscriptionFront: number
    ValidateAccessKey: number
    RemainingToc: number
    Organizations: Organizations
    Sectors: Sectors
    Roles: Roles
    UserRole: UserRoles
    PermissionLevel: number
    Subscriptions: Subscriptions
    UserGroupStatuss: string
    UserStatuss: UserStatuss
    ApplicationAccess: ApplicationAccess
  }
}

export enum UserRoles {
  manager = 'MANAGER',
  admin = 'ADMIN',
  actknowledgeUser = 'ACTKNOWLEDGE_USER',
  tocoOrgatizationTocEditor = 'TOCO_ORGANIZATION_TOC_EDITOR',
  tocoTocEditor = 'TOCO_TOC_EDITOR',
  tocoReviewer = 'TOCO_REVIEWER',
  aplicationAsUser = 'APLICATION_AS_USER',
  tocoOrgatizationTocViewer = 'TOCO_ORGANIZATION_TOC_VIEWER',
  tocoOrgatizationTocAdmin = 'TOCO_ORGANIZATION_TOC_ADMIN',
}
