import React, { memo, useCallback } from 'react'
import { Button, Divider } from '@material-ui/core'
import RedoIcon from '@material-ui/icons/Redo'
import UndoIcon from '@material-ui/icons/Undo'
import SaveIcon from '@material-ui/icons/Save'
import PrintIcon from '@material-ui/icons/Print'
import { useSaveDiagram } from '../hooks/use-save-diagram'
import { useDashboardPageContext } from '../../../pages/layout/pages/dashboard/context/use-dashboard-page-context'

export const IconMenu = memo(() => {
  const { saveDiagram, savingDisabled } = useSaveDiagram()
  const { schemeInfo } = useDashboardPageContext()
  const print = useCallback(() => window.open(`print/${schemeInfo?.id}`, '_blank'), [schemeInfo?.id])
  const save = useCallback(() => saveDiagram(), [saveDiagram])
  return (
    <>
      <Button disabled color="primary">
        <UndoIcon fontSize="default" />
      </Button>
      <Button disabled color="primary">
        <RedoIcon fontSize="default" />
      </Button>
      <Divider orientation="vertical" flexItem />
      <Button disabled={savingDisabled} color="primary" onClick={save}>
        <SaveIcon fontSize="default" />
      </Button>
      <Button disabled={!schemeInfo} color="primary" onClick={print}>
        <PrintIcon fontSize="default" />
      </Button>
    </>
  )
})
