import { Tabs } from '@material-ui/core'
import React, { memo } from 'react'
import Tab from '@material-ui/core/Tab/Tab'
import { NarrativeTab } from './narrative-tab'
import { UnderlyingAssumptionsTab } from './underlying-assumptions-tab'
import { useTabsHandler } from '../../../../hooks/use-tabs-handler'

export const NarrativeTabs = memo(() => {
  const { activeTabIndex, handleTabChange } = useTabsHandler()
  return (
    <>
      <Tabs value={activeTabIndex} onChange={handleTabChange} indicatorColor="primary" textColor="primary">
        <Tab label="Narrative" />
        <Tab label="Underlying Assumptions" />
      </Tabs>
      {activeTabIndex === 0 && <NarrativeTab />}
      {activeTabIndex === 1 && <UnderlyingAssumptionsTab />}
    </>
  )
})
