import { Box } from '@material-ui/core'
import React from 'react'
import styles from './footer.module.scss'
import clsx from 'clsx'

export const Footer = () => {
  return (
    <Box component="footer" className={clsx(styles.footer, 'no-print')}>
      <p>&copy; {new Date().getFullYear()} All Rights Reserved</p>
    </Box>
  )
}
