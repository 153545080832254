import { Box, TextField } from '@material-ui/core'
import React, { ChangeEvent, memo, useCallback } from 'react'
import { ElementList } from '../../../../element-list'
import { useEditorContext } from '../../../context/editor-context-provider'

export const UnderlyingAssumptionsTab = memo(() => {
  const { schemeInfo, updateSchemeInfo } = useEditorContext()
  const assumptionsElements = schemeInfo?.underlyingAssumptions || []

  const addElement = useCallback(() => {
    updateSchemeInfo((prevScheme) => {
      const newElements = [...prevScheme.underlyingAssumptions, '']
      return { ...prevScheme, underlyingAssumptions: newElements }
    })
  }, [updateSchemeInfo])

  const removeElement = useCallback(
    (activeIndex: number) => {
      updateSchemeInfo((prevScheme) => {
        const newElements = prevScheme.underlyingAssumptions.filter((_, index) => index !== activeIndex)
        return { ...prevScheme, underlyingAssumptions: newElements }
      })
    },
    [updateSchemeInfo],
  )

  const renderContent = useCallback((activeIndex: number) => <Content activeIndex={activeIndex} />, [])

  return (
    <Box px={1} py={1} className="h-100 bg-white">
      <ElementList
        renderContent={renderContent}
        onRemoveElement={removeElement}
        onAddElement={addElement}
        elements={assumptionsElements}
      />
    </Box>
  )
})

type ContentProps = {
  activeIndex: number
}

const Content = ({ activeIndex }: ContentProps) => {
  const { schemeInfo, updateSchemeInfo } = useEditorContext()
  const updateElement = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      updateSchemeInfo((prevScheme) => {
        const nextElements: string[] = prevScheme.underlyingAssumptions.map((item, index) =>
          activeIndex === index ? e.target.value : item,
        )
        return { ...prevScheme, underlyingAssumptions: nextElements }
      })
    },
    [activeIndex, updateSchemeInfo],
  )
  return (
    <Box mt={6}>
      <TextField
        className="w-100"
        multiline
        onChange={updateElement}
        value={schemeInfo?.underlyingAssumptions[activeIndex]}
        rows={4}
        variant="outlined"
      />
    </Box>
  )
}
