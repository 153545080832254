import { IconButton } from '@material-ui/core'
import React from 'react'
import { useEditorContext } from '../../../../context/editor-context-provider'
import { OutcomeNodeElement } from '../../../../../diagram/scheme/interfaces'
import styles from '../../../../../toolbar/tools-box/shapes/index.module.scss'
import { OutcomeShapePreview, outcomeShapePreviewOrder } from '../../../../../outcome-shape-preview'

export const UpdateTypeShape = () => {
  const { activeElementId, updateElement } = useEditorContext()
  const createChangeTypeHandler = (newType: OutcomeNodeElement['type']) => () => {
    updateElement<OutcomeNodeElement>(activeElementId!, (el) => ({ ...el, type: newType }))
  }

  return (
    <>
      {outcomeShapePreviewOrder.map((type) => (
        <IconButton
          key={type}
          onClick={createChangeTypeHandler(type)}
          color={'primary'}
          className={styles.shapes}
          size={'small'}
        >
          <OutcomeShapePreview type={type} />
        </IconButton>
      ))}
    </>
  )
}
