import React, { memo, useCallback } from 'react'
import { NodeProps } from 'react-flow-renderer'
import { NodeElementWithType } from '../../scheme/interfaces'
import { NodeSchemeType } from '../../scheme/enums'
import styles from './styles.module.scss'
import { useEditorContext } from '../../../editor/context/editor-context-provider'

type AccountabilityCeilingData = { isHorizontal: boolean }

export type AccountabilityCeilingNodeElement = NodeElementWithType<
  NodeSchemeType.accountabilityCeiling,
  AccountabilityCeilingData
>

export const AccountabilityCeilingNode = memo(
  ({ id, data: { isHorizontal } }: NodeProps<AccountabilityCeilingData>) => {
    const { setActiveElementId } = useEditorContext()
    const setActive = useCallback(() => setActiveElementId(id), [id, setActiveElementId])
    return <div className={isHorizontal ? styles.horizontal : styles.vertical} onClick={setActive} />
  },
)
