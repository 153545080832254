import { Box, Divider, IconButton, InputAdornment, TextField } from '@material-ui/core'
import React, { memo, useCallback, useMemo } from 'react'
import { EdgeTypeSwitcher } from '../../editor/connector-editor/connector-tabs/rationale-tab/edge-type-switcher'
import { LineStyleSwitcher } from '../../editor/connector-editor/connector-tabs/rationale-tab/line-style-switcher'
import { useEditorContext } from '../../editor/context/editor-context-provider'
import { ShapesAndPicker } from './shapes'
import DeleteIcon from '@material-ui/icons/Delete'
import styles from './tools.module.scss'
import { useDashboardPageContext } from '../../../pages/layout/pages/dashboard/context/use-dashboard-page-context'
import { removeElements } from 'react-flow-renderer'
import { EdgeElement } from '../../diagram/scheme/interfaces'
import { isEdgeElement } from '../../diagram/utils/check-node-type'
import { EdgeColors } from './edge-color'

export const ToolsBox = memo(() => {
  const { updateElement, activeElement } = useEditorContext()
  const { updateScheme } = useDashboardPageContext()
  const onElementsRemove = useCallback(() => {
    if (activeElement) {
      updateScheme((els) => removeElements([activeElement] as any, els) as any)
    }
  }, [activeElement, updateScheme])

  const edge = useMemo(() => (isEdgeElement(activeElement) ? (activeElement as EdgeElement) : undefined), [
    activeElement,
  ])
  return (
    <Box className={styles.tools}>
      <Box component="span" fontSize="small" pl={2} mr={1}>
        OUTCOME SHAPES:
      </Box>
      <ShapesAndPicker />
      <Divider orientation="vertical" flexItem />
      <Box
        component="span"
        fontSize="small"
        pl={2}
        style={{ color: edge === undefined ? 'rgba(0, 0, 0, 0.26)' : 'inherit' }}
      >
        CONNECTORS:
      </Box>
      <Box mx={1}>
        <EdgeTypeSwitcher edge={edge} updateElement={updateElement} />
      </Box>
      <Box mx={1}>
        <LineStyleSwitcher edge={edge} updateElement={updateElement} />
      </Box>
      <Box mx={1}>
        <EdgeColors edge={edge} updateElement={updateElement} />
      </Box>
      <Box ml={1} mr={2}>
        <TextField
          disabled={!edge}
          style={{ width: '100px' }}
          type="number"
          inputProps={{
            min: 1,
            max: 12,
          }}
          value={edge?.data.width || '3'}
          variant="outlined"
          onChange={(event) =>
            updateElement<EdgeElement>(edge!.id, (el) => ({
              ...el,
              data: { ...el.data, width: parseInt(event.target.value) },
            }))
          }
          InputProps={{
            endAdornment: <InputAdornment position="start">px</InputAdornment>,
          }}
        />
      </Box>
      <IconButton aria-label="delete" onClick={onElementsRemove} disabled={!activeElement} className={styles.delete}>
        <DeleteIcon fontSize="small" />
      </IconButton>
    </Box>
  )
})
