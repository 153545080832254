import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { DashboardPage } from './pages/dashboard'
import { Header } from './header'
import { Footer } from './footer'
import clsx from 'clsx'
import styles from './layout.module.scss'
import { Box } from '@material-ui/core'
import { LayoutContextProvider } from './context/use-layout-context'
import { ModalWrapper } from '../../components/modal/wrapper'

export const Layout = () => {
  return (
    <LayoutContextProvider>
      <ModalWrapper>
        <Box className={clsx(styles.layout, 'fullWidth')}>
          <Header />
          <div className={styles.main}>
            <Switch>
              <Route exact path="/" component={DashboardPage} />
            </Switch>
          </div>
          <Footer />
        </Box>
      </ModalWrapper>
    </LayoutContextProvider>
  )
}
