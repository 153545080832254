import { Box, Typography } from '@material-ui/core'
import React, { memo, useCallback, useMemo } from 'react'
import { ColorPicker } from '../../../../diagram/indicators-bar/color-picker'
import { TextBoxNodeElement } from '../../../../diagram/shapes/text-box'
import { useVisible } from '../../../../diagram/utils/hooks/use-visible'
import { useEditorContext } from '../../../context/editor-context-provider'
import { QuillEditor } from '../../../components/react-quill'
import styles from './index.module.scss'

export const TextBoxTab = memo(() => {
  const { activeElement, updateElement } = useEditorContext()
  const textBox = activeElement as TextBoxNodeElement
  const selectedColorStyle = useMemo(() => ({ backgroundColor: textBox.data.color }), [textBox.data.color])
  const { ref, isVisible, toggleVisible } = useVisible()
  const onChangeText = useCallback(
    (newText) => {
      updateElement<TextBoxNodeElement>(textBox.id!, (el) => ({ ...el, data: { ...el.data, text: newText } }))
    },
    [textBox, updateElement],
  )

  return (
    <Box p={1} className={styles.wrapper}>
      <QuillEditor value={textBox.data.text || ''} onChange={onChangeText} />
      <Box mb={2} display="flex">
        <Box mr={2}>
          <Typography component="span">Color:</Typography>
          <Box className={styles.colorPicker} mt={1}>
            <Box
              className={styles.selectedColor}
              onClick={() => toggleVisible(!isVisible)}
              style={selectedColorStyle}
              mr={2}
            />
          </Box>
          {isVisible && textBox.id && (
            <div ref={ref}>
              <ColorPicker
                updateElement={updateElement}
                nodeId={textBox.id}
                shapeColor={textBox.data.color!}
                pickerWrapperClassName={styles.pickerWrapper}
              />
            </div>
          )}
        </Box>
      </Box>
    </Box>
  )
})
