import React, { memo, useMemo } from 'react'
import { Box } from '@material-ui/core'
import clsx from 'clsx'
import './indicators-bar.module.scss'
import styles from './indicators-bar.module.scss'
import { Info } from './info'
import { useToggle } from 'react-use'
import { IndicatorBox } from '../indicator-box'
import { Indicator } from '../../../dto'
import { ColorPicker } from './color-picker'
import { useEditorContext } from '../../editor/context/editor-context-provider'
import { useVisible } from '../utils/hooks/use-visible'

type Props = {
  nodeId: string
  positionClassName?: string
  indicators?: Indicator[]
  shapeColor: string
  positionPickerClassName?: string
}

export const IndicatorsBar = memo(
  ({ nodeId, positionClassName, indicators, shapeColor, positionPickerClassName }: Props) => {
    const [isOpenIndicator, toggleOpenIndicator] = useToggle(false)
    const selectedColorStyle = useMemo(() => ({ backgroundColor: shapeColor }), [shapeColor])
    const { updateElement } = useEditorContext()
    const { ref, isVisible, toggleVisible } = useVisible()

    return (
      <Box className="indicators">
        <Box className={clsx(positionClassName, 'indicatorsBar')}>
          <Box className="colorPicker">
            <Box className="selectedColor" onClick={() => toggleVisible(!isVisible)} style={selectedColorStyle} />
          </Box>
          <Info toggleIndicator={toggleOpenIndicator} />
        </Box>
        {isVisible && (
          <div ref={ref}>
            <ColorPicker
              updateElement={updateElement}
              nodeId={nodeId}
              shapeColor={shapeColor}
              pickerWrapperClassName={clsx(styles.pickerWrapper, positionPickerClassName)}
            />
          </div>
        )}
        {isOpenIndicator && <IndicatorBox indicators={indicators} />}
      </Box>
    )
  },
)
