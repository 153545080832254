import React, { memo } from 'react'
import { OutcomeNodeElementWithType, OutcomeNodeProps } from '../../../scheme/interfaces'
import { NodeSchemeType } from '../../../scheme/enums'
import styles from './index.module.scss'
import { BaseShape } from '../base-shape'

export type EllipseElement = OutcomeNodeElementWithType<NodeSchemeType.ellipse>

export const EllipseNode = memo((outcomeProps: OutcomeNodeProps) => {
  return <BaseShape themeClassNames={styles as any} {...outcomeProps} />
})
