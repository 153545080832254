import React from 'react'
import { LeftSide } from './left-side'
import styles from './dashboard.module.scss'
import { Diagram } from './diagram'
import { FlowConfigurationProvider } from '../../../../components/diagram'
import { DashboardPageContextProvider, useDashboardPageContext } from './context/use-dashboard-page-context'
import { EditorContextProvider } from '../../../../components/editor/context/editor-context-provider'
import { DiagramTabs } from './tabs'
import { Editors } from '../../../../components/editor'
import { ToolBar } from '../../../../components/toolbar'

export function DashboardPage() {
  return (
    <FlowConfigurationProvider>
      <DashboardPageContextProvider>
        <DashboardPageContent />
      </DashboardPageContextProvider>
    </FlowConfigurationProvider>
  )
}

function DashboardPageContent() {
  const { updateElement, findElement, schemeInfo, updateSchemeInfo, scheme } = useDashboardPageContext()
  return (
    <EditorContextProvider
      updateElement={updateElement}
      findElement={findElement}
      schemeInfo={schemeInfo}
      updateSchemeInfo={updateSchemeInfo}
      scheme={scheme}
    >
      <main className={styles.main}>
        <ToolBar />
        <div className={styles.wrapper}>
          <LeftSide />
          <div className={styles.content}>
            <div className={styles.editorWrapper}>
              <Editors />
            </div>
            <div className={styles.column}>
              <DiagramTabs />
              <div className={styles.diagram}>
                <Diagram />
              </div>
            </div>
          </div>
        </div>
      </main>
    </EditorContextProvider>
  )
}
