import { Tabs } from '@material-ui/core'
import React, { memo } from 'react'
import Tab from '@material-ui/core/Tab/Tab'
import { useTabsHandler } from '../../../../hooks/use-tabs-handler'
import { TabContent } from '../../components/layout/tab-content'
import { TextBoxTab } from './text-box-tab'

export const TextBoxTabs = memo(() => {
  const { activeTabIndex, handleTabChange } = useTabsHandler()
  return (
    <>
      <Tabs value={activeTabIndex} onChange={handleTabChange} indicatorColor="primary" textColor="primary">
        <Tab label="Text Box" />
      </Tabs>
      <TabContent>{activeTabIndex === 0 && <TextBoxTab />}</TabContent>
    </>
  )
})
