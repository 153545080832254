import { useCallback, useState } from 'react'
import { useApiErrorHandler } from './use-api-error-handler'

export function useRemoteData<T, A extends any[]>(
  fn: (...args: A) => Promise<T>,
  initialState: T,
  shouldNotifyCommonError: boolean = true,
  deps: any[] = [],
) {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState()
  const [state, setState] = useState<T>(initialState)
  const apiCommonErrorHandler = useApiErrorHandler()

  const callback = useCallback((...args: A) => {
    const promise = fn(...args)
    setLoading(true)
    return promise
      .then((res) => {
        setState(res)
        return res
      })
      .catch((err) => {
        if (shouldNotifyCommonError) {
          apiCommonErrorHandler(promise)
        }
        setError(err)
      })
      .finally(() => setLoading(false))
    // eslint-disable-next-line
  }, deps)

  return [callback, state, loading, error] as const
}

export function useRemoteDataCallback<T, A extends any[]>(
  fn: (...args: A) => Promise<T>,
  shouldNotifyError: boolean = true,
  deps: any[] = [],
) {
  const [loading, setLoading] = useState(false)
  const apiErrorHandler = useApiErrorHandler()

  const callback = useCallback((...args: A) => {
    const promise = fn(...args)
    setLoading(true)
    if (shouldNotifyError) {
      apiErrorHandler(promise)
    }
    return promise.finally(() => setLoading(false))
    // eslint-disable-next-line
  }, deps)

  return [callback, loading] as const
}
