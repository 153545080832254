import React, { memo, useMemo } from 'react'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { useDashboardPageContext } from '../context/use-dashboard-page-context'
import styles from './index.module.scss'
import CloseIcon from '@material-ui/icons/Close'
import clsx from 'clsx'

const DEFAULT_TAB = 0

export const DiagramTabs = memo(() => {
  const { tabs, selectTabById, activeTab, closeTab } = useDashboardPageContext()
  const activeTabIndex = useMemo(() => {
    const index = tabs.findIndex((tab) => activeTab?.id === tab?.id)
    const isTabNotExist = index === -1
    return isTabNotExist ? DEFAULT_TAB : index
  }, [tabs, activeTab])
  const tabsElements = useMemo(
    () =>
      tabs.map((tab) => (
        <Tab
          className={styles.tab}
          key={tab.id}
          label={
            <span className={styles.label}>
              <span className={styles.title}>{tab.title}</span>
              <CloseIcon
                className={styles.icon}
                fontSize={'small'}
                onClick={(e) => {
                  e.stopPropagation()
                  if (!tab.isSchemeLoading) {
                    closeTab(tab.id)
                  }
                }}
              />
            </span>
          }
          onClick={() => {
            selectTabById(tab.id)
          }}
        />
      )),
    [tabs, closeTab, selectTabById],
  )
  return (
    <AppBar position="static" color="default" className={clsx(styles.tabPanel, 'no-print')}>
      <Tabs
        value={activeTabIndex}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="auto"
      >
        {tabsElements}
      </Tabs>
    </AppBar>
  )
})
