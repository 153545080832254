import React, { memo, useCallback } from 'react'
import { useEditorContext } from '../../../context/editor-context-provider'
import { EdgeElement } from '../../../../diagram/scheme/interfaces'
import { Rationale } from '../../../../../dto'
import { useUpdateActiveEdgeData } from '../../../components/hooks/use-update-active-edge-data'
import { EdgeTypeSwitcher } from './edge-type-switcher'
import { LineStyleSwitcher } from './line-style-switcher'
import { EdgeDirectionSwitcher } from './edge-direction-switcher'
import styles from './index.module.scss'
import { Box, Checkbox, FormControl, FormControlLabel, FormGroup } from '@material-ui/core'
import { QuillEditor } from '../../../components/react-quill'

const IS_FULL_TEXT_BY_DEFAULT = false

export const RationaleTab = memo(() => {
  const { activeElement, updateElement } = useEditorContext()
  const edge = activeElement as EdgeElement
  const updateActiveEdgeData = useUpdateActiveEdgeData()

  const updateRationale = useCallback(
    (updater: (prevRationale?: Rationale) => Rationale) => {
      updateActiveEdgeData((prevData) => ({
        rationale: updater(prevData.rationale),
      }))
    },
    [updateActiveEdgeData],
  )
  const toggleVisibility = useCallback(() => {
    updateRationale((prevRationale) => ({
      text: prevRationale?.text || '',
      isFullText: !prevRationale?.isFullText,
    }))
  }, [updateRationale])

  const updateText = useCallback(
    (text) => {
      updateRationale((prevRationale) => ({
        isFullText: prevRationale ? prevRationale?.isFullText : IS_FULL_TEXT_BY_DEFAULT,
        text: text,
      }))
    },
    [updateRationale],
  )

  return (
    <div className={styles.wrapper}>
      <QuillEditor value={edge.data.rationale?.text || ''} onChange={updateText} />
      <Box mb={1}>
        <FormControl component="fieldset">
          <FormGroup aria-label="position" row>
            <FormControlLabel
              value="Show full text"
              control={
                <Checkbox
                  color="primary"
                  checked={edge.data?.rationale ? edge.data.rationale.isFullText : IS_FULL_TEXT_BY_DEFAULT}
                  onChange={toggleVisibility}
                />
              }
              label="Show full text"
              labelPlacement="end"
            />
          </FormGroup>
        </FormControl>
      </Box>
      <Box mb={2}>
        <EdgeTypeSwitcher edge={edge} updateElement={updateElement} />
      </Box>
      <Box mb={2}>
        <LineStyleSwitcher edge={edge} updateElement={updateElement} />
      </Box>
      <Box mb={1}>
        <EdgeDirectionSwitcher />
      </Box>
    </div>
  )
})
