import { useCallback, useMemo } from 'react'
import { ElementUtils } from './use-element-utils'

export function useDraggableDisabler(updateElement: ElementUtils['updateElement'], nodeId: string) {
  const denyDrag = useCallback(() => {
    updateElement(nodeId, (el) => ({
      ...el,
      draggable: false,
    }))
  }, [nodeId, updateElement])

  const allowDrag = useCallback(() => {
    updateElement(nodeId, (el) => ({
      ...el,
      draggable: true, // undefined value will be ignored by library
    }))
  }, [nodeId, updateElement])

  return useMemo(
    () => ({
      denyDrag,
      allowDrag,
    }),
    [denyDrag, allowDrag],
  )
}
