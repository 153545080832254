import Button from '@material-ui/core/Button'
import React, { useCallback } from 'react'
import {
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
} from '@material-ui/core'
import clsx from 'clsx'
import * as Yup from 'yup'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Orientation } from '../../../dto'
import styles from './styles.module.scss'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { useLayoutContext } from '../../../pages/layout/context/use-layout-context'
import { api } from '../../../api'
import { createNewXmlScheme } from '../../../pages/layout/pages/dashboard/scheme-parser/js-to-xml/create-new-xml-scheme'
import { Spinner } from '../../spinner'
import { useModalContext } from '../../modal/context/use-modal-context'
import { useRemoteDataCallback } from '../../../hooks/use-remote-data'

type CreateTocRequest = {
  name: string
  outcome: string
  orientation: Orientation
}

const formValidationSchema = Yup.object().shape<CreateTocRequest>({
  name: Yup.string().required('Required'),
  outcome: Yup.string().required('Required'),
  orientation: Yup.string(),
})

export function CreateTocModal() {
  const { hideModal } = useModalContext()
  const { setCreatedTocoId } = useLayoutContext()
  const { register, handleSubmit, errors, control } = useForm<CreateTocRequest>({
    resolver: yupResolver(formValidationSchema),
  })
  const [createToco, isLoading] = useRemoteDataCallback(api.toco.postCreateToco)

  const onSubmit = useCallback(
    (formData: CreateTocRequest) => {
      createToco({
        xml: createNewXmlScheme({
          orientation: formData.orientation,
          title: formData.name,
          outcome: formData.outcome,
        }),
      }).then((id) => {
        setCreatedTocoId(id)
        hideModal()
      })
    },
    [createToco, hideModal, setCreatedTocoId],
  )

  return (
    <Dialog maxWidth="xs" onClose={hideModal} open={true}>
      <Spinner show={isLoading} overlay={true} />
      <form onSubmit={handleSubmit(onSubmit)} className={styles.form} autoComplete="off">
        <MuiDialogTitle className={styles.titleWrapper}>
          New TOC Wizard
          <IconButton onClick={hideModal} className={styles.closeIcon}>
            <CloseIcon />
          </IconButton>
        </MuiDialogTitle>

        <DialogContent>
          <div className={styles.notice}>
            You're almost ready to start building! We just need to gather a few more pieces of information to get your
            TOC set up.
          </div>

          <TextField
            inputRef={register}
            name="name"
            label="Pick a name for your TOC"
            className={clsx('w-100', styles.nameField)}
            variant="outlined"
            size="small"
            helperText={errors.name?.message}
            error={!!errors.name}
          />

          <FormControl component="fieldset" className={styles.orientationField}>
            <Controller
              name="orientation"
              control={control}
              defaultValue={Orientation.landscape}
              render={({ onChange, value }) => (
                <RadioGroup row={true} onChange={onChange} value={value}>
                  <FormControlLabel
                    value={Orientation.landscape}
                    control={<Radio color="primary" />}
                    label="Landscape"
                  />
                  <FormControlLabel value={Orientation.portrait} control={<Radio color="primary" />} label="Portrait" />
                </RadioGroup>
              )}
            />
          </FormControl>
          <TextField
            inputRef={register}
            className={clsx('w-100', styles.outcomeField)}
            name="outcome"
            label="Enter the title of your long-term outcome"
            variant="outlined"
            size="small"
            helperText={errors.outcome?.message}
            error={!!errors.outcome?.message}
          />
        </DialogContent>

        <DialogActions className={styles.actions}>
          <Button onClick={hideModal} color="primary" variant="outlined" className={styles.btn}>
            Cancel
          </Button>
          <Button type="submit" color="primary" variant="contained" className={styles.btn}>
            Done
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}
