import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { Login } from './login'
import { PrivateRoute } from '../components/routing/private-route'
import { Layout } from './layout'
import { Print } from './print'
import { ModalWrapper } from '../components/modal/wrapper'

export function Pages() {
  return (
    <Switch>
      <Route path="/login" component={Login} />
      <PrivateRoute path="/print/:tocId">
        <ModalWrapper>
          <Print />
        </ModalWrapper>
      </PrivateRoute>
      <PrivateRoute path="/">
        <Layout />
      </PrivateRoute>
    </Switch>
  )
}
