import { Box, Button } from '@material-ui/core'
import React, { memo, useState } from 'react'
import { useCallback } from 'react'
import { SketchPicker } from 'react-color'
import { EDGE_COLOR } from '../../../diagram/constants'
import { PRESET_COLORS } from '../../../diagram/indicators-bar/color-picker/preset-color'
import { EdgeElement } from '../../../diagram/scheme/interfaces'
import { ElementUtils } from '../../../diagram/utils/hooks/use-element-utils'
import { useVisible } from '../../../diagram/utils/hooks/use-visible'
import styles from './index.module.scss'

type Props = Pick<ElementUtils, 'updateElement'> & {
  edge?: EdgeElement
}

export const EdgeColors = memo(({ edge, updateElement }: Props) => {
  const [newColor, setNewColor] = useState(EDGE_COLOR)
  const { ref, isVisible, toggleVisible } = useVisible()

  const onChangeColor = useCallback(
    ({ rgb: { r, g, b, a } }) => {
      const rgba = `rgba(${r},${g},${b},${a})`
      updateElement<EdgeElement>(edge!.id, (prevState) => ({
        ...prevState,
        style: { ...prevState.data, stroke: rgba },
      }))
      setNewColor(rgba)
    },
    [edge, updateElement],
  )

  return (
    <Box className={styles.colorPicker}>
      <Button
        disabled={edge === undefined}
        variant="contained"
        className={styles.colorPickerBtn}
        onClick={() => toggleVisible(!isVisible)}
        style={{ backgroundColor: edge === undefined ? 'rgba(0, 0, 0, 0.26)' : newColor, transition: 'none' }}
      />
      {isVisible && (
        <div className={styles.picker} ref={ref}>
          <SketchPicker color={newColor} onChange={onChangeColor} presetColors={PRESET_COLORS} />
        </div>
      )}
    </Box>
  )
})
