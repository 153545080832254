import { Box, CardMedia, Link, Typography } from '@material-ui/core'
import React from 'react'
import styles from './index.module.scss'
import certification from './images/certification-image.jpg'
import partnerProgram from './images/partner-program-image-1.png'
import { Alert } from '@material-ui/lab'

interface DashboadTabProps {}

export const DashboadTab: React.FC<DashboadTabProps> = () => {
  return (
    <div className={styles.wrapper}>
      <Box mx="auto">
        <Alert severity="info">
          If you have any suggestions or feedback while using TOCO 3.0 Beta or run into any issues, please use the
          following form &nbsp;
          <Link href="https://www.actknowledge.org/toco-3-0-feedback/">
            (https://www.actknowledge.org/toco-3-0-feedback/)
          </Link>
        </Alert>
      </Box>
      <div className={styles.left}>
        <Typography variant="h5" gutterBottom>
          Welcome to TOCO 3.0
        </Typography>

        <Typography variant="body1" gutterBottom>
          Theory of Change Online (TOCO) is the first web-based tool that you can use to draw and edit your theory of
          change, learn the concept of theory of change, and capture your outcomes, indicators, rationales and
          assumptions in an interactive graphical environment.Below you will find a walk-through of TOCO 3.0 and it's
          new features
        </Typography>
        <div className={styles.leftBottom}>
          <CardMedia
            className={styles.youTube}
            component="iframe"
            width="560"
            height="315"
            image="https://www.youtube.com/embed/v6RGfkj2SuE"
            title="ActKnowledgeWebinars"
          />
        </div>
      </div>
      <div className={styles.right}>
        <Link
          className={styles.rigtTop}
          href="https://www.theoryofchange.org/theory-of-change-certification-course/"
          target="_blank"
          variant="body2"
        >
          <img src={certification} alt="Certification" />
        </Link>
        <div className={styles.rigtBottom}>
          <Link
            variant="body1"
            gutterBottom
            className={styles.link}
            href="https://www.theoryofchange.org/become-a-partner-of-the-center-for-theory-of-change/"
            target="_blank"
          >
            Become a Center for Theory of Change Partner
          </Link>
          <img src={partnerProgram} alt="Partner Program" />
          <Typography variant="body2" gutterBottom className={styles.subText}>
            Get access to experts, trainings and more!
          </Typography>
        </div>
      </div>
    </div>
  )
}
