import { ColorLegend, ColorLegendItem } from '../../../../../../components/diagram/scheme/interfaces'
import { toArrayAndMap } from '../utils/to-array-and-map'
import { XmlTocoScheme } from '../interface'
import { convertNumberColorToRgba } from '../utils/color'
import { removeTagsAndDecodeHtml } from '../utils/decode-html'

export function parseColorLegend(xmlTocoScheme: XmlTocoScheme.RootObject): ColorLegend {
  const legend = xmlTocoScheme.Toc.Content.ColorLegend
  return {
    isVisible: !!legend.Visible,
    x: legend.x,
    y: legend.y,
    legendItems: toArrayAndMap(legend.LegendItems.Item, parseColorLegendItem),
  }
}

function parseColorLegendItem(item: XmlTocoScheme.Item): ColorLegendItem {
  return {
    color: convertNumberColorToRgba(item.Hex),
    text: removeTagsAndDecodeHtml(item.Text),
  }
}
