import { EdgeElement, Scheme } from '../../../../../../components/diagram/scheme/interfaces'
import { isEdge } from 'react-flow-renderer'
import { XmlTocoScheme } from '../interface'
import { EdgeSchemeType } from '../../../../../../components/diagram/scheme/enums'
import { encodeHtml } from '../utils/decode-html'
import { XmlWithExtraInfo } from '../utils/extra-params'
import { SchemeExtraParams } from '../utils/extra-params/scheme-extra-params'
import { toArray } from '../utils/to-array-and-map'
import { convertRgbaColorToNumber } from '../utils/color'

export function convertEdgesToXmlAndGetExtraInfo(
  scheme: Scheme,
  xmlTocoScheme: XmlTocoScheme.RootObject,
): XmlWithExtraInfo<SchemeExtraParams['edgesInfo']> {
  const edges = scheme.filter(isEdge) as EdgeElement[]

  const edgesInfo: Required<SchemeExtraParams['edgesInfo']> = {
    handlers: [],
    curveLines: [],
    transparency: 1,
  }
  edges.forEach(({ source, target, sourceHandle, targetHandle, type, style }) => {
    if (sourceHandle && targetHandle) {
      edgesInfo.handlers.push({ source, target, sourceHandle, targetHandle })
    }

    if (type === 'bezier-edge-intervention') {
      edgesInfo.curveLines.push({ source, target })
    }
    edgesInfo.transparency = !style?.stroke ? 1 : convertRgbaColorToNumber(style?.stroke!).transparency
  })

  return {
    paramField: edgesInfo,
    xml: `<Connectors>${edges.map((edge) => convertEdge(edge, xmlTocoScheme)).join('\n')}
      </Connectors>`,
  }
}

function convertEdge(edge: EdgeElement, xmlTocoScheme: XmlTocoScheme.RootObject) {
  const prevConnectorXml = toArray(xmlTocoScheme?.Toc?.Content?.CanvasContent?.Connectors?.Connector).find(
    (prev) =>
      prev.Origin?.OutcomeId?.toString() === edge.source.toString() &&
      prev.Destination?.OutcomeId?.toString() === edge.target.toString(),
  )
  const colorInfo =
    edge.style?.stroke === undefined
      ? prevConnectorXml?.lineColor
      : convertRgbaColorToNumber(edge.style?.stroke!).numberColor
  return `
        <Connector>
          <Origin>
            <OutcomeId>${edge.source}</OutcomeId>
          </Origin>
          <Destination>
            <OutcomeId>${edge.target}</OutcomeId>
          </Destination>
          <RationaleInterventionX>${edge.data.rationaleInterventionX || 'NaN'}</RationaleInterventionX>
          <RationaleInterventionY>${edge.data.rationaleInterventionY || 'NaN'}</RationaleInterventionY>
          <lineThickness>${edge.data.width}</lineThickness>
          <lineStyle>${convertLineStyle(edge)}</lineStyle>
          <ConnectorStyle>${convertEdgeType(edge)}</ConnectorStyle>
          <PivotX>${prevConnectorXml?.PivotX || 'NaN'}</PivotX>
          <PivotY>${prevConnectorXml?.PivotY || 'NaN'}</PivotY>
          <lineColor>${colorInfo || 0}</lineColor>
          ${convertRationale(edge)}
          ${convertInterventions(edge)}
        </Connector>`
}

function convertEdgeType({ type }: EdgeElement): XmlTocoScheme.Connector['ConnectorStyle'] {
  switch (type) {
    case EdgeSchemeType.stepEdgeIntervention:
      return 'basic'
    case EdgeSchemeType.straightEdgeIntervention:
      return 'straight'
    case EdgeSchemeType.smoothStepEdgeIntervention:
      return 'roundedCorner'

    default: {
      console.warn(`Unexpected ConnectorStyle: ${type}. Check defineEdgeType function`)
      return 'basic'
    }
  }
}

function convertLineStyle({ data: { lineStyle } }: EdgeElement): XmlTocoScheme.Connector['lineStyle'] {
  switch (lineStyle) {
    case 'solid':
      return 'Solid'
    case 'dashed':
      return 'Dashed'
    case 'dotted':
      return 'Dotted'

    default: {
      console.warn(`Unexpected lineStyle: ${lineStyle}. Check defineLineStyle function`)
      return 'Solid'
    }
  }
}

function convertInterventions({ data: { interventions } }: EdgeElement) {
  if (!interventions || !interventions.length) {
    return '<Interventions/>'
  }
  const notEmptyIndicators = interventions.filter(({ text }) => text)
  if (!notEmptyIndicators.length) {
    return '<Interventions/>'
  }

  return `<Interventions>
  ${notEmptyIndicators
    .map(
      (intervention) => `          <Intervention>
              <Text>${encodeHtml(intervention.text)}</Text>
              <FullText>${intervention.isFullText}</FullText>
            </Intervention>`,
    )
    .join('\n')}
          </Interventions>`
}

function convertRationale({ data: { rationale } }: EdgeElement) {
  if (!rationale?.text) {
    return '<Rationale/>'
  }

  return `  <Rationale>
              <Text>${encodeHtml(rationale.text)}</Text>
              <FullText>${rationale.isFullText}</FullText>
            </Rationale>`
}
