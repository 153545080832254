import * as React from 'react'
import { DASHBOARD_TAB_ID, useDashboardPageContext } from '../context/use-dashboard-page-context'
import { useSchemeDefaultPosition } from '../../../../../components/diagram/utils/hooks/use-scheme-default-position'
import { Flow } from '../../../../../components/diagram'
import { DashboadTab } from '../../../../../components/dashboardTab'
import { Spinner } from '../../../../../components/spinner'
import { useAutoSave } from '../../../../../components/toolbar/hooks/use-auto-save-diagram'
import { useEditorContext } from '../../../../../components/editor/context/editor-context-provider'

export function Diagram() {
  const { scheme, updateScheme, schemeInfo, activeTab } = useDashboardPageContext()
  const { paneMovable } = useEditorContext()
  useAutoSave()
  const { defaultPosition, onResize } = useSchemeDefaultPosition({ scheme, schemeId: schemeInfo?.id })
  if (activeTab?.id === DASHBOARD_TAB_ID) {
    return <DashboadTab />
  }
  if (!scheme) {
    return activeTab?.isSchemeLoading ? <Spinner show={true} /> : null
  }
  return (
    <Flow
      scheme={scheme}
      updateScheme={updateScheme}
      defaultPosition={defaultPosition}
      onResize={onResize}
      schemeInfo={schemeInfo}
      paneMovable={paneMovable}
    />
  )
}
