import React, { createContext, PropsWithChildren, useContext, useMemo } from 'react'
import { Scheme, UpdateScheme } from '../scheme/interfaces'
import { useElementUtils } from '../utils/hooks/use-element-utils'

type ContextReturnType = ReturnType<typeof useProviderValue>

const Context = createContext<ContextReturnType>({} as ContextReturnType)

type Props = {
  scheme: Scheme
  updateScheme: UpdateScheme
}

export function InnerSchemeAccessContextProvider({ children, scheme, updateScheme }: PropsWithChildren<Props>) {
  const value = useProviderValue({ scheme, updateScheme })
  return <Context.Provider value={value}>{children}</Context.Provider>
}

export function useInnerSchemeAccessContext() {
  return useContext(Context)
}
function useProviderValue({ scheme, updateScheme }: Props) {
  const { updateElement } = useElementUtils(updateScheme, scheme)
  const value = useMemo(
    () => ({
      scheme,
      updateScheme,
      updateElement,
    }),
    [scheme, updateScheme, updateElement],
  )
  return value
}
