import { useCallback, useEffect, useMemo, useState } from 'react'
import { Dimensions } from 'react-flow-renderer'
import { usePrevious } from 'react-use'
import { ContentRect } from 'react-measure'
import { NodeElement, Scheme } from '../../scheme/interfaces'
import { isOutcomeNode } from '../check-node-type'
import { useDashboardPageContext } from '../../../../pages/layout/pages/dashboard/context/use-dashboard-page-context'

const TOP_OFFSET_TO_UPPER_SHAPE = 50 // px

type Props = {
  scheme?: Scheme
  schemeId?: number // dep for recalculating default position only when other scheme arrives
}

// calculate default scheme
export function useSchemeDefaultPosition({ scheme, schemeId }: Props) {
  const { defaultPosition, setDefaultPosition } = useDashboardPageContext()
  const [diagramDimensions, setDiagramDimensions] = useState<Dimensions | undefined>(undefined)
  const previousDiagramDimensions = usePrevious(diagramDimensions)
  const loadedSchemeId = scheme && schemeId
  const previousLoadedSchemeId = usePrevious(loadedSchemeId)

  const onResize = useCallback(
    ({ bounds }: ContentRect) => {
      const height = parseInt(bounds!.height as any, 10)
      const width = parseInt(bounds!.width as any, 10)
      if (diagramDimensions?.height !== height || diagramDimensions?.width !== width) {
        setDiagramDimensions({
          height,
          width,
        })
      }
    },
    [diagramDimensions, setDiagramDimensions],
  )

  useEffect(() => {
    const diagramDimensionsChanged = diagramDimensions !== previousDiagramDimensions
    const loadedSchemeIdChanged = loadedSchemeId !== previousLoadedSchemeId
    if (diagramDimensionsChanged || loadedSchemeIdChanged) {
      if (diagramDimensions && loadedSchemeId) {
        const nodes = (scheme as NodeElement[]).filter(isOutcomeNode)
        const minX = Math.min(...nodes.map((l) => l.position.x))
        const maxX = Math.max(...nodes.map((l) => l.position.x + ((l.style?.width as number) || 0)))
        const minY = Math.min(...nodes.map((l) => l.position.y))
        const avgX = (minX + maxX) / 2
        const x = parseInt((-avgX + diagramDimensions.width / 2) as any, 10)
        const y = parseInt((-minY + TOP_OFFSET_TO_UPPER_SHAPE) as any, 10)
        setDefaultPosition([x, y])
      }
    }
  }, [diagramDimensions, previousDiagramDimensions, loadedSchemeId, previousLoadedSchemeId, scheme, setDefaultPosition])

  return useMemo(
    () => ({
      defaultPosition,
      onResize,
    }),
    [defaultPosition, onResize],
  )
}
