import React, { useCallback, useMemo } from 'react'
import { useEditorContext } from '../components/editor/context/editor-context-provider'

export function useTabsHandler() {
  const { activeTabIndex, setActiveTabIndex } = useEditorContext()

  const handleTabChange = useCallback(
    (event: React.ChangeEvent<{}>, newActiveTab: number) => {
      setActiveTabIndex(newActiveTab)
    },
    [setActiveTabIndex],
  )

  return useMemo(
    () => ({
      activeTabIndex,
      handleTabChange,
    }),
    [activeTabIndex, handleTabChange],
  )
}
