import React, { memo } from 'react'
import { EditorLayout } from '../components/layout'
import { EditorTitle } from '../components/layout/title'
import { TextBoxTabs } from './text-box-tabs'

export const TextBoxEditor = memo(() => {
  return (
    <EditorLayout>
      <EditorTitle>Text Box Editor</EditorTitle>
      <TextBoxTabs />
    </EditorLayout>
  )
})
