import { useTakeOutcomeColors } from '../../../toolbar/hooks/add-shape/use-generate-color-legend'
import { useInnerSchemeAccessContext } from '../../context/use-inner-scheme-access'
import { usePrevious } from 'react-use'
import { useEffect, useState } from 'react'
import { ColorLegendNodeElement } from './index'

const DEBOUNCE_DELAY = 500
export function useColorsWatcher(colorLegendNodeId: string) {
  const { scheme, updateElement } = useInnerSchemeAccessContext()
  const takeOutcomeColors = useTakeOutcomeColors()
  // debounced value
  const [outcomeColors, setOutcomeColors] = useState<string[]>()
  const previousOutcomeColors = usePrevious(outcomeColors)

  useEffect(() => {
    const isOutcomeColorsLinkChanged = previousOutcomeColors !== outcomeColors
    if (outcomeColors && isOutcomeColorsLinkChanged) {
      const areColorsChanged =
        outcomeColors?.length !== previousOutcomeColors?.length ||
        outcomeColors.some((color, i) => color !== previousOutcomeColors[i])
      if (areColorsChanged) {
        updateElement<ColorLegendNodeElement>(colorLegendNodeId, (el) => {
          const prevItems = el.data.legendItems
          const itemsWithActualColors = prevItems.filter(({ color }) => outcomeColors.includes(color))
          const actualColors = itemsWithActualColors.map((li) => li.color)
          const newColors = outcomeColors.filter((color) => !actualColors.includes(color))
          const itemsWithActualAndNewColors = itemsWithActualColors.concat(
            newColors.map((color) => ({
              color,
              text: '',
            })),
          )
          return {
            ...el,
            data: {
              ...el.data,
              legendItems: itemsWithActualAndNewColors,
            },
          }
        })
      }
    }
  }, [outcomeColors, previousOutcomeColors, colorLegendNodeId, updateElement])
  // debounced update colors
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setOutcomeColors(takeOutcomeColors())
    }, DEBOUNCE_DELAY)
    return () => clearTimeout(timeoutId)
  }, [scheme, takeOutcomeColors])
}
