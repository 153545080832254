export enum NodeSchemeType {
  // default block
  // default = 'default',

  triangle = 'triangle',
  rectangle = 'rectangle',
  roundedRectangle = 'rounded-rectangle',
  pentagon = 'pentagon',
  hexagon = 'hexagon',
  octagon = 'octagon',
  ellipse = 'ellipse',

  accountabilityCeiling = 'accountability-ceiling',
  colorLegend = 'color-legend',
  comment = 'comment',
  textBox = 'text-box',
  callOut = 'call-out',
}

export enum EdgeSchemeType {
  // default block
  // default = 'default',
  // straight = 'straight',
  // step = 'step',
  // smoothstep = 'smoothstep',

  // custom block
  straightEdgeIntervention = 'straight-edge-intervention',
  bezierEdgeIntervention = 'bezier-edge-intervention',
  stepEdgeIntervention = 'step-edge-intervention',
  smoothStepEdgeIntervention = 'smooth-step-edge-intervention',
}
