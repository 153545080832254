import React, { memo } from 'react'
import { Box, Divider } from '@material-ui/core'
import styles from './toolbar.module.scss'
import { ToolsBox } from './tools-box'
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab'
import { FileMenu } from './file-menu'
import { EditMenu } from './edit-menu'
import { HelpMenu } from './help-menu'
import { useToggle } from 'react-use'
import { CanvasBox } from './canvas-box'
import { useEditorContext } from '../editor/context/editor-context-provider'
import { IconMenu } from './icon-menu'
import clsx from 'clsx'

export const ToolBar = memo(() => {
  const [typeBar, switchTypeBar] = useToggle(true)
  const { schemeInfo } = useEditorContext()
  return (
    <Box className={clsx(styles.toolbarWrapper, 'no-print')} p={1}>
      <FileMenu />
      <EditMenu />
      <HelpMenu />
      <Divider orientation="vertical" flexItem />
      <IconMenu />
      {schemeInfo && (
        <>
          <Divider orientation="vertical" flexItem />
          {typeBar && <ToolsBox />}
          {!typeBar && <CanvasBox />}
          <ToggleButtonGroup
            className={styles.toolBarToggle}
            size="small"
            value={typeBar ? 'Tools' : 'Canvas'}
            exclusive
            onChange={() => switchTypeBar()}
          >
            <ToggleButton value="Canvas">Canvas</ToggleButton>
            <ToggleButton value="Tools">Tools</ToggleButton>
          </ToggleButtonGroup>
        </>
      )}
    </Box>
  )
})
