import { Box, IconButton } from '@material-ui/core'
import React, { PropsWithChildren } from 'react'
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore'
import { useEditorContext } from '../../../context/editor-context-provider'
import styles from './index.module.scss'

export function EditorTitle({ children }: PropsWithChildren<{}>) {
  const { closeEditor } = useEditorContext()
  return (
    <div className={styles.title}>
      <Box p={1}>{children}</Box>
      <IconButton color="primary" aria-label="NavigateBeforeIcon" onClick={closeEditor} component="span">
        <NavigateBeforeIcon />
      </IconButton>
    </div>
  )
}
