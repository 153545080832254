import React, { memo } from 'react'
import { NarrativeTabs } from './narrative-tabs'
import { EditorLayout } from '../components/layout'
import { EditorTitle } from '../components/layout/title'

export const NarrativeEditor = memo(() => {
  return (
    <EditorLayout>
      <EditorTitle>Narrative Editor</EditorTitle>
      <NarrativeTabs />
    </EditorLayout>
  )
})
