import { Box } from '@material-ui/core'
import clsx from 'clsx'
import React, { memo } from 'react'
import { useEditorContext } from '../../editor/context/editor-context-provider'
import { BgGridToggle } from './grid-toggler'
import { AddAccountabilityCeiling } from './accountability-ceiling'

export const CanvasBox = memo(() => {
  const { schemeInfo } = useEditorContext()
  return (
    <Box px={2} display="flex" alignItems="center">
      <Box component="span" fontSize="small" className={clsx({ labelGgay: !schemeInfo })} mr={1}>
        CANVAS:
      </Box>
      <BgGridToggle />
      <AddAccountabilityCeiling />
    </Box>
  )
})
