import { XmlTocoScheme } from '../interface'
import { EdgeElement, NodeElement } from '../../../../../../components/diagram/scheme/interfaces'
import { EdgeSchemeType } from '../../../../../../components/diagram/scheme/enums'
import { toArrayAndMap } from '../utils/to-array-and-map'
import { removeTagsAndDecodeHtml } from '../utils/decode-html'
import { DEFAULT_EDGE_STROKE_WIDTH } from '../../../../../../components/diagram/constants'
import { SchemeExtraParams } from '../utils/extra-params/scheme-extra-params'
import { Position } from 'react-flow-renderer'
import { convertNumberColorToRgba } from '../utils/color'

export function parseEdge(
  connector: XmlTocoScheme.Connector,
  i: number,
  schemeExtraParams: SchemeExtraParams | undefined,
  nodes: NodeElement[],
): EdgeElement {
  const source = connector.Origin.OutcomeId.toString()
  const target = connector.Destination.OutcomeId.toString()
  const handleInfo = schemeExtraParams?.edgesInfo?.handlers?.find(
    (info) => info.source === source && info.target === target,
  )
  const { sourceHandle, targetHandle } = handleInfo || calcHandlePosition(source, target, nodes)
  const isCurveLine = schemeExtraParams?.edgesInfo?.curveLines?.some(
    (info) => info.source === source && info.target === target,
  )
  return {
    id: `${connector.Origin.OutcomeId}__${connector.Destination.OutcomeId}__${i}`,
    source,
    target,
    sourceHandle,
    targetHandle,
    type: defineEdgeType(connector, isCurveLine),
    style: {
      stroke: convertNumberColorToRgba(connector.lineColor, schemeExtraParams?.edgesInfo?.transparency),
    },
    data: {
      width: connector.lineThickness || DEFAULT_EDGE_STROKE_WIDTH,
      rationale:
        connector.Rationale && connector.Rationale.Text
          ? {
              text: removeTagsAndDecodeHtml(connector.Rationale.Text),
              isFullText: connector.Rationale.FullText,
            }
          : undefined,
      interventions: toArrayAndMap(connector.Interventions.Intervention, makeIntervention),
      lineStyle: defineLineStyle(connector),
      rationaleInterventionX: connector.RationaleInterventionX,
      rationaleInterventionY: connector.RationaleInterventionY,
    },
  }
}

function defineEdgeType(connector: XmlTocoScheme.Connector, isCurveLine?: boolean): EdgeSchemeType {
  if (isCurveLine) {
    return EdgeSchemeType.bezierEdgeIntervention
  }

  switch (connector.ConnectorStyle) {
    case '':
    case 'basic':
      return EdgeSchemeType.stepEdgeIntervention
    case 'straight':
      return EdgeSchemeType.straightEdgeIntervention
    case 'roundedCorner':
      return EdgeSchemeType.smoothStepEdgeIntervention

    default: {
      console.warn(`Unexpected ConnectorStyle: ${connector.ConnectorStyle}. Check defineEdgeType function`)
      return EdgeSchemeType.stepEdgeIntervention
    }
  }
}

function defineLineStyle(connector: XmlTocoScheme.Connector) {
  switch (connector.lineStyle) {
    case 'Solid':
      return 'solid'
    case 'Dashed':
      return 'dashed'
    case 'Dotted':
      return 'dotted'

    default: {
      console.warn(`Unexpected lineStyle: ${connector.lineStyle}. Check defineLineStyle function`)
      return 'solid'
    }
  }
}

function makeIntervention(xmlIntervention: XmlTocoScheme.Intervention) {
  return {
    isFullText: xmlIntervention.FullText,
    text: removeTagsAndDecodeHtml(xmlIntervention.Text),
  }
}

function calcHandlePosition(
  source: string,
  target: string,
  nodes: NodeElement[],
): { sourceHandle: Position; targetHandle: Position } {
  const sourceNode = nodes.find((node) => node.id === source)
  const targetNode = nodes.find((node) => node.id === target)
  if (sourceNode && targetNode) {
    const sourceRect = getNodeRect(sourceNode)
    const targetRect = getNodeRect(targetNode)
    if (sourceRect && targetRect) {
      // up
      if (sourceRect.top >= targetRect.bottom) {
        return {
          sourceHandle: Position.Top,
          targetHandle: Position.Bottom,
        }
      }
      // down
      if (sourceRect.bottom <= targetRect.top) {
        return {
          sourceHandle: Position.Bottom,
          targetHandle: Position.Top,
        }
      } // to right
      if (sourceRect.right <= targetRect.left) {
        return {
          sourceHandle: Position.Right,
          targetHandle: Position.Left,
        }
      } // to left
      if (sourceRect.left >= targetRect.right) {
        return {
          sourceHandle: Position.Left,
          targetHandle: Position.Right,
        }
      }
    }
  }
  return {
    sourceHandle: Position.Bottom,
    targetHandle: Position.Top,
  }
}

function getNodeRect(node: NodeElement) {
  if (!node.style?.height || !node.style?.width) {
    console.warn('Node must have specified height and width property in style. Check getNodeRect function', node)
    return undefined
  }
  return {
    top: node.position.y,
    bottom: node.position.y + (node.style?.height as number),
    left: node.position.x,
    right: node.position.x + (node.style?.width as number),
  }
}
