import React, { memo, useCallback } from 'react'
import { EdgeElement } from '../../../../../diagram/scheme/interfaces'
import { IconButton } from '@material-ui/core'
import CompareArrowsIcon from '@material-ui/icons/CompareArrows'
import { useEditorContext } from '../../../../context/editor-context-provider'

export const EdgeDirectionSwitcher = memo(() => {
  const { activeElement, updateElement, setActiveElementId } = useEditorContext()
  const switchEdgeDirection = useCallback(() => {
    updateElement<EdgeElement>(activeElement!.id, (prevEdge) => {
      const id = `${prevEdge.target}_${prevEdge.targetHandle}__${prevEdge.source}_${prevEdge.sourceHandle}__R`
      setActiveElementId(id)
      return {
        ...prevEdge,
        id,
        source: prevEdge.target,
        sourceHandle: prevEdge.targetHandle,
        target: prevEdge.source,
        targetHandle: prevEdge.sourceHandle,
      }
    })
  }, [updateElement, activeElement, setActiveElementId])
  return (
    <IconButton onClick={switchEdgeDirection}>
      <CompareArrowsIcon />
    </IconButton>
  )
})
