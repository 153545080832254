import React, { memo } from 'react'
import { ConnectionLabel } from '../connection-label'
import { EdgeElementWithType, InterventionEdgeProps } from '../scheme/interfaces'
import { EdgeSchemeType } from '../scheme/enums'
import { BezierEdgeWithContent } from '../core/edge/bezier-edge'
import { useEdgeConfiguration } from './hooks/use-edge-configuration'

export type BezierEdgeInterventionElement = EdgeElementWithType<EdgeSchemeType.bezierEdgeIntervention>
export const BezierEdgeIntervention = memo((props: InterventionEdgeProps) => {
  const { triggerAreaPathProps, style } = useEdgeConfiguration(props)
  return (
    <BezierEdgeWithContent
      {...props}
      style={style}
      triggerAreaPathProps={triggerAreaPathProps}
      label={<ConnectionLabel {...props} />}
    />
  )
})
