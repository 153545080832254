import React from 'react'
import { Avatar, Box, Button } from '@material-ui/core'
import HomeIcon from '@material-ui/icons/Home'
import ContactSupportIcon from '@material-ui/icons/ContactSupport'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import { useAuthContext } from '../../../components/context/use-auth-context'
import { utc } from 'moment'
import { Link } from 'react-router-dom'

import logo from './images/TOCO-logo.svg'
import training from './images/ic_training.svg'
import styles from './header.module.scss'
import { CreateTocBtn } from './create-toc-btn'
import clsx from 'clsx'

export const Header = () => {
  const { logOut, authData } = useAuthContext()
  const lastLoginDateMoment = utc(authData?.lastLogIn).local()
  const isValidLastLoginDate = lastLoginDateMoment.isValid()
  const lastLoginDate = lastLoginDateMoment.format('MMM Do YYYY, h:mm a')
  return (
    <Box component="header" className={clsx(styles.header, 'no-print')}>
      <Box className="logo" display="flex" mr="auto" pl={2}>
        <img src={logo} alt="Toco logo" className="logo__img" />
        <Box className="logo__info" pl={1}>
          <Box fontWeight={400} mb={0.5} fontSize={18}>
            Welcome {authData?.firstName}
          </Box>
          {isValidLastLoginDate && (
            <Box component="p" fontSize={12}>
              <Box component="span" mr={0.5}>
                Logged in
              </Box>
              {lastLoginDate}
            </Box>
          )}
        </Box>
      </Box>
      <Box m={1} display="Block" className="nav-btn">
        <Link to={'/'}>
          <Button variant="outlined" color="secondary">
            <HomeIcon />
          </Button>
        </Link>
        <Box fontWeight={500} fontSize={12}>
          Dashboard
        </Box>
      </Box>
      <CreateTocBtn />
      <Box m={1} display="Block" className="nav-btn">
        <Button
          variant="outlined"
          color="secondary"
          href="http://help.actknowledge.org/index.php?title=Main_Page"
          target="_blank"
        >
          <ContactSupportIcon />
        </Button>
        <Box fontWeight={500} fontSize={12}>
          Help
        </Box>
      </Box>
      {/* <Box m={1} display="Block" className="nav-btn">
        <Button variant="outlined" color="secondary" disabled>
          <AccountCircleIcon />
        </Button>
        <Box fontWeight={500} fontSize={12}>
          My Account
        </Box>
      </Box> */}
      <Box m={1} display="Block" className="nav-btn">
        <Button
          variant="outlined"
          color="secondary"
          href="https://www.theoryofchange.org/toco-software/support-and-training/"
          target="_blank"
        >
          <Avatar className="trainingIcon" alt="Training" src={training} />
        </Button>
        <Box fontWeight={500} fontSize={12}>
          Training
        </Box>
      </Box>
      <Box mx={1} display="Block" className="nav-btn">
        <Button variant="outlined" color="secondary" onClick={() => logOut()}>
          <ExitToAppIcon />
        </Button>
        <Box fontWeight={500} fontSize={12}>
          Logout
        </Box>
      </Box>
    </Box>
  )
}
