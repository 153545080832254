import React from 'react'
import { Pages } from './pages'
import { AuthContextProvider } from './components/context/use-auth-context'
import { BrowserRouter } from 'react-router-dom'
import { createMuiTheme, makeStyles, ThemeProvider } from '@material-ui/core'
import { blue, green, pink, red, yellow } from '@material-ui/core/colors'
import 'fontsource-roboto'
import { SnackbarOrigin, SnackbarProvider } from 'notistack'

const MAX_SNACKBAR = 3
const AUTO_HIDE_DURATION = 3000
const anchorOrigin: SnackbarOrigin = {
  vertical: 'bottom',
  horizontal: 'center',
}

const theme = createMuiTheme({
  palette: {
    primary: blue,
    secondary: pink,
    warning: yellow,
    success: green,
    info: blue,
    error: red,
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  overrides: {
    MuiButton: {
      outlinedSecondary: {
        color: 'white',
        borderColor: 'white',
        '&:hover': {
          borderColor: 'rgb(224, 224, 224, 0.5)',
        },
      },
    },
  },
  typography: {
    htmlFontSize: 10,
  },
})

const useStyles = makeStyles((theme) => ({
  mediaWrapper: {
    '@media (max-width: 1530px)': {
      minWidth: '1530px',
      overflowY: 'hidden',
    },
  },
}))

export function App() {
  const classes = useStyles()
  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <SnackbarProvider maxSnack={MAX_SNACKBAR} autoHideDuration={AUTO_HIDE_DURATION} anchorOrigin={anchorOrigin}>
          <div className={classes.mediaWrapper}>
            <AuthContextProvider>
              <Pages />
            </AuthContextProvider>
          </div>
        </SnackbarProvider>
      </ThemeProvider>
    </BrowserRouter>
  )
}
