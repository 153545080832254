import { SchemeInfo } from '../../../../../../components/diagram/scheme/interfaces'

export function convertUnderlyingAssumptionsToXml(underlyingAssumptions: SchemeInfo['underlyingAssumptions']) {
  return `<UnderlyingAssumptions>${underlyingAssumptions
    .map((underlyingAssumption) => convertUnderlyingAssumption(underlyingAssumption))
    .join('\n')}
      </UnderlyingAssumptions>`
}

function convertUnderlyingAssumption(underlyingAssumption: any) {
  return `<UnderlyingAssumption>
          ${underlyingAssumption}
        </UnderlyingAssumption>`
}
