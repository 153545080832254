// need to be wrapper by FlowConfigurationProvider
import { EdgeElement, Scheme, UpdateScheme } from '../../scheme/interfaces'
import { useContainerNodeMiddleware } from './use-container-node-middleware'
import {
  addEdge,
  Edge,
  FlowElement,
  FlowTransform,
  OnLoadParams,
  ReactFlowProps,
  removeElements,
} from 'react-flow-renderer'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { EdgeSchemeType } from '../../scheme/enums'
import { useEditorContext } from '../../../editor/context/editor-context-provider'
import { DEFAULT_EDGE_STROKE_WIDTH } from '../../constants'
import { useDashboardPageContext } from '../../../../pages/layout/pages/dashboard/context/use-dashboard-page-context'

export function useFlowHandlers(
  updateScheme: UpdateScheme,
  defaultPosition: ReactFlowProps['defaultPosition'],
  isFrozen?: boolean,
  onLoadCb?: () => void,
) {
  /**
   * Function allow to get elements with actual position.
   * Library does not update original scheme:
   * https://github.com/wbkd/react-flow/issues/777#issuecomment-747348510
   */
  const [getActualElement, setActualElementsGetter] = useState<OnLoadParams['getElements']>()
  const { setNewXyPosition, setCurrentDefaultPosition, setActiveElementId } = useEditorContext()
  const { onSelectionChange } = useContainerNodeMiddleware()
  const { activeTab, updateSchemeInfo, setDefaultPosition } = useDashboardPageContext()

  useEffect(() => {
    if (defaultPosition) {
      setCurrentDefaultPosition?.(defaultPosition)
    }
  }, [defaultPosition, setCurrentDefaultPosition])

  const onMoveEnd = useCallback(
    (xyPosition?: FlowTransform | undefined) => {
      setNewXyPosition?.(xyPosition)
    },
    [setNewXyPosition],
  )

  const onLoad = useCallback(
    ({ getElements, fitView }: OnLoadParams) => {
      // getElements might contain outdated element right now, but when we will call it, it will have actual data
      setActualElementsGetter?.(() => getElements)
      if (isFrozen) {
        fitView()
      }
      if (onLoadCb) {
        onLoadCb()
      }
    },
    [isFrozen, onLoadCb],
  )

  const onMove = useCallback(
    (e: FlowTransform | undefined) => {
      if (activeTab?.id) {
        updateSchemeInfo((prevScheme) => ({
          ...prevScheme,
          x: e!.x,
          y: e!.y,
          zoom: e!.zoom,
        }))
        setDefaultPosition([e!.x, e!.y])
      }
    },
    [activeTab?.id, setDefaultPosition, updateSchemeInfo],
  )

  const onElementsRemove: ReactFlowProps['onElementsRemove'] = useCallback(
    (elementsToRemove) => updateScheme?.((els) => removeElements(elementsToRemove, els! as FlowElement[]) as Scheme),
    [updateScheme],
  )

  const onConnect: ReactFlowProps['onConnect'] = useCallback(
    (edge) => {
      const edgeElement: EdgeElement = {
        source: edge.source!,
        sourceHandle: edge.sourceHandle,
        target: edge.target!,
        targetHandle: edge.targetHandle,
        id: `${edge.source}_${edge.sourceHandle}__${edge.target}_${edge.targetHandle}`,
        type: EdgeSchemeType.stepEdgeIntervention,
        data: {
          width: DEFAULT_EDGE_STROKE_WIDTH,
          lineStyle: 'solid',
        },
      }

      updateScheme?.((els) => addEdge(edgeElement as Edge, els! as FlowElement[]) as Scheme)
    },
    [updateScheme],
  )

  const onNodeDragStop = useCallback(() => {
    if (getActualElement) {
      const scheme = getActualElement?.() as Scheme
      updateScheme(() => scheme)
    }
  }, [getActualElement, updateScheme])

  const onPanelClick = useCallback(() => {
    setActiveElementId?.(undefined)
  }, [setActiveElementId])

  return useMemo(
    () => ({
      onPanelClick,
      onElementsRemove,
      onConnect,
      onSelectionChange,
      onLoad,
      onMoveEnd,
      onNodeDragStop,
      onMove,
    }),
    [onPanelClick, onConnect, onElementsRemove, onSelectionChange, onLoad, onMoveEnd, onNodeDragStop, onMove],
  )
}
