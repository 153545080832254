export function toArrayAndMap<T, R, N = NonNullable<T extends any[] ? T[0] : T>>(
  nodes: T,
  fn: (n: N, i: number) => R,
): R[] {
  return (toArray(nodes) as N[]).map(fn)
}

/**
 * Function receives one item or list of items or undefined value and always return array
 */
export function toArray<T, N = NonNullable<T extends any[] ? T[0] : T>>(objectOrArray: T): N[] {
  if (!objectOrArray) {
    return []
  }
  return Array.isArray(objectOrArray) ? objectOrArray : (([objectOrArray] as unknown) as N[])
}
