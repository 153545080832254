import { NodeElement, OutcomeNodeElement, Scheme } from '../../../../../../components/diagram/scheme/interfaces'
import { XmlTocoScheme } from '../interface'
import { NodeSchemeType } from '../../../../../../components/diagram/scheme/enums'
import { encodeExtraParams } from '../utils/extra-params'
import { Indicator } from '../../../../../../dto'
import { encodeHtml } from '../utils/decode-html'
import { convertRgbaColorToNumber } from '../utils/color'
import { toArray } from '../utils/to-array-and-map'
import { isOutcomeNode } from '../../../../../../components/diagram/utils/check-node-type'

export function convertNodesToXml(scheme: Scheme, xmlTocoScheme: XmlTocoScheme.RootObject) {
  const nodes: OutcomeNodeElement[] = scheme.filter(isOutcomeNode)
  return `<Outcomes>${nodes.map((node) => convertNode(node, xmlTocoScheme)).join('\n')}
      </Outcomes>`
}

function convertNode(node: OutcomeNodeElement, xmlTocoScheme: XmlTocoScheme.RootObject) {
  const colorInfo = convertRgbaColorToNumber(node.data.color)

  const prevOutcomeXml = toArray(xmlTocoScheme?.Toc?.Content?.CanvasContent?.Outcomes?.Outcome).find(
    (prev) => prev.OutcomeId?.toString() === node.id.toString(),
  )
  return `        <Outcome>
          <OutcomeId>${node.id}</OutcomeId>
          <Title>${encodeHtml(node.data.title)}</Title>
          <Description>${node.data.description}</Description>
          <x>${node.position.x}</x>
          <y>${node.position.y}</y>
          <Width>${node.style?.width}</Width>
          <Height>${node.style?.height}</Height>
          <Color>${colorInfo?.numberColor}</Color>
          <outcomeFontWeight>${encodeExtraParams({ opacity: colorInfo?.transparency })}</outcomeFontWeight>
          <outcomeType>${convertNodeType(node)}</outcomeType>
          <outcomeTextFontChoice>${prevOutcomeXml?.outcomeTextFontChoice || 'Verdana'}</outcomeTextFontChoice>
          <outcomeFontColor>${prevOutcomeXml?.outcomeFontColor || 0}</outcomeFontColor>
          <outcomeFontSize>${prevOutcomeXml?.outcomeFontSize || 14}</outcomeFontSize>
          <outcomeFontStyle>${prevOutcomeXml?.outcomeFontStyle || 'normal'}</outcomeFontStyle>
          <linkedResource>${prevOutcomeXml?.linkedResource || ''}</linkedResource>
          <Depth>${prevOutcomeXml?.Depth || 'NaN'}</Depth>
          <TitleVisibility>${
            prevOutcomeXml?.TitleVisibility == null ? 1 : prevOutcomeXml?.TitleVisibility
          }</TitleVisibility>
          ${convertIndicators(node)}
        </Outcome>`
}

function convertNodeType({ type }: NodeElement): XmlTocoScheme.Outcome['outcomeType'] {
  switch (type) {
    case NodeSchemeType.rectangle:
      return 'rectangle'
    case NodeSchemeType.triangle:
      return 'triangle'
    case NodeSchemeType.pentagon:
      return 'pentagon'
    case NodeSchemeType.ellipse:
      return 'oval'
    case NodeSchemeType.hexagon:
      return 'hexagon'
    case NodeSchemeType.octagon:
      return 'octagon'
    case NodeSchemeType.roundedRectangle:
    default:
      return ''
  }
}

function convertIndicators({ data: { indicators } }: OutcomeNodeElement) {
  if (!indicators || !indicators.length) {
    return '<Indicators/>'
  }
  const notEmptyIndicators = indicators.filter(
    ({ whatWillChange, byWhen, howMuch, howMany, who }) => whatWillChange || byWhen || howMuch || howMany || who,
  )
  if (!notEmptyIndicators.length) {
    return '<Indicators/>'
  }

  return `<Indicators>
  ${notEmptyIndicators
    .map(
      (indicator) => `          <Indicator>
              <Status>${convertIndicatorStatus(indicator.status)}</Status>
              <WhatWillChange>${encodeHtml(indicator.whatWillChange)}</WhatWillChange>
              <Who>${encodeHtml(indicator.who)}</Who>
              <HowMany>${encodeHtml(indicator.howMany)}</HowMany>
              <HowMuch>${encodeHtml(indicator.howMuch)}</HowMuch>
              <ByWhen>${encodeHtml(indicator.byWhen)}</ByWhen>
            </Indicator>`,
    )
    .join('\n')}
          </Indicators>`
}

function convertIndicatorStatus(xmlStatus: Indicator['status']): XmlTocoScheme.Indicator['Status'] {
  switch (xmlStatus) {
    case 'inProgress':
      return 'inprogress'
    case 'needsAttention':
      return 'needsattention'
    default:
      return xmlStatus
  }
}
