import React, { memo } from 'react'
import { EdgeContent } from './common/edge-content'
import { getMarkerEnd } from './common/utils'
import { EdgeWithContentProps } from './common/interface'

export const StraightEdgeWithContent = memo(
  ({
    sourceX,
    sourceY,
    targetX,
    targetY,
    label,
    labelStyle,
    labelShowBg,
    labelBgStyle,
    labelBgPadding,
    labelBgBorderRadius,
    style,
    arrowHeadType,
    markerEndId,
    triggerAreaPathProps,
  }: EdgeWithContentProps) => {
    const yOffset = Math.abs(targetY - sourceY) / 2
    const centerY = targetY < sourceY ? targetY + yOffset : targetY - yOffset

    const xOffset = Math.abs(targetX - sourceX) / 2
    const centerX = targetX < sourceX ? targetX + xOffset : targetX - xOffset
    const markerEnd = getMarkerEnd(arrowHeadType, markerEndId)

    const text = label ? (
      <EdgeContent
        x={centerX}
        y={centerY}
        label={label}
        labelStyle={labelStyle}
        labelShowBg={labelShowBg}
        labelBgStyle={labelBgStyle}
        labelBgPadding={labelBgPadding}
        labelBgBorderRadius={labelBgBorderRadius}
      />
    ) : null
    const path = `M ${sourceX},${sourceY}L ${targetX},${targetY}`
    return (
      <>
        <path style={style} d={path} markerEnd={markerEnd} />
        <path d={path} {...triggerAreaPathProps} />
        {text}
      </>
    )
  },
)
