import React from 'react'
import InfoIcon from '@material-ui/icons/Info'

type Props = {
  toggleIndicator: () => void
}

export const Info = ({ toggleIndicator }: Props) => {
  return <InfoIcon onClick={toggleIndicator} fontSize="small" color="primary" style={{ cursor: 'pointer' }} />
}
