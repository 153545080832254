import { ColorLegendItem, Scheme } from '../../../../../../components/diagram/scheme/interfaces'
import { encodeHtml } from '../utils/decode-html'
import { isColorLegend } from '../../../../../../components/diagram/utils/check-node-type'
import { convertRgbaColorToNumber } from '../utils/color'

export function convertColorLegend(scheme: Scheme) {
  const colorLegend = scheme.filter(isColorLegend)[0]
  return `        <ColorLegend>
            <Visible>${!colorLegend ? 0 : 1}</Visible>
            <x>${!colorLegend ? 0 : colorLegend.position.x}</x>
            <y>${!colorLegend ? 0 : colorLegend.position.y}</y>
            <LegendItems>
                ${!colorLegend ? '' : colorLegend.data.legendItems.map((cl) => convertItem(cl)).join('\n')}
            </LegendItems>
        </ColorLegend>`
}

function convertItem(colorLegendItem: ColorLegendItem) {
  return `<Item>
            <Hex>${convertRgbaColorToNumber(colorLegendItem.color).numberColor}</Hex>
            <Text>${encodeHtml(colorLegendItem.text)}</Text>
          </Item>`
}
