import React, { useCallback, useEffect, useMemo, useRef, useState, memo } from 'react'
import { CellParams, ColDef, DataGrid, GridOverlay, SelectionChangeParams } from '@material-ui/data-grid'
import {
  Box,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
} from '@material-ui/core'
import RefreshIcon from '@material-ui/icons/Refresh'
import FolderSharedIcon from '@material-ui/icons/FolderShared'
import FolderIcon from '@material-ui/icons/Folder'
import SearchIcon from '@material-ui/icons/Search'
import { useAuthContext } from '../../../../../components/context/use-auth-context'
import { api } from '../../../../../api'
import { useRemoteData } from '../../../../../hooks/use-remote-data'
import styles from './table.module.scss'
import { TocoInfoDto, TocoListRequestI } from '../../../../../api/main/toco'
import { DASHBOARD_TAB_ID, useDashboardPageContext } from '../context/use-dashboard-page-context'
import { useLayoutContext } from '../../../context/use-layout-context'
import { UserRoles } from '../../../../../api/directory/auth'

export type RowData = {
  id: string
  tocoId: string
  title: string
  createUserId: string
  shareWith: string
  createTimestamp: string
  updateTimeStamp: string
  status: string
  username: string
}
type Column = Omit<ColDef, 'field'> & { field: keyof RowData }
type Params = Omit<CellParams, 'data'> & { data: RowData }
const columns: Column[] = [
  {
    field: 'title',
    headerName: 'ToC Title',
    headerClassName: 'header',
    width: 190, //Temporarily
    renderCell: ((params: Params) => <CellToolTip title={params.data.title} />) as any,
  },
  {
    field: 'createUserId',
    headerName: 'Created By',
    headerClassName: 'header',
    width: 110,
    renderCell: ((params: Params) => <CellToolTip title={params.data.createUserId} />) as any,
  },
  {
    field: 'updateTimeStamp',
    headerName: 'Updated Date',
    headerClassName: 'header',
    width: 125,
    renderCell: ((params: Params) => <CellToolTip title={params.data.updateTimeStamp} />) as any,
  },
  // ----Temporarily disabled----
  // {
  //   field: 'shareWith',
  //   headerName: 'Share',
  //   headerClassName: 'header',
  //   renderCell: ((params: Params) => <CellToolTip title={params.data.shareWith} />) as any,
  // },
]

const initialFilterStates = {
  page: 1,
  myToco: true,
  query: '',
  toQuery: 'title',
}

export const Table = memo(() => {
  const { createdTocoId, deleteTocWather, setDeleteTocWather } = useLayoutContext()
  const [reloadAfterCreatedDeleteTocWather, setReloadAfterCreatedDeleteTocWather] = useState(0)
  const createdTocoIdRef = useRef<string>()
  const { openOrSelectTabByToco, activeTab } = useDashboardPageContext()
  const [page, setPage] = useState(initialFilterStates.page)
  const [myToco, setMyToco] = useState(initialFilterStates.myToco)
  const [query, setQuery] = useState(initialFilterStates.query)
  const [toQuery, setToQuery] = useState(initialFilterStates.toQuery)
  const { authData } = useAuthContext()

  const onSelectionChange = useCallback(
    (selections: SelectionChangeParams) => {
      if (selections.rows.length) {
        const toco = selections.rows[0] as RowData
        openOrSelectTabByToco?.(toco.tocoId, toco.title)
      } else {
        if (!activeTab) {
          openOrSelectTabByToco?.(DASHBOARD_TAB_ID, 'Dashboard')
        }
      }
    },
    [activeTab, openOrSelectTabByToco],
  )

  const onPageChange = useCallback(
    ({ page }: { page: number }) => {
      setPage(page)
    },
    [setPage],
  )

  const [loadTocoList, tocoList, loading] = useRemoteData(api.toco.fetchList, [])

  const authParams = myToco ? authData?.userId : ''

  const loadToco = useCallback(() => {
    let params: TocoListRequestI = {
      pageno: page,
      username: authParams,
      dummy: true,
    }
    if (query) {
      params = {
        q: query,
        sortBy: toQuery,
        username: authParams,
        dummy: true,
      }
    }
    return loadTocoList(params)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadTocoList, page, authParams, query])

  const reformattedData = useMemo<RowData[]>(
    () =>
      tocoList.map((data) => ({
        id: `${data.tocid}-${data.sharewith}`,
        tocoId: data.tocid,
        title: data.title,
        createUserId: data.createuserid,
        shareWith: data.sharewith,
        createTimestamp: data.createtimestamp,
        updateTimeStamp: data.updatetimestamp,
        status: data.status,
        username: data.username,
      })),
    [tocoList],
  )

  const timeoutActiveTabIdRef = useRef<any>()

  const openActiveTab = useCallback(() => {
    const elem = document.querySelectorAll(`[data-id^="${activeTab?.id}-"] .table-cell-truncate`)
    elem[0]?.dispatchEvent(new Event('click'))
  }, [activeTab?.id])

  useEffect(() => {
    if (!loading) {
      timeoutActiveTabIdRef.current = setTimeout(() => {
        openActiveTab()
      }, 100)
    }
    return () => clearTimeout(timeoutActiveTabIdRef.current)
  }, [loading, openActiveTab])

  // reset filters when toco is created
  useEffect(() => {
    if (createdTocoId) {
      setPage(initialFilterStates.page)
      setMyToco(initialFilterStates.myToco)
      setQuery(initialFilterStates.query)
      setToQuery(initialFilterStates.toQuery)
      setReloadAfterCreatedDeleteTocWather((n) => n + 1)
      createdTocoIdRef.current = createdTocoId
    }
  }, [createdTocoId])

  //reload TOC list
  useEffect(() => {
    if (deleteTocWather) {
      setPage(initialFilterStates.page)
      setMyToco(initialFilterStates.myToco)
      setQuery(initialFilterStates.query)
      setToQuery(initialFilterStates.toQuery)
      setReloadAfterCreatedDeleteTocWather((n) => n + 1)
      setDeleteTocWather(false)
    }
  }, [deleteTocWather, setDeleteTocWather])

  // load toco list when filter is changed or toco is created
  useEffect(() => {
    loadToco().then((list) => {
      if (createdTocoIdRef.current) {
        const createdToco = ((list as unknown) as TocoInfoDto[])?.find(
          (toco) => toco.tocid.toString() === createdTocoIdRef.current?.toString(),
        )
        if (createdToco) {
          openOrSelectTabByToco(createdToco.tocid, createdToco.title)
        }
        createdTocoIdRef.current = undefined
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadToco, reloadAfterCreatedDeleteTocWather])

  const countRows = tocoList[0] ? parseInt(tocoList[0].totalcount, 10) : 0

  function CustomNoRowsOverlay() {
    return (
      <GridOverlay className={styles.noRows}>
        <svg width="120" height="100" viewBox="0 0 184 152" aria-hidden focusable="false">
          <g fill="none" fillRule="evenodd">
            <g transform="translate(24 31.67)">
              <ellipse fill="#f5f5f5" fillOpacity={0.8} cx="67.797" cy="106.89" rx="67.797" ry="12.668" />
              <path
                fill="#aeb8c2"
                d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
              />
              <path
                fill="#f5f5f7"
                d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
              />
              <path
                fill="#dce0e6"
                d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
              />
            </g>
            <path
              fill="#dce0e6"
              d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
            />
            <g fill="#fff" transform="translate(149.65 15.383)">
              <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
              <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
            </g>
          </g>
        </svg>
        <div className={styles.labelNoToC}>No ToCs</div>
      </GridOverlay>
    )
  }

  return (
    <Paper className={styles.tocoTable} variant="outlined" square>
      <Box className="search-box">
        <Box className="wrapper">
          <TextField
            id="toco-search"
            className="input"
            value={query}
            placeholder="Search by TOCs Title / Created user name"
            onChange={(e) => {
              setQuery(e.currentTarget.value)
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon color="action" />
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Box ml={2} display="flex" alignItems="center" className="radio">
          <Box mr={1}>Search by:</Box>
          <FormControl component="fieldset">
            <RadioGroup
              aria-label="gender"
              name="gender1"
              value={toQuery}
              onChange={(e) => {
                setToQuery(e.target.value)
              }}
              style={{ flexDirection: 'row' }}
            >
              <FormControlLabel value="title" control={<Radio color="primary" />} label="ToC Title" />
              <FormControlLabel value="createuserid" control={<Radio color="primary" />} label="Created User Name" />
            </RadioGroup>
          </FormControl>
        </Box>
      </Box>
      <Box className="controls w-100" display="flex">
        <IconButton
          aria-label="My TOCO"
          onClick={() => {
            setMyToco(true)
            setPage(1)
          }}
          color="primary"
        >
          <FolderIcon />
        </IconButton>
        {authData!.userRole === UserRoles.manager || authData!.userRole === UserRoles.admin ? (
          <IconButton
            aria-label="Shared TOCO"
            onClick={() => {
              setMyToco(false)
              setPage(1)
            }}
          >
            <FolderSharedIcon />
          </IconButton>
        ) : null}
        <Box ml="auto">
          <IconButton aria-label="Refresh" onClick={loadToco}>
            <RefreshIcon color="primary" />
          </IconButton>
        </Box>
      </Box>
      <Box className="grid">
        <DataGrid
          hideFooterSelectedRowCount
          hideFooterRowCount={true}
          rows={reformattedData}
          columns={columns}
          pagination
          pageSize={100}
          rowCount={query ? 0 : countRows}
          paginationMode="server"
          loading={loading}
          onSelectionChange={onSelectionChange}
          onPageChange={onPageChange}
          components={{
            noRowsOverlay: CustomNoRowsOverlay,
          }}
        />
      </Box>
    </Paper>
  )
})

const CellToolTip = memo(({ title }: { title: string }) => (
  <Tooltip title={title}>
    <span className="table-cell-truncate">{title}</span>
  </Tooltip>
))
