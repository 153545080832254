import { useMemo } from 'react'
import { useDashboardPageContext } from '../../../../../pages/layout/pages/dashboard/context/use-dashboard-page-context'
import {
  findGuide,
  HORIZONTAL_GUIDE_NODE_ID,
  createHorizontalGuideNode,
  VERTICAL_GUIDE_NODE_ID,
  createVerticalGuideNode,
} from '../../../hooks/add-shape/create-special-nodes/guides'
import { useCalcNewNodePosition } from '../../../hooks/add-shape/use-calc-new-node-position'

export function ShowHideAddAccountabilityCeiling() {
  const { scheme, updateScheme, schemeInfo } = useDashboardPageContext()
  const calcNewShapePosition = useCalcNewNodePosition()
  const checkBoxesConfig = useMemo(() => {
    const horizontal = findGuide(scheme, 'horizontal')
    const vertical = findGuide(scheme, 'vertical')
    const addOrRemoveHorizontal = () => {
      if (horizontal) {
        updateScheme((prevScheme) => prevScheme.filter((el) => el.id !== HORIZONTAL_GUIDE_NODE_ID))
      } else {
        const newNodePosition = calcNewShapePosition(2)
        const newNode = createHorizontalGuideNode(newNodePosition.y, schemeInfo!.canvasSize)
        updateScheme((prevScheme) => [newNode, ...prevScheme])
      }
    }
    const addOrRemoveVertical = () => {
      if (vertical) {
        updateScheme((prevScheme) => prevScheme.filter((el) => el.id !== VERTICAL_GUIDE_NODE_ID))
      } else {
        const newNodePosition = calcNewShapePosition(schemeInfo!.canvasSize.height!)
        const newNode = createVerticalGuideNode(newNodePosition.x, schemeInfo!.canvasSize)
        updateScheme((prevScheme) => [newNode, ...prevScheme])
      }
    }
    return {
      horizontal,
      vertical,
      addOrRemoveHorizontal,
      addOrRemoveVertical,
    }
    // performance optimisation
    // eslint-disable-next-line
  }, [calcNewShapePosition, scheme?.length, schemeInfo?.canvasSize, updateScheme])
  return checkBoxesConfig
}
