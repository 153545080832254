import React, { useEffect, useRef } from 'react'
import ReactQuill, { Quill } from 'react-quill'
import styles from './index.module.scss'

const Font = Quill.import('formats/font')
Font.whitelist = [
  'Montserrat',
  'Roboto',
  'Lato',
  'CourierPrime',
  'Lora',
  'FiraSans',
  'Merriweather',
  'PlayfairDisplay',
  'ArchitectsDaughter',
  'Raleway',
]
Quill.register(Font, true)

const modules = {
  toolbar: [
    ['bold', 'italic', 'underline'],
    [{ color: [] }, { background: [] }],
    [{ align: [] }],
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ font: Font.whitelist }],
    [{ size: [] }],
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
}

interface QuillEditorProps {
  value: string
  onChange: (text: string) => void
  placeholder?: string
}

export const QuillEditor: React.FC<QuillEditorProps> = (props) => {
  const inputEl = useRef<any>()
  useEffect(() => {
    inputEl.current.focus()
  }, [])
  return (
    <ReactQuill
      ref={inputEl}
      className={styles.reactQuill}
      theme="snow"
      modules={modules}
      value={props.value}
      onChange={props.onChange}
      placeholder={props.placeholder}
    />
  )
}
