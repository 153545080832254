import React, { memo } from 'react'
import { MARKER_ID, EDGE_COLOR } from '../constants'

export const MarkerDefinitions = memo(() => {
  return (
    <svg width={0} height={0}>
      <defs>
        <marker id={MARKER_ID} markerWidth={9} markerHeight={9} viewBox="-10 -10 20 20" orient="auto" refX="0" refY="0">
          <polyline
            stroke={EDGE_COLOR}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            fill={EDGE_COLOR}
            points="-8,-4 0,0 -8,4 -8,-4"
          />
        </marker>
      </defs>
    </svg>
  )
})
