import React, {
  createContext,
  PropsWithChildren,
  ReducerWithoutAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useList, useToggle } from 'react-use'
import { Scheme, SchemeInfo } from '../../../../../components/diagram/scheme/interfaces'
import { useContainerNodeMiddleware } from '../../../../../components/diagram/utils/hooks/use-container-node-middleware'
import { useRemoteDataCallback } from '../../../../../hooks/use-remote-data'
import { api } from '../../../../../api'
import { useAuthContext } from '../../../../../components/context/use-auth-context'
import { useElementUtils } from '../../../../../components/diagram/utils/hooks/use-element-utils'
import { ReactFlowProps, useStoreActions } from 'react-flow-renderer'
import { useSchemeProcessor } from '../../../../../components/diagram/utils/hooks/use-scheme-processor'
import { XmlTocoScheme } from '../scheme-parser/interface'
import { useParseLoadedToco } from '../../../../../hooks/use-parse-loaded-toco'

type DashboardContext = ReturnType<typeof useProvideDashboardPage>

const Context = createContext<DashboardContext>({} as DashboardContext)

export function DashboardPageContextProvider({ children }: PropsWithChildren<{}>) {
  const dashboard = useProvideDashboardPage()
  return <Context.Provider value={dashboard}>{children}</Context.Provider>
}

export function useDashboardPageContext() {
  return useContext(Context)
}

function useProvideDashboardPage() {
  const {
    tabs,
    activeTab,
    openOrSelectTabByToco,
    selectTabById,
    updateScheme,
    closeTab,
    updateElement,
    findElement,
    updateSchemeInfo,
  } = useTabs()
  const schemeProcessor = useSchemeProcessor()
  const scheme = useMemo(() => {
    return activeTab?.scheme && schemeProcessor(activeTab?.scheme)
  }, [activeTab?.scheme, schemeProcessor])
  const schemeInfo = useMemo(() => activeTab?.schemeInfo, [activeTab?.schemeInfo])
  const xmlTocoScheme = useMemo(() => activeTab?.xmlTocoScheme, [activeTab?.xmlTocoScheme])
  const { onSelectionChange } = useContainerNodeMiddleware()
  const [autoSaveError, setAutoSaveError] = useState(false)
  const [isOpenLeft, setIsOpenLeft] = useToggle(true)
  const [defaultPosition, setDefaultPosition] = useState<ReactFlowProps['defaultPosition']>(undefined)

  return useMemo(
    () => ({
      tabs,
      openOrSelectTabByToco,
      selectTabById,
      activeTab,
      scheme,
      schemeInfo,
      xmlTocoScheme,
      updateSchemeInfo,
      onSelectionChange,
      updateScheme,
      updateElement,
      closeTab,
      findElement,
      autoSaveError,
      setAutoSaveError,
      isOpenLeft,
      setIsOpenLeft,
      defaultPosition,
      setDefaultPosition,
    }),
    [
      tabs,
      openOrSelectTabByToco,
      selectTabById,
      activeTab,
      scheme,
      schemeInfo,
      xmlTocoScheme,
      updateSchemeInfo,
      onSelectionChange,
      updateScheme,
      updateElement,
      closeTab,
      findElement,
      autoSaveError,
      setAutoSaveError,
      isOpenLeft,
      setIsOpenLeft,
      defaultPosition,
      setDefaultPosition,
    ],
  )
}

type Tab = {
  id: string
  title: string
  scheme?: Scheme
  isSchemeLoading?: boolean
  modified?: boolean
  schemeInfo?: SchemeInfo
  xmlTocoScheme?: XmlTocoScheme.RootObject
  // add next/prev array here
}

export const DASHBOARD_TAB_ID = 'dashboard'

function useTabs() {
  const [tabs, { push, updateAt, removeAt }] = useList<Tab>([
    {
      id: DASHBOARD_TAB_ID,
      title: 'Dashboard',
    },
  ])
  const [activeTabId, setActiveTabId] = useState<string>()
  const { authData } = useAuthContext()
  const userId = authData!.userId
  const [fetchToco] = useRemoteDataCallback(api.toco.fetchToco)
  const resetSelectedElements = useStoreActions((s) => s.resetSelectedElements) // hot fix

  const openOrSelectTabByToco = useCallback(
    (tocoId: string, title: string) => {
      const foundTab = tabs.find((tab) => tab.id === tocoId)
      if (!foundTab) {
        push({
          id: tocoId,
          isSchemeLoading: true,
          title,
        })
      }
      resetSelectedElements()
      setActiveTabId(tocoId)
    },
    [setActiveTabId, push, tabs, resetSelectedElements],
  )

  const selectTabById = useCallback(
    (tabId: string) => {
      resetSelectedElements()
      setActiveTabId(tabId)
    },
    [setActiveTabId, resetSelectedElements],
  )

  const activeTab = useMemo(() => {
    return activeTabId != null ? tabs.find((tab) => tab?.id === activeTabId) : undefined
  }, [activeTabId, tabs])

  const updateTabById = useCallback(
    (tabId: string | undefined, fn: ReducerWithoutAction<Tab>) => {
      const tabIndex = tabs.findIndex((tab) => tab.id === tabId)
      if (tabIndex === -1) {
        console.warn(`Tab with id ${tabId} was not found. Check updateTabById method. Tabs:`, tabs)
        return
      }
      updateAt(tabIndex, fn(tabs[tabIndex]))
    },
    [tabs, updateAt],
  )

  const updateTabByIdRef = useRef(updateTabById)
  useEffect(() => {
    updateTabByIdRef.current = updateTabById
  }, [updateTabById])

  const updateScheme = useCallback(
    (fn: ReducerWithoutAction<Scheme>) => {
      updateTabById(activeTab?.id, (prevTab) => ({
        ...activeTab!,
        scheme: fn(prevTab.scheme!),
      }))
    },
    [activeTab, updateTabById],
  )

  const updateSchemeInfo = useCallback(
    (fn: ReducerWithoutAction<SchemeInfo>) => {
      updateTabById(activeTab?.id, (prevTab) => ({
        ...activeTab!,
        schemeInfo: fn(prevTab.schemeInfo!),
      }))
    },
    [activeTab, updateTabById],
  )

  const { updateElement, findElement } = useElementUtils(updateScheme, activeTab?.scheme!)
  const closeTab = useCallback(
    (tabId: string) => {
      const indexToRemove = tabs.findIndex((tab) => tab.id === tabId)
      if (tabId === activeTabId) {
        if (tabs.length === 1) {
          setActiveTabId(undefined)
        } else {
          const nextActiveTabIndex = indexToRemove === 0 ? 1 : indexToRemove - 1
          setActiveTabId(tabs[nextActiveTabIndex].id)
        }
      }
      removeAt(indexToRemove)
    },
    [removeAt, tabs, setActiveTabId, activeTabId],
  )
  const parseLoadedToco = useParseLoadedToco()
  useEffect(() => {
    if (activeTab && !activeTab.scheme && activeTab.id !== DASHBOARD_TAB_ID) {
      const fetchPromise = fetchToco({
        tocoId: activeTab.id,
        userId,
      })
      const closeCurrentTab = () => closeTab(activeTab.id)
      parseLoadedToco(fetchPromise, activeTab.id, closeCurrentTab).then(({ scheme, schemeInfo, xmlTocoScheme }) => {
        if (scheme) {
          // we need ref here to update tab by correct index, f.e. if user removes other tab when current is loading
          updateTabByIdRef.current(activeTab?.id, (prevTab) => ({
            ...prevTab,
            scheme,
            schemeInfo,
            xmlTocoScheme,
            isSchemeLoading: false,
          }))
        }
      })
    }
    // eslint-disable-next-line
  }, [activeTab])

  return {
    tabs,
    openOrSelectTabByToco,
    selectTabById,
    activeTab,
    updateScheme,
    updateSchemeInfo,
    closeTab,
    updateElement,
    findElement,
  }
}
