import { Scheme } from '../../../../../../components/diagram/scheme/interfaces'
import { isCommentNode } from '../../../../../../components/diagram/utils/check-node-type'
import { XmlTocoScheme } from '../interface'
import { encodeHtml } from '../utils/decode-html'
import { toArray } from '../utils/to-array-and-map'
import { CommentNodeElement } from '../../../../../../components/diagram/shapes/comment'
import { XmlCommentOwner } from '../xml-enums'

export function convertCommentsToXml(scheme: Scheme, xmlTocoScheme: XmlTocoScheme.RootObject, userId: string) {
  const comments: CommentNodeElement[] = scheme.filter(isCommentNode) as any
  return `<Comments>
    ${comments.map((comment, index) => convertComment(comment, xmlTocoScheme, index + 1, userId)).join('\n')}
      </Comments>`
}

function convertComment(
  comment: CommentNodeElement,
  xmlTocoScheme: XmlTocoScheme.RootObject,
  id: number,
  userId: string,
) {
  let prevCommentXml
  if (comment.data.commentId) {
    prevCommentXml = toArray(xmlTocoScheme?.Toc?.Content?.CanvasContent?.Comments?.Comment).find(
      (prev) => prev.CommentId === comment.data.commentId,
    )
  }
  let isActKnowledgeComment = prevCommentXml?.IsActKnowledgeComment
  if (isActKnowledgeComment === undefined) {
    isActKnowledgeComment =
      xmlTocoScheme.Toc.CreateUserId === userId ? XmlCommentOwner.user : XmlCommentOwner.actKnowledge
  }
  return `         <Comment>
          <CommentId>${id}</CommentId>
          <Text>${encodeHtml(comment.data.text)}</Text>
          <Creator>${prevCommentXml?.Creator || userId}</Creator>
          <IsActKnowledgeComment>${isActKnowledgeComment}</IsActKnowledgeComment>
          <x>${comment.position.x}</x>
          <y>${comment.position.y}</y>
          <Width>${comment.style?.width}</Width>
          <Height>${comment.style?.height}</Height>
        </Comment>`
}
