import React, { memo, PropsWithChildren, useCallback, useMemo } from 'react'
import { ResizableBox, ResizableProps } from 'react-resizable'
import './react-resizable.css'
import styles from './index.module.scss'
import clsx from 'clsx'
import { useInnerSchemeAccessContext } from '../context/use-inner-scheme-access'
import { useDraggableDisabler } from '../utils/hooks/use-draggable-disabler'

type Props = {
  nodeId: string
  className?: string
}

export const ResizableShapeWrapper = memo(({ children, nodeId, className }: PropsWithChildren<Props>) => {
  const { scheme, updateElement } = useInnerSchemeAccessContext()
  const { allowDrag, denyDrag } = useDraggableDisabler(updateElement, nodeId)

  const initialSize = useMemo(() => {
    const size = scheme?.find((node) => node.id === nodeId)?.style
    return {
      width: (size?.width as number) || 0,
      height: (size?.height as number) || 0,
    }
    // eslint-disable-next-line
  }, [nodeId])

  const onResize: ResizableProps['onResize'] = useCallback(
    (_, { size }) => {
      updateElement(nodeId, (el) => ({
        ...el,
        style: {
          ...el.style,
          width: (size?.width as number) || 0,
          height: (size?.height as number) || 0,
        },
      }))
    },
    [nodeId, updateElement],
  )

  const handle = useCallback(
    () => (
      <span
        className={clsx('react-resizable-handle react-resizable-handle-se', className)}
        onMouseEnter={denyDrag}
        onMouseLeave={allowDrag}
      />
    ),
    [denyDrag, allowDrag, className],
  )
  return (
    <ResizableBox
      className={clsx(styles.resizableBox, 'indicatorsWrapper')}
      width={initialSize.width}
      height={initialSize.height}
      onResize={onResize}
      handle={handle}
    >
      {children}
    </ResizableBox>
  )
})
