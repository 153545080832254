import { IconButton } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import React, { ReactNode, useCallback, useEffect, useState } from 'react'
import { usePrevious } from 'react-use'
import styles from './index.module.scss'

type Props = {
  elements: any[]
  renderContent: (activeIndex: number) => ReactNode
  onAddElement: () => void
  onRemoveElement: (activeIndex: number) => void
  resetKey?: string
}

export function ElementList({ renderContent, onRemoveElement, onAddElement, elements, resetKey }: Props) {
  const prevCount = usePrevious(elements.length)
  const [activeIndex, setActiveIndex] = useState(0)
  useEffect(() => {
    if (elements.length > (prevCount || 0)) {
      setActiveIndex(elements.length - 1)
    }
  }, [elements.length, prevCount])

  useEffect(() => {
    setActiveIndex(0)
  }, [resetKey])

  const onRemove = useCallback(() => {
    onRemoveElement(activeIndex)
    if (elements.length - 1 === activeIndex) {
      setActiveIndex(activeIndex - 1)
    }
  }, [activeIndex, elements.length, onRemoveElement])

  return (
    <div className={styles.wrapper}>
      <nav>
        <ul>
          {elements.map((_, i) => (
            <li key={i} onClick={() => setActiveIndex(i)} className={i === activeIndex ? styles.active : ''}>
              {i + 1}
            </li>
          ))}
          <li onClick={onAddElement}>+</li>
        </ul>
      </nav>

      {elements[activeIndex] !== undefined && (
        <section>
          {renderContent(activeIndex)}
          <IconButton aria-label="delete" onClick={onRemove} className={styles.deleteIcon}>
            <DeleteIcon fontSize="inherit" />
          </IconButton>
        </section>
      )}
    </div>
  )
}
