import { useCallback } from 'react'
import { useAddNode } from './use-add-node'
import { CommentNodeElement } from '../../../diagram/shapes/comment'
import { NodeSchemeType } from '../../../diagram/scheme/enums'
import { useAuthContext } from '../../../context/use-auth-context'

const NEW_SHAPE_WIDTH = 150
const NEW_SHAPE_HEIGHT = 130

export function useAddCommentNode() {
  const addShape = useAddNode()
  const { authData } = useAuthContext()
  return useCallback(
    (creatorId: string) =>
      addShape<CommentNodeElement>({
        type: NodeSchemeType.comment,
        data: {
          creator: authData!.userId,
          text: '',
          isActKnowledgeComment: creatorId !== authData!.userId,
        },
        style: { width: NEW_SHAPE_WIDTH, height: NEW_SHAPE_HEIGHT },
      }),
    [addShape, authData],
  )
}
