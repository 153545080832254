import {
  CanvasSize,
  OutcomeNodeElement,
  Scheme,
  SchemeInfo,
} from '../../../../../../components/diagram/scheme/interfaces'
import { parse } from 'fast-xml-parser'
import { XmlTocoScheme } from '../interface'
import { parseOutcomeNode } from './nodes'
import { parseEdge } from './edges'
import { toArray, toArrayAndMap } from '../utils/to-array-and-map'
import { tryDecodeExtraParam } from '../utils/extra-params'
import { SchemeExtraParams } from '../utils/extra-params/scheme-extra-params'
import { parseColorLegend } from './color-legend'
import { createGuideNodes } from './accountability-ceiling'
import { parseTextBoxOrCallout } from './text-box'
import { parseComment } from './comment'
import { XmlCommentsVisibility } from '../xml-enums'
import { createColorLegendNode } from '../../../../../../components/toolbar/hooks/add-shape/create-special-nodes/color-legend'

export class ParseError extends Error {}

export class AccessError extends Error {}

type ParseResult = {
  schemeInfo: SchemeInfo
  scheme: Scheme
  xmlTocoScheme: XmlTocoScheme.RootObject
}

export function parseScheme(xlm: string): ParseResult {
  let xmlTocoScheme: XmlTocoScheme.RootObject
  try {
    xmlTocoScheme = parse(xlm)
  } catch (e) {
    console.error(e)
    throw new ParseError()
  }
  const error = ((xmlTocoScheme as unknown) as XmlTocoScheme.RootErrorObject)?.TocAccess?.Message
  if (error) {
    const alreadyOpenedBy = error.replace('This TOC file is already opened by ', '').slice(0, -1)
    throw new AccessError(alreadyOpenedBy)
  }

  const schemeExtraParam = tryDecodeExtraParam<SchemeExtraParams>(xmlTocoScheme.Toc.Content.CanvasTitle.FontFamily)
  const xmlOutcomes = xmlTocoScheme.Toc.Content.CanvasContent.Outcomes.Outcome
  const outcomes: OutcomeNodeElement[] = toArrayAndMap(xmlOutcomes, parseOutcomeNode)
  const xmlConnectors = xmlTocoScheme.Toc.Content.CanvasContent.Connectors.Connector
  const edges = toArray(xmlConnectors).map((connector, i) => parseEdge(connector, i, schemeExtraParam, outcomes))
  const canvasSize: CanvasSize = {
    height: xmlTocoScheme.Toc.TocHeight,
    width: xmlTocoScheme.Toc.TocWidth,
  }
  const guides = createGuideNodes(xmlTocoScheme, canvasSize)
  const schemeInfo: SchemeInfo = {
    narrative: xmlTocoScheme.Toc.Content.Narrative,
    underlyingAssumptions: toArrayAndMap(
      xmlTocoScheme.Toc.Content.UnderlyingAssumptions?.UnderlyingAssumption,
      (e) => e,
    ),
    isBgGridShown: true,
    id: xmlTocoScheme.Toc.TocId,
    canvasSize,
    areCommentsVisible: xmlTocoScheme.Toc.Content.CanvasContent.CommentVisibility === XmlCommentsVisibility.visible,
    creatorId: xmlTocoScheme.Toc.CreateUserId,
    isSavedByUser: xmlTocoScheme.Toc.IsSavedByUser,
    title: xmlTocoScheme.Toc.Title,
    status: xmlTocoScheme.Toc.Status,
    shared: xmlTocoScheme.Toc.Shared,
    x: xmlTocoScheme.Toc.x,
    y: xmlTocoScheme.Toc.y,
    zoom: xmlTocoScheme.Toc.zoom,
  }
  const colorLegend = parseColorLegend(xmlTocoScheme)
  const colorLegendNodes = createColorLegendNode(colorLegend)
  const comments = toArrayAndMap(xmlTocoScheme.Toc.Content.CanvasContent.Comments.Comment, parseComment)
  const textBoxesAndCallouts = toArray(xmlTocoScheme.Toc.Content.CanvasContent.Annotations.Annotation).map(
    parseTextBoxOrCallout,
  )
  return {
    scheme: [...outcomes, ...edges, ...guides, ...colorLegendNodes, ...comments, ...textBoxesAndCallouts],
    schemeInfo,
    xmlTocoScheme,
  }
}
