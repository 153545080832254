import { useCallback } from 'react'
import { useStoreState, XYPosition } from 'react-flow-renderer'
import { useEditorContext } from '../../../editor/context/editor-context-provider'

const FRACTION = 2.3

export function useCalcNewNodePosition() {
  const { newXyPosition, currentDefaultPosition } = useEditorContext()
  const nodesHeight = useStoreState((state) => state.height)
  const nodesWidth = useStoreState((state) => state.width)
  const nodesTransform = useStoreState((state) => state.transform)
  return useCallback(
    (shapeHeight: number): XYPosition => ({
      x:
        newXyPosition !== undefined
          ? (nodesWidth / FRACTION - newXyPosition!.x) / nodesTransform[2]
          : (nodesWidth / FRACTION - currentDefaultPosition![0]) / nodesTransform[2],
      y:
        newXyPosition !== undefined
          ? (nodesHeight / FRACTION - newXyPosition!.y - shapeHeight) / nodesTransform[2]
          : (nodesHeight / FRACTION - currentDefaultPosition![1] - shapeHeight) / nodesTransform[2],
    }),
    [newXyPosition, nodesWidth, nodesTransform, currentDefaultPosition, nodesHeight],
  )
}
