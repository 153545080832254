import React, { PropsWithChildren } from 'react'
import styles from './index.module.scss'
import clsx from 'clsx'
import { useOutsideClick } from '../../../../hooks/use-outside-click'
import { useEditorContext } from '../../context/editor-context-provider'

export function EditorLayout({ children }: PropsWithChildren<{}>) {
  const { closeEditor } = useEditorContext()
  const ref = useOutsideClick(closeEditor)
  return (
    <div ref={ref} className={clsx(styles.editorLayout, 'br')}>
      {children}
    </div>
  )
}
