import { useCallback } from 'react'
import { EdgeElement } from '../../../diagram/scheme/interfaces'
import { useEditorContext } from '../../context/editor-context-provider'

export function useUpdateActiveEdgeData() {
  const { activeElement, updateElement } = useEditorContext()
  return useCallback(
    (updater: (prevData: EdgeElement['data']) => Partial<EdgeElement['data']>) => {
      updateElement<EdgeElement>(activeElement?.id!, (prevEdge) => ({
        ...prevEdge,
        data: {
          ...prevEdge.data,
          ...updater(prevEdge.data),
        },
      }))
    },
    [activeElement?.id, updateElement],
  )
}
