import { NodeSchemeType } from '../../../../../../components/diagram/scheme/enums'
import { NodeElement, Scheme } from '../../../../../../components/diagram/scheme/interfaces'
import { CallOutNodeElement } from '../../../../../../components/diagram/shapes/call-out'
import { TextBoxNodeElement } from '../../../../../../components/diagram/shapes/text-box'
import { isCallOutNode, isTextBoxNode } from '../../../../../../components/diagram/utils/check-node-type'
import { XmlTocoScheme } from '../interface'
import { convertRgbaColorToNumber } from '../utils/color'
import { encodeHtml } from '../utils/decode-html'
import { encodeExtraParams } from '../utils/extra-params'
import { toArray } from '../utils/to-array-and-map'

export function convertTextBoxToXml(scheme: Scheme, xmlTocoScheme: XmlTocoScheme.RootObject) {
  const textBoxes: TextBoxNodeElement[] = scheme.filter(isTextBoxNode)
  const callOuts: CallOutNodeElement[] = scheme.filter(isCallOutNode)
  return `<Annotations>
    ${textBoxes.map((textBox, index) => convertAnnotation(textBox, xmlTocoScheme, index + 1)).join('\n')}
    ${callOuts
      .map((callOut, index) => convertAnnotation(callOut, xmlTocoScheme, index + textBoxes.length + 1))
      .join('\n')}
      </Annotations>`
}

function convertAnnotation(
  annotation: TextBoxNodeElement | CallOutNodeElement,
  xmlTocoScheme: XmlTocoScheme.RootObject,
  id: number,
) {
  const colorInfo = convertRgbaColorToNumber(annotation.data.color!)
  let prevAnnotationXml
  if (annotation.data.annotationId) {
    prevAnnotationXml = toArray(xmlTocoScheme?.Toc?.Content?.CanvasContent?.Annotations?.Annotation).find(
      (prev) => prev.AnnotationId === annotation.data.annotationId,
    )
  }
  return `         <Annotation>
          <AnnotationId>${id}</AnnotationId>
          <Text>${encodeHtml(annotation.data.text)}</Text>
          <x>${annotation.position.x}</x>
          <y>${annotation.position.y}</y>
          <Width>${annotation.style?.width}</Width>
          <Height>${annotation.style?.height}</Height>
          <BorderColor>${prevAnnotationXml?.BorderColor || 0}</BorderColor>
          <BackgroundColor>${colorInfo?.numberColor}</BackgroundColor>
          <textFont>${prevAnnotationXml?.textFont || 'Arial'}</textFont>
          <textSize>${prevAnnotationXml?.textSize || 12}</textSize>
          <textColor>${prevAnnotationXml?.textColor || 0}</textColor>
          <textStyle>${encodeExtraParams({ opacity: colorInfo?.transparency })}</textStyle>
          <textWeight>${prevAnnotationXml?.textWeight || 'normal'}</textWeight>
          <Type>${convertNodeType(annotation)}</Type>
          <TailX>${prevAnnotationXml?.TailX || 60}</TailX>
          <TailY>${prevAnnotationXml?.TailY || 120}</TailY>
          <Depth>${prevAnnotationXml?.Depth || 'NaN'}</Depth>
        </Annotation>`
}

function convertNodeType({ type }: NodeElement): XmlTocoScheme.Annotation['Type'] {
  switch (type) {
    case NodeSchemeType.callOut:
      return 'call-out'
    default:
      return ''
  }
}
