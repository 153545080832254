import { Box } from '@material-ui/core'
import React, { memo } from 'react'
import styles from './indicator-box.module.scss'
import clsx from 'clsx'
import WarningRoundedIcon from '@material-ui/icons/WarningRounded'
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded'
import ErrorRoundedIcon from '@material-ui/icons/ErrorRounded'
import { green, yellow } from '@material-ui/core/colors'
import { Indicator } from '../../../dto'

type Props = {
  indicators?: Indicator[]
}

export const IndicatorBox = memo(({ indicators }: Props) => {
  return (
    <Box className={clsx(styles.indicatorBox, styles.dBlock)}>
      <Box className="container">
        <Box component="h4" className="title">
          Indicator
        </Box>
        <Box component="ul" className="list">
          {indicators?.map((indicator, i) => (
            <Box component="li" key={i}>
              <Box component="span" className="text">
                {indicator.whatWillChange}
              </Box>
              <Box component="span" className="status">
                {indicator.status === 'needsAttention' && <ErrorRoundedIcon color="error" fontSize="small" />}
                {indicator.status === 'achieved' && (
                  <CheckCircleRoundedIcon style={{ color: green[500] }} fontSize="small" />
                )}
                {indicator.status === 'inProgress' && (
                  <WarningRoundedIcon style={{ color: yellow[500] }} fontSize="small" />
                )}
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  )
})
