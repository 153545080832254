import React, { PropsWithChildren } from 'react'
import { ModalContextProvider, useModalContext } from './context/use-modal-context'

export function ModalWrapper({ children }: PropsWithChildren<{}>) {
  return (
    <ModalContextProvider>
      {children}
      <ModalContent />
    </ModalContextProvider>
  )
}

function ModalContent() {
  const { modalContent } = useModalContext()
  return <>{modalContent}</>
}
