import { useCallback } from 'react'
import { useDashboardPageContext } from '../../../../pages/layout/pages/dashboard/context/use-dashboard-page-context'
import { NodeElement } from '../../../diagram/scheme/interfaces'
import { useCalcNewNodePosition } from './use-calc-new-node-position'
import { getTimeStamp } from '../../../../pages/layout/pages/dashboard/scheme-parser/utils/date'

export function useAddNode() {
  const { updateScheme } = useDashboardPageContext()
  const calcNewShapePosition = useCalcNewNodePosition()
  return useCallback(
    <T extends NodeElement>(node: Pick<Required<T>, 'type' | 'data' | 'style'>) => {
      const id = getTimeStamp().toString()
      const newNode: NodeElement = {
        id,
        type: node.type,
        data: node.data,
        style: node.style,
        position: calcNewShapePosition(node?.style?.height as number),
      } as NodeElement

      updateScheme((els) => els.concat(newNode))
    },
    [calcNewShapePosition, updateScheme],
  )
}
