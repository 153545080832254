import { EdgeSchemeType, NodeSchemeType } from './enums'
import { EdgeTypesType, NodeTypesType } from 'react-flow-renderer'
import { TriangleNode } from '../shapes/outcome/triangle-node'
import { RectangleNode, RoundedRectangleNode } from '../shapes/outcome/rectangle-node'
import { PentagonNode } from '../shapes/outcome/pentagon-node'
import { StepEdgeIntervention } from '../edges/step-edge-intervention'
import { SmoothStepEdgeIntervention } from '../edges/smooth-step-edge-intervention'
import { BezierEdgeIntervention } from '../edges/bezier-edge-intervention'
import { StraightEdgeIntervention } from '../edges/straight-edge-intervention'
import { HexagonNode } from '../shapes/outcome/hexagon-node'
import { OctagonNode } from '../shapes/outcome/octagon-node'
import { EllipseNode } from '../shapes/outcome/ellipse-node'
import { AccountabilityCeilingNode } from '../shapes/accountability-ceiling'
import { ColorLegendNode } from '../shapes/color-legend'
import { CommentNode } from '../shapes/comment'
import { TextBoxNode } from '../shapes/text-box'
import { CallOutNode } from '../shapes/call-out'

export const nodesDefinition: NodeTypesType = {
  [NodeSchemeType.triangle]: TriangleNode,
  [NodeSchemeType.rectangle]: RectangleNode,
  [NodeSchemeType.roundedRectangle]: RoundedRectangleNode,
  [NodeSchemeType.pentagon]: PentagonNode,
  [NodeSchemeType.hexagon]: HexagonNode,
  [NodeSchemeType.octagon]: OctagonNode,
  [NodeSchemeType.ellipse]: EllipseNode,
  [NodeSchemeType.accountabilityCeiling]: AccountabilityCeilingNode,
  [NodeSchemeType.colorLegend]: ColorLegendNode,
  [NodeSchemeType.comment]: CommentNode,
  [NodeSchemeType.textBox]: TextBoxNode,
  [NodeSchemeType.callOut]: CallOutNode,
}

export const edgesDefinition: EdgeTypesType = {
  [EdgeSchemeType.straightEdgeIntervention]: StraightEdgeIntervention,
  [EdgeSchemeType.bezierEdgeIntervention]: BezierEdgeIntervention,
  [EdgeSchemeType.stepEdgeIntervention]: StepEdgeIntervention,
  [EdgeSchemeType.smoothStepEdgeIntervention]: SmoothStepEdgeIntervention,
}
