import React, { memo } from 'react'
import { OutcomeNodeElementWithType, OutcomeNodeProps } from '../../../scheme/interfaces'
import { NodeSchemeType } from '../../../scheme/enums'
import styles from './index.module.scss'
import { BaseShape } from '../base-shape'

export type OctagonElement = OutcomeNodeElementWithType<NodeSchemeType.octagon>

export const OctagonNode = memo((outcomeProps: OutcomeNodeProps) => {
  return <BaseShape themeClassNames={styles as any} {...outcomeProps} />
})
