import roundedRectangle from './images/roundedRectangle.svg'
import rectangle from './images/rectangle.svg'
import pentagon from './images/pentagon.svg'
import hexagon from './images/hexagon.svg'
import octagon from './images/octagon.svg'
import triangle from './images/triangle.svg'
import ellipse from './images/ellipse.svg'
import { OutcomeNodeElement } from '../diagram/scheme/interfaces'
import { NodeSchemeType } from '../diagram/scheme/enums'
import React, { CSSProperties } from 'react'

const imagesInfo: Record<OutcomeNodeElement['type'], { image: string; alt: string; style?: CSSProperties }> = {
  [NodeSchemeType.roundedRectangle]: { image: roundedRectangle, alt: 'roundedRectangle' },
  [NodeSchemeType.triangle]: { image: triangle, alt: 'triangle' },
  [NodeSchemeType.ellipse]: { image: ellipse, alt: 'ellipse' },
  [NodeSchemeType.rectangle]: { image: rectangle, alt: 'rectangle' },
  [NodeSchemeType.pentagon]: { image: pentagon, alt: 'pentagon' },
  [NodeSchemeType.hexagon]: { image: hexagon, alt: 'hexagon' },
  [NodeSchemeType.octagon]: { image: octagon, alt: 'octagon', style: { transform: 'rotate(23deg)' } },
}

export const outcomeShapePreviewOrder: OutcomeNodeElement['type'][] = [
  NodeSchemeType.roundedRectangle,
  NodeSchemeType.triangle,
  NodeSchemeType.ellipse,
  NodeSchemeType.rectangle,
  NodeSchemeType.pentagon,
  NodeSchemeType.hexagon,
  NodeSchemeType.octagon,
]

export function OutcomeShapePreview({ type }: { type: OutcomeNodeElement['type'] }) {
  const info = imagesInfo[type]
  return <img src={info.image} alt={info.alt} style={info.style} />
}
