import { Scheme } from '../../../../../../components/diagram/scheme/interfaces'
import { findGuide } from '../../../../../../components/toolbar/hooks/add-shape/create-special-nodes/guides'
import { XmlAccountabilityCeilingVisibility } from '../xml-enums'

export function convertAccountabilityCeiling(scheme: Scheme) {
  const horizontal = findGuide(scheme, 'horizontal')
  const vertical = findGuide(scheme, 'vertical')
  let visible = XmlAccountabilityCeilingVisibility.none
  if (horizontal && vertical) {
    visible = XmlAccountabilityCeilingVisibility.both
  } else if (horizontal) {
    visible = XmlAccountabilityCeilingVisibility.horizontal
  } else if (vertical) {
    visible = XmlAccountabilityCeilingVisibility.vertical
  }
  return `        <AccountabilityCeiling>
                <Visible>${visible}</Visible>
                <x>${vertical?.position.x || 0}</x>
                <y>${horizontal?.position.y || 0}</y>
            </AccountabilityCeiling>`
}
