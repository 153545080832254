import { Box, Button, Menu, MenuItem, MenuProps } from '@material-ui/core'
import React, { memo, useCallback, useState } from 'react'

export type BaseMenuProps = {
  list: {
    label: string
    handle: () => void
    disabled: boolean
  }[]
  title: string
}

export const BaseMenu = memo((props: BaseMenuProps) => {
  const [anchorEdit, setAnchorEdit] = useState<null | HTMLElement>(null)

  const openMenu = useCallback((event) => {
    setAnchorEdit(event.currentTarget)
  }, [])

  const closeMenu = useCallback(() => {
    setAnchorEdit(null)
  }, [])

  return (
    <>
      <Button onClick={openMenu}>
        <Box fontWeight="fontWeightBold" className="btnCapitalize">
          {props.title}
        </Box>
      </Button>
      <Menu
        {...menuProps}
        id="edit-menu"
        anchorOrigin={undefined}
        anchorEl={anchorEdit}
        open={Boolean(anchorEdit)}
        onClose={closeMenu}
      >
        {props.list.map((item, index) => (
          <MenuItem
            key={index}
            disabled={item.disabled}
            onClick={() => {
              item.handle()
              closeMenu()
            }}
          >
            {item.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
})

const menuProps: Partial<MenuProps> = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left',
  },
}
