import { useDashboardPageContext } from '../../../../pages/layout/pages/dashboard/context/use-dashboard-page-context'
import { isOutcomeNode } from '../../../diagram/utils/check-node-type'
import {
  convertNumberColorToRgba,
  convertRgbaColorToNumber,
} from '../../../../pages/layout/pages/dashboard/scheme-parser/utils/color'
import { useCallback } from 'react'
import { useCalcNewNodePosition } from './use-calc-new-node-position'
import { ColorLegend } from '../../../diagram/scheme/interfaces'
import { createColorLegendNode } from './create-special-nodes/color-legend'

export function useGenerateColorLegend() {
  const takeOutcomeColors = useTakeOutcomeColors()
  const calcNewShapePosition = useCalcNewNodePosition()
  const { updateScheme } = useDashboardPageContext()
  return useCallback(() => {
    const colors = takeOutcomeColors()
    const colorLegend: ColorLegend = {
      x: 0,
      y: 0,
      legendItems: colors.map((color) => ({
        color,
        text: '',
      })),
      isVisible: true,
    }
    const node = createColorLegendNode(colorLegend)[0]
    node.position = calcNewShapePosition(node.style?.height as number)

    updateScheme((els) => els.concat(node))
  }, [calcNewShapePosition, takeOutcomeColors, updateScheme])
}

export function useTakeOutcomeColors() {
  const { scheme } = useDashboardPageContext()
  return useCallback((): string[] => {
    if (!scheme) {
      return []
    }
    const outcomes = scheme.filter(isOutcomeNode)
    const outcomeColors = outcomes.map((outcome) =>
      convertNumberColorToRgba(convertRgbaColorToNumber(outcome.data.color).numberColor),
    )
    const uniqueColor = [...(new Set(outcomeColors) as any)]
    return uniqueColor
  }, [scheme])
}
