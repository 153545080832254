import React from 'react'
import { Box, Button } from '@material-ui/core'
import NoteAddIcon from '@material-ui/icons/NoteAdd'
import { CreateTocModal } from '../../../../components/modals/сreate-toc'
import { useModalContext } from '../../../../components/modal/context/use-modal-context'

export function CreateTocBtn() {
  const { showModal } = useModalContext()
  return (
    <>
      <Box m={1} display="Block" className="nav-btn">
        <Button variant="outlined" color="secondary" onClick={() => showModal(<CreateTocModal />)}>
          <NoteAddIcon />
        </Button>
        <Box fontWeight={500} fontSize={12}>
          Create New
        </Box>
      </Box>
    </>
  )
}
