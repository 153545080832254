import { useCallback, useMemo } from 'react'
import { api } from '../../../api'
import { useRemoteDataCallback } from '../../../hooks/use-remote-data'
import { useDashboardPageContext } from '../../../pages/layout/pages/dashboard/context/use-dashboard-page-context'
import { convertSchemeToXml } from '../../../pages/layout/pages/dashboard/scheme-parser/js-to-xml/convert-scheme-to-xml'
import { useAuthContext } from '../../context/use-auth-context'
import { useSnackbar } from 'notistack'

export function useSaveDiagram() {
  const [saveDiagramCb, isDiagramSaving] = useRemoteDataCallback(api.toco.postUpdateToco)
  const { scheme, schemeInfo, activeTab, xmlTocoScheme, setAutoSaveError } = useDashboardPageContext()
  const { authData } = useAuthContext()
  const savingDisabled = !(scheme && authData && activeTab && schemeInfo && xmlTocoScheme)
  const savingSnackbar = useSavingSnackbar()
  const saveDiagram = useCallback(
    (autoSaveHandler?: (promise: Promise<any>) => void) => {
      const xml = convertSchemeToXml({
        xmlTocoScheme: xmlTocoScheme!,
        scheme: scheme!,
        schemeInfo: schemeInfo!,
        userId: authData!.userId,
        tocoInfo: {
          id: activeTab!.id,
          title: activeTab!.title,
        },
      })
      const promise = saveDiagramCb({ xml, userId: authData!.userId, tocoId: activeTab!.id })
      const saveHandler = (promise: Promise<any>) =>
        promise
          .then(() => {
            savingSnackbar.notifySavingFulfilled()
            setAutoSaveError(false)
          })
          .catch(() => {
            savingSnackbar.notifySavingRejected()
          })
      if (!savingDisabled) {
        savingSnackbar.notifySavingStarted()
        autoSaveHandler ? autoSaveHandler(promise) : saveHandler(promise)
      }
    },
    [
      xmlTocoScheme,
      scheme,
      schemeInfo,
      authData,
      activeTab,
      saveDiagramCb,
      savingDisabled,
      savingSnackbar,
      setAutoSaveError,
    ],
  )

  return { saveDiagram, isDiagramSaving, savingDisabled }
}

export function useSavingSnackbar() {
  const { enqueueSnackbar } = useSnackbar()
  return useMemo(
    () => ({
      notifySavingStarted: () => enqueueSnackbar('ToC is saving ...', { variant: 'info' }),
      notifyDeletingStarted: () => enqueueSnackbar('Deleting ToC ...', { variant: 'info' }),
      notifySavingFulfilled: () => enqueueSnackbar('ToC was successfully saved', { variant: 'success' }),
      notifyDeleteTOCSuccessfully: () => enqueueSnackbar('ToC was successfully deleted', { variant: 'error' }),
      notifyDeleteTOCRejected: () => enqueueSnackbar('Failed to delete ToC', { variant: 'error' }),
      notifySavingRejected: () => enqueueSnackbar('Failed to save ToC', { variant: 'error' }),
      notifySavingRejectedAutoSave: () =>
        enqueueSnackbar('Failed to automatically save the ToC. Please manually save the ToC later', {
          variant: 'error',
        }),
    }),
    [enqueueSnackbar],
  )
}
