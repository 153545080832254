import { decode, encode } from 'he'

export function decodeHtmlAndUpdateFont(xml: string) {
  return decodeHtml(updateFont(xml))
}

export function removeTagsAndDecodeHtml(xml: string) {
  return decodeHtml(removeTags(xml))
}

/**
 * Transform &lt;span&gt;_text_&lt;/span&gt; => _text_
 */
export function removeTags(xml: string) {
  return toString(xml)
    .replace(/&lt;[^&gt;]+&gt;/g, '')
    .replace(/<[^>]+>/g, '')
}

/**
 * update font size
 */
export function updateFont(xml: string) {
  const regex = /SIZE="\d+"/g
  return toString(xml).replaceAll(regex, `SIZE=""`)
}

/**
 * Transform &amp; => &
 * Transform &lt;span&gt; => <span>
 */
export function decodeHtml(xml: string) {
  return decode(toString(xml))
}

/**
 * Transform & => &amp;
 * Transform <span> => &lt;span&gt;
 */
export function encodeHtml(xml?: string) {
  return encode(toString(xml))
}

function toString(xml: string | number | undefined) {
  switch (typeof xml) {
    case 'string':
      return xml
    case 'number':
      return `${xml}`
    default:
      return ''
  }
}
