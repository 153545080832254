import React from 'react'
import { Box, IconButton } from '@material-ui/core'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import { Table } from '../table'
import clsx from 'clsx'
import styles from './left-side.module.scss'
import { useDashboardPageContext } from '../context/use-dashboard-page-context'

export const LeftSide = () => {
  const { isOpenLeft, setIsOpenLeft } = useDashboardPageContext()
  return (
    <Box
      className={clsx(
        {
          [styles.sideLeft]: isOpenLeft,
        },
        'br side',
        'no-print',
      )}
    >
      <IconButton color="inherit" name="ChevronLeft" onClick={setIsOpenLeft} edge="start" style={{ marginLeft: '0' }}>
        {isOpenLeft ? <ChevronLeftIcon color="primary" /> : <ChevronRightIcon color="primary" />}
      </IconButton>
      {isOpenLeft ? <Table /> : ''}
    </Box>
  )
}
