import { NodeSchemeType } from '../../../../diagram/scheme/enums'
import { ColorLegendNodeElement } from '../../../../diagram/shapes/color-legend'
import { ColorLegend } from '../../../../diagram/scheme/interfaces'

const COLOR_LEGEND_NODE_ID = 'color-legend'
const COLOR_LEGEND_WIDTH = 210
// Constants bellow describes css setting. If you want to change it, please change also css
const TITLE_HEIGHT = 26
const ROW_HEIGHT = 20
const ROW_MARGIN_BOTTOM = 4
const LIST_PADDING = 14

export function createColorLegendNode(colorLegend: ColorLegend): ColorLegendNodeElement[] {
  if (!colorLegend?.isVisible) {
    return []
  }

  /* Height depends on css styles: src/components/diagram/shapes/color-legend/styles.module.scss */
  const rowsCount = colorLegend.legendItems.length
  const height = TITLE_HEIGHT + ROW_HEIGHT * rowsCount + ROW_MARGIN_BOTTOM * (rowsCount - 1) + LIST_PADDING * 2
  return [
    {
      id: COLOR_LEGEND_NODE_ID,
      type: NodeSchemeType.colorLegend,
      position: { x: colorLegend.x, y: colorLegend.y },
      style: { width: COLOR_LEGEND_WIDTH, height },
      data: { legendItems: colorLegend.legendItems },
    },
  ]
}
