import { OutcomeNodeProps } from '../../../scheme/interfaces'
import React, { memo } from 'react'
import { useOutcomeNodeConfig } from '../../../utils/hooks/use-outcome-node-config'
import { ResizableShapeWrapper } from '../../../resizable-shape-wrapper'
import { ShapeTitle } from '../../../shape-title-content'
import { Handle, Position } from 'react-flow-renderer'
import { IndicatorsBar } from '../../../indicators-bar'
import './_mixins.scss'
import clsx from 'clsx'
import { Connection } from 'react-flow-renderer/dist/types'

export type ThemeClassNames = {
  resizableHandle: string
  box: string
  shadow: string
  shape: string
  content: string
  left: string
  right: string
  top: string
  bottom: string
  indicatorBar: string
  pickerPosition: string
}

type BaseShapeProps = OutcomeNodeProps & {
  themeClassNames: ThemeClassNames
}

export const BaseShape = memo(({ id, data, themeClassNames }: BaseShapeProps) => {
  const { style, openOutcomeEditor, setNodeId } = useOutcomeNodeConfig(data, id)

  return (
    <div onDoubleClick={openOutcomeEditor} onClick={setNodeId}>
      <ResizableShapeWrapper nodeId={id} className={themeClassNames.resizableHandle}>
        <>
          <div className={themeClassNames.box}>
            <div className={clsx(themeClassNames.shadow, 'selected')}>
              <div className={themeClassNames.shape} style={style} />
            </div>
            <div className={clsx(themeClassNames.content, 'ql-editor')}>
              <ShapeTitle data={data} />
            </div>
          </div>
          <Handle
            type="source"
            position={Position.Left}
            id={Position.Left}
            className={themeClassNames.left}
            isValidConnection={isValidConnection}
          />
          <Handle
            type="source"
            position={Position.Right}
            id={Position.Right}
            className={themeClassNames.right}
            isValidConnection={isValidConnection}
          />
          <Handle
            type="source"
            position={Position.Top}
            id={Position.Top}
            className={themeClassNames.top}
            isValidConnection={isValidConnection}
          />
          <Handle
            type="source"
            position={Position.Bottom}
            id={Position.Bottom}
            className={themeClassNames.bottom}
            isValidConnection={isValidConnection}
          />
          <IndicatorsBar
            nodeId={id}
            positionClassName={themeClassNames.indicatorBar}
            indicators={data.indicators}
            shapeColor={data.color}
            positionPickerClassName={themeClassNames.pickerPosition}
          />
        </>
      </ResizableShapeWrapper>
    </div>
  )
})

function isValidConnection(connection: Connection): boolean {
  return connection.source !== connection.target
}
