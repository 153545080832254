import React from 'react'
import { BaseMenu } from '../base-menu'
import { useSaveDiagram } from '../hooks/use-save-diagram'
import { CloneTocModal } from '../../modals/clone-toc'
import { useToggle } from 'react-use'
import { useModalContext } from '../../modal/context/use-modal-context'
import { CreateTocModal } from '../../modals/сreate-toc'
import { DeleteTocModal } from '../../modals/delete-toc'
import { downloadCsv } from '../icon-menu/download-csv'
import { useDashboardPageContext } from '../../../pages/layout/pages/dashboard/context/use-dashboard-page-context'

export const FileMenu = () => {
  const [isCloneModalOpen, toggleCloneModal] = useToggle(false) // todo provide common solution. Now Modal does not receive all contexts
  const [isDeleteTocModalOpen, toggleDeleteTocModal] = useToggle(false) // todo provide common solution. Now Modal does not receive all contexts
  const { saveDiagram, savingDisabled } = useSaveDiagram()
  const { showModal } = useModalContext()
  const { scheme, schemeInfo } = useDashboardPageContext()

  const fileLists = [
    { label: 'Save', handle: () => saveDiagram(), disabled: savingDisabled },
    { label: 'Save As', handle: () => toggleCloneModal(), disabled: savingDisabled },
    { label: 'Save to PDF', handle: () => window.open(`print/${schemeInfo?.id}`, '_blank'), disabled: !scheme },
    { label: 'Export to Excel', handle: () => downloadCsv(scheme!, schemeInfo!), disabled: !scheme },
    { label: 'Print', handle: () => window.open(`print/${schemeInfo?.id}`, '_blank'), disabled: !scheme },
    { label: 'Create New', handle: () => showModal(<CreateTocModal />), disabled: false },
    { label: 'Delete TOC', handle: () => toggleDeleteTocModal(), disabled: savingDisabled },
  ]
  return (
    <>
      <BaseMenu list={fileLists} title={'FILE'} />
      {isCloneModalOpen && <CloneTocModal hideModal={() => toggleCloneModal()} />}
      {isDeleteTocModalOpen && <DeleteTocModal hideModal={() => toggleDeleteTocModal()} />}
    </>
  )
}
