import { useCallback, useMemo, useRef } from 'react'
import { Elements, isNode, Node, useStoreActions, useStoreState } from 'react-flow-renderer'

interface Rect {
  top: number
  bottom: number
  left: number
  right: number
}

interface NodeInfo {
  node: Node
  rect: Rect
}

// selects all inside nodes on node select
export function useContainerNodeMiddleware() {
  const setSelectedElements = useStoreActions((s) => s.setSelectedElements)
  const storeElements = useStoreState((s) => s.elements)
  const skipNextUpdateRef = useRef(false) // skip handling caused by setSelectedElements call

  const onSelectionChange = useCallback(
    (selectedBaseInfo: Elements | null) => {
      if (skipNextUpdateRef.current) {
        skipNextUpdateRef.current = false
        return
      }
      if (selectedBaseInfo) {
        const selectedNodesBaseInfo = selectedBaseInfo.filter(isNode)
        if (!selectedNodesBaseInfo.length) {
          return
        }
        const nodesInfo = storeElements.filter(isNode).map<NodeInfo>((node) => ({
          node,
          rect: getNodeRect(node),
        }))
        const selectedNodesInfo = selectedNodesBaseInfo
          .map((selectedInfo) => nodesInfo.find((nodeInfo) => nodeInfo.node.id === selectedInfo.id))
          .filter((node) => node !== undefined) as NodeInfo[] // prevent rare bug

        // select inner nodes
        const autoSelected: Node[] = []
        selectedNodesInfo.forEach((selectedNodeInfo) => {
          const innerNodesInfo = getInnerNodes(selectedNodeInfo, nodesInfo)
          const notSelectedInnerNodes = innerNodesInfo.filter((innerNodeInfo) => {
            const alreadySelected = selectedNodesInfo.some(
              (selectedNodeInfo) => selectedNodeInfo.node.id === innerNodeInfo.node.id,
            )
            const alreadyAutoSelected = alreadySelected
              ? undefined
              : autoSelected.some((node) => node.id === innerNodeInfo.node.id)

            return !(alreadySelected || alreadyAutoSelected)
          })
          autoSelected.push(...notSelectedInnerNodes.map((n) => n.node))
        })
        if (autoSelected.length) {
          skipNextUpdateRef.current = true
          setSelectedElements([...autoSelected, ...selectedNodesInfo.map((info) => info.node)])
        }
      }
    },
    [storeElements, setSelectedElements],
  )

  return useMemo(
    () => ({
      onSelectionChange,
    }),
    [onSelectionChange],
  )
}

function getInnerNodes(container: NodeInfo, nodesInfo: NodeInfo[]) {
  return nodesInfo.filter((nodeInfo) => {
    if (nodeInfo?.node?.id === container?.node?.id) return false

    return (
      nodeInfo.rect.left > container.rect.left &&
      nodeInfo.rect.right < container.rect.right &&
      nodeInfo.rect.top > container.rect.top &&
      nodeInfo.rect.bottom < container.rect.bottom
    )
  })
}

function getNodeRect(node: Node) {
  if (!node.style?.height || !node.style?.width) {
    console.warn('Node must have specified height and width property in style. Check getNodeRect function', node)
  }
  return {
    top: node.__rf.position.y,
    bottom: node.__rf.position.y + (node.style?.height as number),
    left: node.__rf.position.x,
    right: node.__rf.position.x + (node.style?.width as number),
  }
}
