import { mainApiClient } from './_client'
import { stringify } from 'querystring'
import { utc } from 'moment'

export type TocoListRequestI = {
  pageno?: number
  username?: string
  dummy: boolean
  q?: string
  sortBy?: string
}

export type TocoListResponseI = TocoInfoDto[]

export interface TocoInfoDto {
  tocid: string
  title: string
  createuserid: string
  sharewith: string
  createtimestamp: string
  updatetimestamp: string
  status: string
  totalcount: string
  username: string
}

export function fetchList(params: TocoListRequestI) {
  return mainApiClient
    .get<string>(`api/v2/tocs?${stringify(addHashInParamsToPreventCashing(params))}`, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })
    .then(({ data }) => {
      // parse html to js object // Todo refactor later. It's just example
      const links = data.match(/<a[^>]*>([^<]+)<\/a>/g) || []
      const tocoLinks = links.filter((l) => l.includes('api/v2/tocs'))

      let tempWrapper = document.createElement('div')
      let objList = tocoLinks.map((link) => {
        tempWrapper.innerHTML = link
        const linkEl = tempWrapper.firstChild as HTMLAnchorElement
        let linkInfo: TocoInfoDto = {} as TocoInfoDto
        Object.values(linkEl.attributes).forEach((attr) => {
          if (!['class', 'href'].includes(attr.nodeName)) linkInfo[attr.nodeName as keyof TocoInfoDto] = attr.nodeValue!
        })
        return linkInfo
      })
      return objList
    })
}

export type TocoRequestI = {
  tocoId: string
  userId: string
}

export type TocoResponseI = string

export function fetchToco({ tocoId, userId }: TocoRequestI) {
  return mainApiClient
    .get<string>(`api/v2/tocs/${tocoId}/${userId}?${stringify(addHashInParamsToPreventCashing())}`, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })
    .then(({ data }) => data)
}

export type CreateTocoRequestI = {
  xml: string
}

export type CreateTocoResponseI = string

export function postCreateToco({ xml }: CreateTocoRequestI) {
  return mainApiClient
    .post<CreateTocoResponseI>(`api/v2/tocs`, xml, {
      headers: {
        'Content-Type': 'application/xml',
      },
    })
    .then((resp) => {
      return /\[ID=(.*?)\]/.exec(resp.data)?.[1]
    })
}

export type UpdateTocoRequestI = {
  xml: string
  tocoId: string
  userId: string
}

export function postUpdateToco({ xml, tocoId, userId }: UpdateTocoRequestI) {
  return mainApiClient.put(`api/v2/tocs/${tocoId}/${userId}`, xml, {
    headers: {
      'Content-Type': 'application/xml',
    },
  })
}

function addHashInParamsToPreventCashing<T extends {}>(params?: T) {
  const hash = utc().valueOf()
  return params ? { ...params, hash } : { hash }
}
