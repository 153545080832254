import React, { memo, useMemo } from 'react'
import { OpenEditorStates, useEditorContext } from '../../editor/context/editor-context-provider'
import { BaseMenu, BaseMenuProps } from '../base-menu'
import { useAddCommentNode } from '../hooks/add-shape/use-add-comment-node'
import { useGenerateColorLegend } from '../hooks/add-shape/use-generate-color-legend'
import { useDashboardPageContext } from '../../../pages/layout/pages/dashboard/context/use-dashboard-page-context'
import { isColorLegend } from '../../diagram/utils/check-node-type'
import { useAddTextBoxNode } from '../hooks/add-shape/use-add-text-box-node'
import { ShowHideAddAccountabilityCeiling } from '../canvas-box/accountability-ceiling/show-hide-accountability-ceiling'

export const EditMenu = memo(() => {
  const { openEditor, schemeInfo } = useEditorContext()
  const { scheme } = useDashboardPageContext()
  const addComment = useAddCommentNode()
  const addTextBox = useAddTextBoxNode()
  const regenerateColorLegend = useGenerateColorLegend()
  const hasColorLegend = useMemo(() => {
    return scheme ? scheme.some(isColorLegend) : false
    // eslint-disable-next-line
  }, [scheme?.length]) // small optimization

  const { addOrRemoveHorizontal, addOrRemoveVertical } = ShowHideAddAccountabilityCeiling()

  const editList: BaseMenuProps['list'] = useMemo(
    () => [
      {
        label: 'Add Comment',
        handle: () => addComment(schemeInfo!.creatorId),
        disabled: !schemeInfo,
      },
      {
        label: 'Add Text Box',
        handle: () => addTextBox(),
        disabled: !schemeInfo,
      },
      {
        label: 'Add Color Legend',
        handle: regenerateColorLegend,
        disabled: !schemeInfo || hasColorLegend,
      }, //adds the legend
      {
        label: 'Edit Narrative',
        handle: () => openEditor(OpenEditorStates.narrativeEditor, 0),
        disabled: !schemeInfo,
      }, //edit Narrative by opening narrative/assumption box
      {
        label: 'Edit Assumptions',
        handle: () => openEditor(OpenEditorStates.narrativeEditor, 1),
        disabled: !schemeInfo,
      }, //edit assumptions by opening narrative/assumption box
      {
        label: 'Show/Hide Comments',
        handle: () => {},
        disabled: true,
      }, //Comments that are added can be hidden or shown
      {
        label: 'Show/Hide horizontal accountability ceiling',
        handle: () => {
          addOrRemoveHorizontal()
        },
        disabled: !schemeInfo,
      }, //Same as adding horizontal accountability ceiling from main bar
      {
        label: 'Show/Hide vertical accountability ceiling',
        handle: () => {
          addOrRemoveVertical()
        },
        disabled: !schemeInfo,
      }, //Same as adding vertical accountability ceiling from main bar
    ],
    [
      schemeInfo,
      regenerateColorLegend,
      hasColorLegend,
      addComment,
      addTextBox,
      openEditor,
      addOrRemoveHorizontal,
      addOrRemoveVertical,
    ],
  )
  return <BaseMenu list={editList} title={'EDIT'} />
})
