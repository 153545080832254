import { useCallback } from 'react'
import { useAddNode } from './use-add-node'
import { NodeSchemeType } from '../../../diagram/scheme/enums'
import { TextBoxNodeElement } from '../../../diagram/shapes/text-box'

const NEW_TEXT_BOX_WIDTH = 200
const NEW_TEXT_BOX_HEIGHT = 150

export function useAddTextBoxNode() {
  const textBoxNode = useAddNode()
  return useCallback(
    () =>
      textBoxNode<TextBoxNodeElement>({
        type: NodeSchemeType.textBox,
        data: {
          text: '',
          color: 'rgb(255, 255, 153, 1)',
        },
        style: { height: NEW_TEXT_BOX_HEIGHT, width: NEW_TEXT_BOX_WIDTH },
      }),
    [textBoxNode],
  )
}
