import React, { memo, useCallback, useMemo } from 'react'
import { NodeProps } from 'react-flow-renderer'
import { NodeElementWithType } from '../../scheme/interfaces'
import { NodeSchemeType } from '../../scheme/enums'
import styles from './styles.module.scss'
import { ResizableShapeWrapper } from '../../resizable-shape-wrapper'
import { useEditorContext } from '../../../editor/context/editor-context-provider'

type CallOutData = {
  text: string
  color?: string
  annotationId?: number
}

export type CallOutNodeElement = NodeElementWithType<NodeSchemeType.callOut, CallOutData>

export const CallOutNode = memo(({ id, data: { text } }: NodeProps<CallOutData>) => {
  const html = useMemo(() => ({ __html: text || '' }), [text])
  const { setActiveElementId } = useEditorContext()
  const setCallOutId = useCallback(() => {
    setActiveElementId(id)
  }, [id, setActiveElementId])
  return (
    <ResizableShapeWrapper nodeId={id}>
      <div className={styles.callOut} onClick={setCallOutId}>
        <div className={styles.text} dangerouslySetInnerHTML={html} />
      </div>
    </ResizableShapeWrapper>
  )
})
