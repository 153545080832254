import { Button, Dialog, DialogActions, DialogContent, IconButton } from '@material-ui/core'
import React from 'react'
import { useCallback } from 'react'
import styles from './index.module.scss'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import CloseIcon from '@material-ui/icons/Close'
import { useDashboardPageContext } from '../../../pages/layout/pages/dashboard/context/use-dashboard-page-context'
import { useAuthContext } from '../../context/use-auth-context'
import { useSavingSnackbar } from '../../toolbar/hooks/use-save-diagram'
import { convertSchemeToXml } from '../../../pages/layout/pages/dashboard/scheme-parser/js-to-xml/convert-scheme-to-xml'
import { useRemoteDataCallback } from '../../../hooks/use-remote-data'
import { api } from '../../../api'
import { SchemeInfo } from '../../diagram/scheme/interfaces'
import { useLayoutContext } from '../../../pages/layout/context/use-layout-context'

export function DeleteTocModal({ hideModal }: { hideModal: () => any }) {
  const { authData } = useAuthContext()
  const [saveDiagramCb] = useRemoteDataCallback(api.toco.postUpdateToco)
  const { scheme, schemeInfo, activeTab, xmlTocoScheme, closeTab } = useDashboardPageContext()
  const savingSnackbar = useSavingSnackbar()
  const { setDeleteTocWather } = useLayoutContext()

  const onClick = useCallback(() => {
    const xml = convertSchemeToXml({
      xmlTocoScheme: xmlTocoScheme!,
      scheme: scheme!,
      schemeInfo: { ...schemeInfo, isSavedByUser: 0 } as SchemeInfo,
      userId: authData!.userId,
      tocoInfo: {
        id: activeTab!.id,
        title: activeTab!.title,
      },
    })
    savingSnackbar.notifyDeletingStarted()
    const promise = saveDiagramCb({ xml, userId: authData!.userId, tocoId: activeTab!.id })
    const saveHandler = (promise: Promise<any>) =>
      promise
        .then(() => {
          savingSnackbar.notifyDeleteTOCSuccessfully()
          hideModal()
          setDeleteTocWather(true)
          closeTab(activeTab!.id)
        })
        .catch(() => {
          savingSnackbar.notifyDeleteTOCRejected()
        })
    saveHandler(promise)
  }, [
    activeTab,
    authData,
    closeTab,
    hideModal,
    saveDiagramCb,
    savingSnackbar,
    scheme,
    schemeInfo,
    setDeleteTocWather,
    xmlTocoScheme,
  ])

  return (
    <Dialog maxWidth="xs" onClose={hideModal} open={true} className={styles.form}>
      <MuiDialogTitle className={styles.titleWrapper}>
        Delete TOC “{schemeInfo?.title}”
        <IconButton onClick={hideModal} className={styles.closeIcon}>
          <CloseIcon />
        </IconButton>
      </MuiDialogTitle>

      <DialogContent>
        <div className={styles.notice}>Are you sure you want to remove TOC “{schemeInfo?.title}”?</div>
      </DialogContent>

      <DialogActions className={styles.actions}>
        <Button onClick={hideModal} color="primary" variant="outlined" className={styles.btn}>
          Cancel
        </Button>
        <Button type="submit" color="primary" variant="contained" onClick={onClick} className={styles.btn}>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  )
}
