export enum XmlAccountabilityCeilingVisibility {
  none = 0,
  horizontal,
  vertical,
  both,
}
export enum XmlCommentOwner {
  user = 0,
  actKnowledge,
}

export enum XmlCommentsVisibility {
  hidden = 0,
  visible,
}
