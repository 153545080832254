import React, { memo, useCallback } from 'react'
import { Box } from '@material-ui/core'
import { useDraggableDisabler } from '../../utils/hooks/use-draggable-disabler'
import { OutcomeNodeElement } from '../../scheme/interfaces'
import { SketchPicker } from 'react-color'
import { ElementUtils } from '../../utils/hooks/use-element-utils'
import { PRESET_COLORS } from './preset-color'

type Props = {
  nodeId: string
  shapeColor: string
  updateElement: ElementUtils['updateElement']
  pickerWrapperClassName?: string
}

export const ColorPicker = memo(({ nodeId, shapeColor, updateElement, pickerWrapperClassName }: Props) => {
  const { allowDrag, denyDrag } = useDraggableDisabler(updateElement, nodeId)

  const onChangeColor = useCallback(
    ({ rgb: { r, g, b, a } }) => {
      const rgba = `rgba(${r},${g},${b},${a})`
      updateElement<OutcomeNodeElement>(nodeId, (el) => ({ ...el, data: { ...el.data, color: rgba } }))
    },
    [nodeId, updateElement],
  )

  return (
    <Box onMouseEnter={denyDrag} onMouseLeave={allowDrag} className={pickerWrapperClassName}>
      <SketchPicker color={shapeColor} onChange={onChangeColor} presetColors={PRESET_COLORS} />
    </Box>
  )
})
