import { OutcomeNodeElement } from '../../../../../../components/diagram/scheme/interfaces'
import { XmlTocoScheme } from '../interface'
import { NodeSchemeType } from '../../../../../../components/diagram/scheme/enums'
import { tryDecodeExtraParam } from '../utils/extra-params'
import { convertNumberColorToRgba } from '../utils/color'
import { decodeHtmlAndUpdateFont } from '../utils/decode-html'
import { toArrayAndMap } from '../utils/to-array-and-map'
import { Indicator } from '../../../../../../dto'
import { NodeExtraParams } from '../utils/extra-params/node-extra-params'

export function parseOutcomeNode(outcome: XmlTocoScheme.Outcome): OutcomeNodeElement {
  const extraParams = tryDecodeExtraParam<NodeExtraParams>(outcome.outcomeFontWeight)
  return {
    id: outcome.OutcomeId.toString(),
    type: defineNodeType(outcome),
    data: {
      title: decodeHtmlAndUpdateFont(outcome.Title),
      description: outcome.Description,
      color: convertNumberColorToRgba(outcome.Color, extraParams?.opacity),
      indicators: toArrayAndMap(outcome.Indicators.Indicator, parseIndicator),
    },
    position: {
      x: outcome.x,
      y: outcome.y,
    },
    style: { width: outcome.Width, height: outcome.Height },
  } as OutcomeNodeElement
}

function defineNodeType(outcome: XmlTocoScheme.Outcome) {
  switch (outcome.outcomeType) {
    case undefined:
    case '': // rect-with border radius
      return NodeSchemeType.roundedRectangle
    case 'rectangle':
      return NodeSchemeType.rectangle
    case 'triangle':
      return NodeSchemeType.triangle
    case 'pentagon':
      return NodeSchemeType.pentagon
    case 'oval':
      return NodeSchemeType.ellipse
    case 'hexagon':
      return NodeSchemeType.hexagon
    case 'octagon':
      return NodeSchemeType.octagon
    default: {
      console.warn(`Unexpected outcomeType: ${outcome.outcomeType}. Check defineNodeType function`)
      return NodeSchemeType.roundedRectangle
    }
  }
}

function parseIndicator({ ByWhen, HowMany, HowMuch, Status, WhatWillChange, Who }: XmlTocoScheme.Indicator): Indicator {
  return {
    byWhen: ByWhen,
    howMany: HowMany,
    howMuch: HowMuch,
    status: parseIndicatorStatus(Status),
    whatWillChange: WhatWillChange,
    who: Who,
  }
}

function parseIndicatorStatus(xmlStatus: XmlTocoScheme.Indicator['Status']): Indicator['status'] {
  switch (xmlStatus) {
    case 'inprogress':
      return 'inProgress'
    case 'needsattention':
      return 'needsAttention'
    default:
      return xmlStatus
  }
}
