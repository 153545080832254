import React, { createContext, PropsWithChildren, useContext, useMemo, useState } from 'react'
import { useToggle } from 'react-use'

type ContextType = ReturnType<typeof useValue>

const Context = createContext<ContextType>({} as ContextType)

export function LayoutContextProvider({ children }: PropsWithChildren<{}>) {
  const value = useValue()
  return <Context.Provider value={value}>{children}</Context.Provider>
}

export function useLayoutContext() {
  return useContext(Context)
}

function useValue() {
  const [createdTocoId, setCreatedTocoId] = useState<string | undefined>()
  const [deleteTocWather, setDeleteTocWather] = useToggle(false)
  return useMemo(
    () => ({
      createdTocoId,
      setCreatedTocoId,
      deleteTocWather,
      setDeleteTocWather,
    }),
    [createdTocoId, setCreatedTocoId, deleteTocWather, setDeleteTocWather],
  )
}
