import Axios from 'axios'
import { runtimeConfig } from '../../runtime-config'

export const mainApiClient = Axios.create({
  baseURL: runtimeConfig.apiToco,
})

export function setTokenHeader(token: string): void {
  Object.assign(mainApiClient.defaults.headers, {
    AUTHORIZATION: `Basic ${token}`,
  })
}

export function removeTokenHeader(): void {
  delete mainApiClient.defaults.headers.AUTHORIZATION
}
